.headerWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 28px 60px;
}

.headerWrapper img {
  cursor: pointer;
}

.logo {
  width: 108px;
  height: 32px;
}
.dashboardButtonWrapper {
  display: flex;
  align-items: center;
}
.logoutButton {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  border: none;
  color: #000000;
  background-color: white;
  margin-left: 23px;
  cursor: pointer;
}
.logoutVector {
  padding-right: 10px;
  padding-left: 23px;
}

@media only screen and (max-width: 1300px) {
  .headerWrapper {
    width: 100%;
    padding: 27px 54px;
  }

  .logo {
    width: 97px;
    height: 29px;
  }

  .logoutButton {
    font-size: 12.5px;
    line-height: 14.5px;
    margin-left: 20.5px;
  }
  .logoutVector {
    padding-right: 9px;
    padding-left: 22.5px;
  }
}

@media only screen and (max-width: 1201px) {
  .headerWrapper {
    width: 100%;
    padding: 24px 50px;
  }

  .logo {
    width: 89px;
    height: 26.5px;
  }

  .logoutButton {
    font-size: 11.5px;
    line-height: 13.5px;
    margin-left: 18.5px;
  }
  .logoutVector {
    padding-right: 8.5px;
    padding-left: 20.5px;
  }
}

@media only screen and (min-width: 1900px) {
  .logo {
    margin-left: 40px;
  }
}
@media only screen and (max-width: 1024px) {
  .headerWrapper {
    display: none;
  }
}
