.cardWrapper {
  color: #ffffff;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  margin-right: 65px;
  padding: 38px 121px 67px 121px;
  position: absolute;
  left: 66%;
  top: 50%;
  transform: translate(-50%,-50%);
}
.mobileLogoWrapper {
  display: none;
}
.h1 {
  font-family: "Rubik";
  font-size: 24px;
  color: #ffffff;
  padding-bottom: 14px;
}
.inputWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 27px;
  width: 540px;
}

.inputWrapper label {
  margin-bottom: 10px;
  font-size: 18px;
  font-family: "Roboto";
}
.inputWrapper input {
  border-radius: 10px;
  height: 52px;
  color: #fff;
  font-size: 24px;
  background: rgba(255, 255, 255, 0.3);
}
.contentWrapper {
  width: 540px;
}

.buttonWrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 59px;
  flex: row-reverse;
}

.backButton {
  border: none;
  padding: 0;
  border: none;
  background: none;
  color: white;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
  opacity: 0.8;
}
.backButton:hover {
  opacity: 1;
}
.whitePurpleButton {
  color: var(--mainPurple);
  background-color: white;
  border-radius: 10px;
  padding: 16px 32px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
}
.whitePurpleButton:hover {
  color:#ffffff;
  background-color: var(--mainPurple);
  transition: 0.5s;
}

@media only screen and (max-width: 1300px) {
  .cardWrapper {
    margin-right: 58.5px;
    padding: 34px 75px 60px 75px;
  }
  .h1 {
    font-size: 21.5px;
    padding-bottom: 12.5px;
  }
  .inputWrapper {
    margin-bottom: 24px;
  }

  .inputWrapper label {
    margin-bottom: 9px;
    font-size: 16px;
  }
  .inputWrapper input {
    height: 45px;
    font-size: 21.5px;
  }
  .buttonWrapper {
    margin-top: 54px;
  }
  .backButton {
    font-size: 16px;
  }
  .whitePurpleButton {
    padding: 14.5px 29px;
    font-size: 16px;
  }
}

@media only screen and (max-width: 1200px) {
  .cardWrapper {
    margin-right: 52px;
    padding: 34px 60px 60px 60px;
  }
  .h1 {
    font-size: 19px;
    padding-bottom: 11px;
  }
  .inputWrapper {
    margin-bottom: 21.5px;
  }

  .inputWrapper label {
    margin-bottom: 8px;
    font-size: 14.5px;
  }
  .inputWrapper input {
    height: 45px;
    font-size: 20px;
  }
  .buttonWrapper {
    margin-top: 48px;
    margin-bottom: 48px;
  }
  .backButton {
    font-size: 14.5px;
  }
  .whitePurpleButton {
    padding: 13px 26px;
    font-size: 14.5px;
  }
}

@media only screen and (max-width: 1024px) {
  .cardWrapper {
    position: static;
    top:0;
    left: 0;
    margin: 0 auto;
    width: 100%;
    max-width: 95%;
    padding: 0;
    transform: none;
  }
  .contentWrapper {
    width: 100%;
    padding: 40px 40px 0;
    padding-bottom: 40px;
  }
  section {
    margin: 0 auto;
    min-width: 80%;
    vertical-align: middle;
  }
  .h1 {
    margin: 0;
    margin-bottom: 30px;
    text-align: center;
  }
  .mobileLogoWrapper {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 100px;
  }
  .logo {
    width: 110px;
    height: 32px;
  }
  .inputWrapper {
    margin-bottom: 21.5px;
    width: 100%;
  }
  .inputWrapper label {
    text-align: center;
  }
}
.buttonWrapper {
  margin: 0;
}
@media only screen and (max-width: 768px) {
  
  .inputWrapper {
    margin-bottom: 21.5px;
  }
  .h1 {
    text-align: center;
  }
  .buttonWrapper {
    flex-direction: column;
    align-items: center;
  }
  .inputWrapper label {
    margin-bottom: 8px;
    font-size: 14.5px;
  }
  .inputWrapper input {
    height: 45px;
    font-size: 20px;
  }
  .buttonWrapper {
    margin-top: 48px;
    flex-direction: column-reverse;
  }
  .backButton {
    font-size: 14.5px;
    margin-top: 48px;
  }
  .whitePurpleButton {
    padding: 13px 65px;
    font-size: 14.5px;
  }
}
