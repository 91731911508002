.section {
  width: 67%;
  display: flex;
  justify-content: center;
  align-items: center;
}


.cardWrapper {
  color: #ffffff;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  padding: 25px 121px 25px 121px;
  position: static;
  margin-top: 70px;
  /* margin-right: 65px; */
  width: fit-content;
  height: fit-content;
  
}
.mobileLogoWrapper {
  display: none;
}
.logo {
  width: 110px;
  height: 32px;
}
.h3 {
  font-family: "Rubik";
  font-size: 24px;
  color: #ffffff;
  padding-bottom: 14px;
  margin: 25px 0 25px 0;
}
.inputWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  width: 540px;
}
.inputWrapper i {
  position: absolute;
  font-size: 24px;
  color: black;
  cursor: pointer;
  right: 10px;
  bottom: 15px;
  transition: font-size 0.5s;
}
.inputWrapper label {
  margin-bottom: 10px;
  font-size: 18px;
  font-family: "Roboto";
}
.inputWrapper input {
  border-radius: 10px;
  height: 52px;
  font-size: 24px;
  color: #fff;
  background: rgba(255, 255, 255, 0.3);
}

.buttonWrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  margin-bottom: 25px;
}

.backButton {
  border: none;
  padding: 0;
  border: none;
  background: none;
  color: white;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
  opacity: 0.8;
}
.backButton:hover {
  opacity: 1;
  transition: 0.5s;
}
.createAccButton {
  color: #fff;
  background-color: transparent;
  border-radius: 10px;
  border: 2px solid #fff;
  padding: 16px 32px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
}
.createAccButton:hover {
  color: rgb(0, 0, 0);
  background-color: #fff;
  transition: 0.5s;
}
.errorBlock {
  background-color: #bd3134;
  display: none;
  vertical-align: center;
  margin-top: 25px;
}

.errorBlock i {
  font-size: 30px;
  align-self: center;
  padding-left: 20px;
  padding-right: 15px;
}

.errorParagraph {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
}
.errorMessage {
  display: none;
  position: absolute;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: rgb(189, 49, 52);
  margin: 0;
  bottom: -7px;
  transform: translateY(100%);
}

.ReCAPTCHA {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.successMessage {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
  color: #ffffff;
  text-align: center;
  margin-top: 150px;
}
.pWrapper {
  margin: 0;
  margin-top: 60px;
  margin-bottom: 80px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 25px;
  color: #ffffff;
  max-width: 580px;
}
.sButtonWrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 280px;
}
.closeButton {
  color: var(--mainPurple);
  background-color: white;
  border-radius: 10px;
  padding: 16px 70px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
}

@media only screen and (max-width: 1300px) {
  .cardWrapper {
    margin-right: 58.5px;
    padding: 18px 109px 36px 109px;
  }
  .h3 {
    font-size: 21.5px;
    padding-bottom: 12.5px;
  }
  .inputWrapper {
    margin-bottom: 24.5px;
    width: 486px;
  }
  .inputWrapper i {
    font-size: 21.5px;
    right: 9px;
    bottom: 13px;
  }
  .inputWrapper label {
    margin-bottom: 9px;
    font-size: 16px;
  }
  .inputWrapper input {
    height: 47px;
    font-size: 21.5px;
  }

  .buttonWrapper {
    margin-top: 40px;
  }

  .backButton {
    font-size: 16px;
  }

  .createAccButton {
    padding: 14px 29px;
    font-size: 16px;
  }
  .ReCAPTCHA {
    margin-top: 18px;
  }
}

@media only screen and (max-width: 1201px) {
  .cardWrapper {
    margin-right: 54px;
    padding: 16.5px 80px 33px 80px;
  }
  .h3 {
    font-size: 20px;
    padding-bottom: 11.5px;
  }
  .inputWrapper {
    margin-bottom: 20px;
    width: 447px;
  }
  .inputWrapper i {
    font-size: 20px;
    right: 8px;
    bottom: 12px;
  }
  .inputWrapper label {
    margin-bottom: 8px;
    font-size: 15px;
  }
  .inputWrapper input {
    height: 40px;
    font-size: 20px;
  }

  .buttonWrapper {
    margin-top: 40px;
  }

  .backButton {
    font-size: 15px;
  }
  .createAccButton {
    padding: 13px 27px;
    font-size: 15px;
  }
  .ReCAPTCHA {
    margin-top: 16.5px;
  }
}
@media only screen and (max-width: 1024px) {
  
  .section {
    margin: 0 auto;
    min-width: 65%;
    flex-direction: column;
    justify-content: flex-start;
  }
  .cardWrapper {
    position: static;
    top: 0;
    left: 0;
    transform: none;
    margin: 0 auto;
    width: 90%;
    padding: 25px 10px;
    margin-bottom: 30px;
  }
  .mobileLogoWrapper {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  
  .contentWrapper {
    width: 100%;
    padding: 40px 80px 50px;
  }
  .logo {
    width: 110px;
    height: 32px;
  }
  .h3 {
    margin: 0;
    margin-bottom: 15px;
    font-size: 20px;
  }
  .inputWrapper {
    width: 100%;
    margin-bottom: 10px;
  }
  .inputWrapper i {
    font-size: 20px;
    right: 8px;
    bottom: 12px;
  }
  .inputWrapper label {
    margin-bottom: 8px;
    font-size: 15px;
  }
  .inputWrapper input {
    height: 40px;
    font-size: 20px;
  }

  .buttonWrapper {
    margin-top: 30px;
  }

  .backButton {
    font-size: 15px;
  }
  .createAccButton {
    padding: 13px 27px;
    font-size: 15px;
  }
  .ReCAPTCHA {
    margin-top: 10px;
  }
}
@media only screen and (max-width: 768px) {
  .cardWrapper {
    max-width: 95%;
    margin-bottom: 30px;
    padding: 20px 10px;
  }
  .mobileLogoWrapper {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .contentWrapper {
    width: 100%;
    padding: 30px 30px 20px;
  }

  .h3 {
    margin: 0;
    margin-bottom: 15px;
    font-size: 20px;
  }
  .inputWrapper {
    width: 100%;
    margin-bottom: 10px;
  }
  .inputWrapper i {
    font-size: 20px;
    right: 8px;
    bottom: 12px;
  }
  .inputWrapper label {
    margin-bottom: 8px;
    font-size: 15px;
  }
  .inputWrapper input {
    height: 40px;
    font-size: 20px;
  }

  .buttonWrapper {
    margin-top: 30px;
  }

  .backButton {
    font-size: 15px;
  }
  .createAccButton {
    padding: 13px 27px;
    font-size: 15px;
  }
  .sButtonWrapper {
    margin-bottom: 100px;
  }
  .successMessage {
    margin-top: 100px;
  }
}
@media only screen and (min-width: 1920px) {
  .cardWrapper {
    margin-right: 140px;
  }
}
