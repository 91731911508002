
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  
  100% {
    transform: rotate(360deg);
  }
}

.loader:before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  width: 100vw;
  height: 100vh;
  z-index: 999;
}
.loader:after {
  content: "";
  position: fixed;
  top:50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  background: transparent;
  border: 3px solid transparent;
  border-top: 3px solid var(--mainPurple);
  border-bottom: 3px solid var(--mainPurple);
  z-index: 99999;
  border-radius: 50%;
  outline: 3px solid white;
  outline-offset: -6px;
  animation: lds-ring 1s linear infinite;
}

