.container {
  width: 84%;
}
.wrapper {
  position: relative;
  margin-left: 50px;
  margin-right: 33px;
  margin-bottom: 40px;
  padding: 50px 35px 30px 35px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.modalOpen {
  box-shadow: none;
}

.icon-devices {
  margin-right: 30px;
}
.inputSearch {
  width: 300px;
  background-color: rgba(214, 214, 214, 0.1);
  color: black;
  border-radius: 5px;
  height: 40px;
  font-family: "Roboto";
  margin-right: 4px;
}

.purpleButton {
  font-family: "Rubik";
  font-size: 14px;
  color: #ffffff;
  background-color: var(--mainPurple);
  padding: 11px 35px;
  border-radius: 5px;
}
.purpleButton:hover {
  background-color: var(--lighterMain);
  transition: 0.5s;
}
.titleWrapper {
  display: flex;
  align-items: center;
}
.h1 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #000000;
  letter-spacing: 0.02em;
}
.header i {
  padding-right: 30px;
  color: var(--mainPurple);
  font-size: 30px;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  vertical-align: middle;
}

.devicesArr {
  color: var(--mainPurple);
  font-family: Montserrat;
  font-weight: bold;
  font-size: 16px;
  background-color: white;
}
.devicesArr td,
tr {
  text-align: left;
  vertical-align: middle;
  width: calc(100% / 5);
}
.tableHeader {
  table-layout: fixed;
  width: 100%;
  padding: 0 35px 0 25px;
  border-spacing: 0 29px;
  background-color: rgba(214, 214, 214, 0.1);
}

.rows {
  margin-bottom: 30px;
  font-family: Montserrat;
  font-style: normal;
  font-size: 16px;
  color: #000000;
}

td {
  width: calc(100% / 5);
  padding: 20px 30px;
}

.table {
  border-collapse: collapse;
}

.table .rows {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.table .rows:last-child {
  border-bottom: 0;
}
.iconTV {
  padding-left: 38px !important;
}

.devicesArr > td {
  padding: 25px 30px;
}
.deviceName {
  font-weight: bold;
  position: relative;
  font-size: 16px !important;
}
.deviceName input {
  width: 100%;
  padding: 0;
  background-color: inherit;
  text-overflow: ellipsis;
}
.deviceName i {
  font-size: 18px;
  color: black;
  background-color: #fff;
}
.deviceName button {
  position: absolute;
  background-color: #fff;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  display: block;
  left: 90%;
}
.Active {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #17bc27;
}

.Deactive {
  color: #ff0000;
}
.removeButton {
  padding-left: 40px;
  padding-right: 0;
}
.removeButton button {
  padding: 0;
  border: none;
  background: none;
  margin-right: 10px;
  cursor: pointer;
}
.searchIcon {
  display: none;
}

@media only screen and (max-width: 1300px) {
  .wrapper {
    margin-left: 45px;
    margin-right: 30px;
    margin-bottom: 36px;
    padding: 45px 35px 27px 35px;
  }

  .icon-devices {
    margin-right: 27px;
  }
  .inputSearch {
    width: 270px;
    height: 36px;
    margin-right: 4px;
  }

  .purpleButton {
    font-size: 12px;
    padding: 9px 32.5px;
  }

  .h1 {
    font-size: 14px;
  }
  .header i {
    padding-right: 27px;
    font-size: 27px;
  }

  .header {
    margin-bottom: 18px;
  }

  .devicesArr {
    font-size: 14px;
  }

  .tableHeader {
    padding: 0 32px 0 22.5px;
    border-spacing: 0 27px;
  }

  .rows {
    margin-bottom: 27px;
    font-size: 14px;
  }
  .table .rows {
    padding: 18px 27px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }
  .devicesArr > td {
    padding: 22.5px 27px;
  }
  .deviceName {
    font-size: 14px;
  }

  .deviceName i {
    font-size: 16px;
  }

  .Active {
    font-size: 14px;
    line-height: 18px;
  }
  .removeButton button {
    margin-right: 9px;
  }
}

@media only screen and (max-width: 1201px) {
  .wrapper {
    margin-left: 4px;
    margin-right: 27px;
    margin-bottom: 33px;
    padding: 40px 30px 24px 30px;
  }

  .icon-devices {
    margin-right: 24px;
  }
  .inputSearch {
    width: 248px;
    height: 33px;
    margin-right: 4px;
  }

  .purpleButton {
    font-size: 11px;
    padding: 9px 30px;
  }

  .h1 {
    font-size: 13px;
  }
  .header i {
    padding-right: 25px;
    font-size: 25px;
  }

  .header {
    margin-bottom: 16.5px;
  }

  .devicesArr {
    font-size: 13px;
  }

  .tableHeader {
    padding: 0 29.5px 0 20.5px;
    border-spacing: 0 27px;
  }

  .rows {
    margin-bottom: 25px;
    font-size: 13px;
  }
  .rows > td {
    padding: 16.5px 25px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding-right: 0;
  }
  .devicesArr > td {
    padding: 20.5px 25px;
  }
  .deviceName {
    font-size: 13px;
  }

  .deviceName i {
    font-size: 14.7px;
  }

  .Active {
    font-size: 13px;
    line-height: 16.5px;
  }
  .removeButton button {
    margin-right: 10px;
  }
}

@media only screen and (max-width: 1024px) {
  .container {
    width: 100%;
    position: relative;
  }
  .wrapper {
    margin: 0 auto;
    max-width: 100%;
    padding: 0;
    margin-top: 100px;
    padding-bottom: 100px;
    margin: 37px 40px 100px 40px;
    padding: 20px 40px 20px 40px;
  }

  .type,
  .model,
  .lastActivity,
  .iconTV {
    display: none;
  }
  /* .table {
    padding: ;
  } */
  .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px 40px 40px 40px;
    margin: 0;
  }
  .inputSearch {
    opacity: 0;
  }
  .inputSearchNone {
    opacity: 1;
    display: block;
    background-color: #d6d6d6;
    border-radius: 5px;
    margin: 0 auto 20px;
    width: 100%;
    height: 38px;
    transition: opacity 0.5s ease-in-out;
  }
  .table {
    table-layout: fixed;
    width: 100%;
  }

  .titleWrapper {
    justify-content: center;
    margin-bottom: 35px;
  }
  .buttonWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .purpleButton {
    font-size: 14px;
    padding: 10px 35px;
    max-width: 30%;
  }

  .h1 {
    font-size: 16px;
  }
  .header i {
    padding-right: 25px;
    font-size: 35px;
  }
  .header i:last-child {
    font-size: 25px;
  }

  .header {
    margin-bottom: 16.5px;
  }

  .devicesArr {
    font-size: 13px;
    max-width: 80%;
  }
  .devicesArr td:last-of-type {
    padding-right: 0;
    width: 10%;
  }

  .tableHeader {
    padding: 0 29.5px 0 20.5px;
    border-spacing: 0 27px;
  }

  .rows {
    margin-bottom: 25px;
    font-size: 13px;
  }
  .rows > td {
    border: none;
  }

  .table .rows {
    border: 0;
  }

  .deviceName {
    font-size: 13px;
  }

  .deviceName i {
    font-size: 14.7px;
  }
  .deviceName button {
    top: 51%;
    left: 80%;
  }
  .deviceName input {
    text-overflow: ellipsis;
  }

  .Active {
    font-size: 13px;
    line-height: 16.5px;
  }
  .removeButton {
    padding: 0;
  }
  .removeButton button {
    margin-right: 8px;
  }
  .searchIcon {
    display: flex;
    background-color: #63269a;
    font-size: 21px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    padding: 8px;
    vertical-align: middle;
    position: absolute;
    top: 30px;
    right: 15px;
  }
}
@media only screen and (max-width: 768px) {
  .container {
    width: 100%;
  }
  .wrapper {
    margin: 0 auto;
    max-width: 100%;
    padding: 0;
    margin-top: 60px;
    padding-bottom: 60px;
    margin: 35px 20px 100px 20px;
    padding: 20px 25px 20px 25px;
  }

  .type,
  .model,
  .lastActivity,
  .iconTV {
    display: none;
  }

  .header {
    display: block;
    justify-content: center;
    padding: 10px 20px 40px 20px;
    margin: 0;
  }
  .inputSearch {
    opacity: 0;
  }
  .inputSearchNone {
    opacity: 1;
    display: block;
    background-color: #d6d6d6;
    border-radius: 5px;
    margin: 0 auto 20px;
    width: 100%;
    height: 38px;
    transition: opacity 1s ease-in-out;
  }
  .table {
    table-layout: fixed;
    width: 100%;
    padding-left: 30px;
  }
  .devicesArr td:last-of-type {
    width: 10%;
  }
  .titleWrapper {
    justify-content: center;
    margin-bottom: 30px;
  }
  .buttonWrapper {
    display: flex;
    justify-content: center;
  }

  .purpleButton {
    font-size: 14px;
    padding: 10px 35px;
    max-width: 80%;
  }

  .h1 {
    font-size: 16px;
  }
  .header i {
    padding-right: 25px;
    font-size: 35px;
  }

  .header i:last-child {
    font-size: 25px;
  }

  .devicesArr {
    font-size: 12px;
    max-width: 100%;
  }
  .devicesArr:last-child {
    padding-right: 0;
  }

  .tableHeader {
    padding: 0 10px 0 10px;
    border-spacing: 0 27px;
  }

  .rows {
    margin-bottom: 25px;
    font-size: 13px;
  }
  .rows > td {
    border: none;
  }
  .rows > td:first-child {
    padding-right: 0;
  }
  .rows > td:last-child {
    padding-right: 0;
  }

  .table .rows:last-child {
    border-bottom: 0;
  }
  .deviceName {
    font-size: 13px;
  }
  .deviceName input {
    text-overflow: ellipsis;
    max-width: 6ch;
  }
  .deviceName i {
    font-size: 14.7px;
  }

  .Active {
    font-size: 13px;
    line-height: 16.5px;
  }
  .removeButton button {
    margin-right: 8px;
  }
}

@media only screen and (max-width: 600px) {
  .devicesArr td:last-of-type {
    width: 15%;
  }
  .devicesArr > td {
    padding: 21px 15px;
  }
  .rows > td {
    padding: 21px 15px;
  }
}
@media only screen and (max-width: 450px) {
  .devicesArr td:last-of-type {
    width: 20%;
  }
  .devicesArr > td {
    padding: 20px 10px;
  }
  .rows > td {
    padding: 20px 10px;
  }
}
