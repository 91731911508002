.modalBackground {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modalWrapper {
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  z-index: 9999;
  max-width: 350px;
  padding: 35px 65px 35px 65px;
}

.paragraph {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #ffffff;
}
.buttonWrapper {
  padding: 10px 30px 10px 30px;
  border-radius: 5px;

  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
  color: #ffffff;
  background-color: var(--mainPurple);
}
.button {
  display: flex;
  justify-content: space-evenly;
}
.buttonWrapper:hover {
  background-color: var(--lighterMain);
  transition: 0.5s;
}

@media only screen and (max-width: 768px) {
  .modalWrapper {
    padding: 35px;
  }
}

