.modalBackground {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modalWrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  padding: 50px 120px;
}
.xButton {
  display: none;
}
.h1 {
  font-family: Rubik;
  font-weight: 700;
  font-size: 24px;
  color: white;
  padding-bottom: 50px;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  font-family: Roboto;
  font-size: 18px;
  min-width: 540px;
  font-weight: 400;
  color: black;
  padding-bottom: 35px;
}
.inputWrapper label {
  color: #fff;
}
.inputWrapper input {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  height: 52px;
  border-radius: 10px;
  margin-top: 10px;
  font-size: 24px;
  background: rgba(255, 255, 255, 0.3);
  color: #fff;
}

.inputWrapper label {
  color: #fff;
}
.buttonWrapper {
  display: flex;
  justify-content: space-between;
}

.button1 {
  border: none;
  background-color: inherit;
  font-family: Roboto;
  font-size: 18px;
  color: white;
  cursor: pointer;
  opacity: 0.8;
}
.button1:hover {
  opacity: 1;
}
.button2 {
  background: var(--mainPurple);
  font-family: Roboto;
  font-weight: 700;
  font-size: 18px;
  color: #ffffff;
  border-radius: 5px;
  padding: 16px 60px;
}
.button2:hover {
  background-color: var(--lighterMain);
  transition: 0.5s;
}

@media only screen and (max-width: 1024px) {
  .modalBackground {
    position: fixed;
     background-color: rgb(0, 0, 0, 0);
  }
  .inputWrapper {
    text-align: center;
  }
  .modalWrapper {
    padding: 60px 30px;
    width: 80%;
    background-color: rgba(0, 0, 0);
    margin: 0;
    top: 50%;
  }
  .h1 {
    padding-bottom: 35px;
    text-align: center;
  }
  .inputWrapper {
    min-width: 0;
    width: 100%;
    padding-bottom: 35px;
  }
  .xButton {
    position: absolute;
    top: 10px;
    right: 10px;
    display: block;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 37px;
    letter-spacing: 0.02em;
    color: #ffffff;
    float: right;
  }
  .button1 {
    display: none;
  }
  .button2 {
    width: 100%;
  }
}
@media only screen and (max-width: 768px) {
  .modalBackground {
    position: fixed;
  }
  .modalWrapper {
    padding: 60px 30px;
    width: 80%;
    background-color: rgba(0, 0, 0);
    margin: 0;
    top: 50%;
  }
  .buttonWrapper {
    flex-direction: column-reverse;
    justify-content: center;
  }
  .button2 {
    margin-bottom: 20px;
  }
}
