.modalWrapper {
  position: absolute;
  background: #ffffff;
  border: none;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  overflow-y: hidden;
  overflow-x: hidden;
}

.headerWrapper {
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin-bottom: 20px;
}

.iconWrapper {
  display: flex;
}

.iconWrapper i {
  color: var(--mainPurple);
  font-size: 28px;
}

.active {
  margin-left: 90px;
  color: #17bc27;
}
.deactive {
  margin-left: 90px;
  color: #ff0000;
}
.h1 {
  position: relative;
  font-size: 16px;
  font-family: "Montserrat";
  font-weight: 700;
  letter-spacing: 0.02em;
  color: #000000;
}
.h1 input {
  width: 100px;
  text-align: left;
  vertical-align: middle;
  padding: 0;
  background-color: #fff;
}
.h1 span {
  vertical-align: middle;
}

.headerWrapper i {
  font-size: 35px;
  color: var(--mainPurple);
  margin-right: 30px;
}
.purpleButton {
  font-size: 14px;
  letter-spacing: 0.02em;
  color: #ffffff;
  font-family: "Rubik";
  font-weight: 500;
  background: var(--mainPurple);
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  margin-left: 30px;
}

.purpleButton:hover {
  background-color: var(--lighterMain);
  transition: 0.5s;
}
.purpleButtonDimmed {
  font-size: 14px;
  letter-spacing: 0.02em;
  color: #ffffff;
  font-family: "Rubik";
  font-weight: 500;
  background: var(--mainPurple);
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  margin-left: 30px;
}
.purpleButtonDimmed:hover {
  background: var(--mainPurple);
}

.changeDeviceName {
  background-color: #fff;
  border: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 25%;
}
.changeDeviceName i {
  font-size: 18px;
  vertical-align: middle;
}
.contentWrapper {
  margin-bottom: 20px;
  padding: 35px 35px 30px 50px;
  background: white;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}
.tableWrapper {
  width: 100%;
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.02em;
  color: #000000;
  background-color: rgba(214, 214, 214, 0.1);
  border-radius: 10px;

  padding: 40px 45px 30px 45px;
}
.tableHeader {
  table-layout: fixed;
  width: 100%;
}
.dropdownButton {
  cursor: pointer;
}

.devicesArr {
  color: var(--mainPurple);
  background-color: white;
  letter-spacing: 0.02em;
  text-align: left;
  line-height: 20px;
  width: calc(100% / 5);
}
.deviceArr th:last-child {
  padding-right: 0;
}
.devicesArr th {
  padding: 20px 40px 20px 20px;
  border: none;
  width: 10%;
}

.deviceName {
  font-weight: bold;
  position: relative;
}
.deviceName input {
  width: 100%;
  padding: 0;
  background-color: rgba(214, 214, 214, 0.1);
}
.deviceName i {
  font-size: 18px;
  color: black;
  background-color: rgba(214, 214, 214, 0.1);
}
.deviceName button {
  position: absolute;
  background-color: #fff;
  top: 63%;
  transform: translateY(-50%);
  right: 0%;
  vertical-align: middle;
  text-align: middle;
}
.emptyList {
  font-size: 16px;
}
.rows {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #000000;
  margin-bottom: 30px;
  text-align: left;
  padding: 15px;
  letter-spacing: 0.02em;
}
.rows input {
  width: 100%;
  padding: 0;
}
.rows td {
  padding: 20px 0px 0px 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% / 5);
}
.mobileActions {
  display: none;
}

.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 15px;
  vertical-align: middle;
  margin-right: 10px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.sliderRound {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.sliderRound:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 0px;
  bottom: 1px;
  background-color: #ffffff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .sliderRound {
  background-color: #17bc27;
}

input:focus + .sliderRound {
  box-shadow: 0 0 1px #17bc27;
}

input:checked + .sliderRound:before {
  -webkit-transform: translateX(17px);
  -ms-transform: translateX(17px);
  transform: translateX(17px);
}

.sliderRound {
  border-radius: 34px;
}

.sliderRound:before {
  border-radius: 50%;
}

.dropdown {
  display: none;
}

@media only screen and (max-width: 1201px) {
  .headerWrapper {
    margin-bottom: 16.5px;
    align-items: center;
  }

  .iconWrapper i {
    font-size: 23px;
  }

  .h1 {
    font-size: 13px;
  }
  .h1 input {
    width: 83px;
  }

  .headerWrapper i {
    font-size: 29px;
    margin-right: 25px;
  }
  .purpleButton {
    font-size: 11.5px;
    padding: 8.5px 16.5px;
    margin-left: 25px;
  }
  .purpleButtonDimmed {
    font-size: 11.5px;
    padding: 8.5px 16.5px;
    margin-left: 25px;
  }

  .changeDeviceName i {
    font-size: 15px;
  }
  .contentWrapper {
    margin-bottom: 16.5px;
    padding: 29px 29px 25px 41.5px;
  }
  .tableWrapper {
    font-size: 13px;
    padding: 33px 37px 25px 37px;
  }

  .devicesArr {
    line-height: 16.5px;
  }
  .devicesArr th {
    padding: 16.5px 33px 16.5px 16.5px;
  }
  .deviceName i {
    font-size: 15px;
  }
  .emptyList {
    font-size: 13px;
  }
  .rows {
    font-size: 13px;
    line-height: 16.5px;
    padding: 12.5px;
  }

  .rows td {
    padding: 16.5px 0px 0px 16.5px;
  }

  .switch {
    width: 25px;
    height: 12.5px;
  }

  .sliderRound:before {
    position: absolute;
    content: "";
    height: 11px;
    width: 11px;
  }
  input:checked + .sliderRound:before {
    -webkit-transform: translateX(14px);
    -ms-transform: translateX(14px);
    transform: translateX(14px);
  }
  .sliderRound {
    border-radius: 28px;
  }
}

@media only screen and (max-width: 1024px) {
  .modalWrapper {
    top: 0;
  }
  .greenName {
    color: rgb(17, 136, 23);
  }

  .mobileActions {
    display: block;
    margin: 0;
  }
  .mobileActions button {
    margin-right: 0;
  }
  .tableWrapper {
    padding: 60px 15px 120px 15px;
    margin: 0;
  }
  .tableWrapper h4 {
    font-size: 16px;
    font-weight: 700;
  }
  .iconWrapper {
    justify-content: center;
    margin-bottom: 35px;
    align-items: center;
  }
  .changeDeviceName i {
    display: none;
  }

  .iconWrapper i {
    align-self: center;
    font-size: 35px;
  }
  .buttonWrapper {
    display: flex;
    justify-content: center;
  }
  .buttonWrapperBottom {
    display: flex;
    justify-content: space-around;
    flex-direction: row-reverse;
  }
  .headerWrapper {
    margin: 0 20px 0 20px;
    display: block;
  }
  .contentWrapper {
    padding: 30px 10px 30px 10px;
    margin-bottom: 35px;
  }
  .devicesArr th {
    padding-right: 0;
  }
  .backButton {
    display: none;
  }
  .purpleButton {
    padding: 10px 20px;
    font-size: 14px;
  }
  .purpleButtonDimmed {
    padding: 10px 20px;
    font-size: 14px;
    display: flex;
    justify-content: center;
  }
  .rows td {
    overflow: visible;
  }
  .active,
  .deactive,
  .status,
  .refreshButton,
  .switch,
  .URL {
    display: none;
  }

  .changeDeviceName {
    right: -50%;
  }
  .dropdown {
    display: inline;
    text-align: center;
  }
  .desktopActions {
    display: none;
  }
  .devicesArr th:last-of-type {
    width: 5%;
  }
  .deviceName input {
    text-overflow: ellipsis;
    background-color: inherit;
    max-width: 65%;
  }
  .dropdownContent {
    display: none;
    position: relative;
    float: left;
  }
  .dropdownOpen {
    display: inline-block;
    position: absolute;
    z-index: 9999;
    padding: 15px 20px 15px 20px;
    /* width: 130px; */
    min-width: 150px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    right: 20%;
  }
  .refreshSpan {
    margin-left: 10px;
  }
  .switchWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .refreshWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
  }
  .refreshWrapper button {
    font-size: 20px;
  }
  .deleteWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
  }
  .deleteWrapper button {
    margin-right: 15px;
    font-size: 20px;
  }
  .switchMobile {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 15px;
    vertical-align: middle;
    margin-right: 10px;
  }

  .switchMobile input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .activeMobile {
    color: #17bc27;
    display: inline;
  }
  .deactiveMobile {
    color: #ff0000;
    display: inline;
  }
  .sliderRoundMobile {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: black;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .sliderRoundMobile:before {
    position: absolute;
    content: "";
    height: 13px;
    width: 13px;
    left: 0px;
    bottom: 1px;
    background-color: #ffffff;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .sliderRoundMobile {
    background-color: #17bc27;
  }

  input:focus + .sliderRoundMobile {
    box-shadow: 0 0 1px #17bc27;
  }

  input:checked + .sliderRoundMobile:before {
    -webkit-transform: translateX(17px);
    -ms-transform: translateX(17px);
    transform: translateX(17px);
  }

  .sliderRoundMobile {
    border-radius: 34px;
  }

  .sliderRoundMobile:before {
    border-radius: 50%;
  }
  .purpleButton:nth-of-type(2) {
    padding: 10px 30px;
  }
}
@media only screen and (max-width: 768px) {
  .modalWrapper {
    top: 0%;
  }

  .headerWrapper {
    flex-direction: column;
    margin: 0;
    margin-bottom: 16.5px;
  }
  .iconWrapper {
    justify-content: center;
    margin-bottom: 30px;
  }

  .buttonWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  .purpleButton {
    margin: 0;
    margin-bottom: 20px;
  }

  .active {
    display: none;
    margin: 0;
  }
  .deactive {
    display: none;
    margin: 0;
  }
  .URL {
    display: none;
  }
  .status {
    display: none;
  }
  .actions {
    display: none;
  }

  .purpleButtonDimmed {
    font-size: 14px;
    padding: 10px 20px;
    margin: 0;
    height: 34px;
  }

  .changeDeviceName i {
    display: none;
  }
  .contentWrapper {
    margin-bottom: 35px;
    padding: 30px 15px 30px 15px;
  }
  .tableWrapper {
    font-size: 13px;
    padding: 60px 15px 120px 15px;
  }

  .devicesArr {
    line-height: 16.5px;
  }
  .devicesArr th {
    padding: 16.5px 33px 16.5px 16.5px;
  }
  .deviceName i {
    font-size: 15px;
  }
  .emptyList {
    font-size: 13px;
  }
  .rows {
    font-size: 13px;
    line-height: 16.5px;
    padding: 12.5px;
  }

  .rows td {
    padding: 16.5px 0px 0px 16.5px;
  }

  .switch {
    width: 25px;
    height: 12.5px;
  }

  .sliderRound:before {
    position: absolute;
    content: "";
    height: 11px;
    width: 11px;
  }
  input:checked + .sliderRound:before {
    -webkit-transform: translateX(14px);
    -ms-transform: translateX(14px);
    transform: translateX(14px);
  }
  .sliderRound {
    border-radius: 28px;
  }
}
