body {
  margin: 0;
  overflow-x: hidden;
}
* {
  box-sizing: border-box;
}


.image {
  display: flex;
  justify-content: space-between;
  background: url(../../assets/images/image.png) no-repeat center center fixed;
  min-height: 100vh;
  height: 100%;
  mix-blend-mode: luminosity;
  background-size: cover;
}

.sidebar {
  width: 33%;
  background: rgba(0, 0, 0, 0.5);
  /* height: 100vh; */
  padding-left: 5.063rem;
}
.logo {
  margin-top: 57px;
  margin-bottom: 35px;
}
.header {
  font-family: Rubik;
  font-weight: bold;
  font-size: 1.5rem;
  color: #ffffff;
}
.paragraph {
  margin-top: 8.063rem;
  margin-right: 3.563rem;
  font-family: Rubik;
  font-size: 1rem;
  color: #ffffff;
  line-height: 19px;
  font-weight: 400;
}

@media only screen and (max-width: 1300px) {
  .sidebar {
    padding-left: 73px;
  }
  .logo {
    margin-top: 51px;
    margin-bottom: 31.5px;
    margin-right: 129px;
  }
  .header {
    font-size: 21px;
  }
  .paragraph {
    margin-top: 116px;
    margin-right: 51px;
    font-size: 14.5px;
    line-height: 17px;
  }
}

@media only screen and (max-width: 1201px) {
  .sidebar {
    padding-left: 67px;
  }
  .logo {
    margin-top: 47px;
    margin-bottom: 29px;
    margin-right: 119px;
  }
  .header {
    font-size: 19.5px;
  }
  .paragraph {
    margin-top: 107px;
    margin-right: 47px;
    font-size: 13.5px;
    line-height: 15.5px;
  }
}

@media only screen and (max-width: 1024px) {
  .sidebar {
    display: none;
  }
}
