.modalBackground {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.cardWrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  padding: 50px 75px;
}
.h1 {
  font-family: Rubik;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #ffffff;
}
.paragraph {
  display: block;
  padding-top: 37px;
  font-family: "Roboto";
  font-size: 18px;
  line-height: 19px;
  color: #ffffff;
}
.flexitems {
  display: flex;
  justify-content: space-around;
  margin: 0;
  margin-top: 55px;
  margin-bottom: 110px;
  border: none;
}
.container {
  height: 70px;
  align-items: center;
}
.container .character {
  line-height: 75px;
  font-size: 36px;
  color: white;
  background-color: rgba(255, 255, 255, 0.3);
  border: 1px solid transparent;
  border-radius: 8px;
  margin-right: 35px;
  width: 75px;
  height: 75px;
}
.container .character:last-of-type {
  margin: 0;
}

.character:last-child {
  margin-right: 0;
}

.buttonWrapper {
  cursor: pointer;
  color: #d6d6d6;
  float: right;
}
.cancelButton {
  border: none;
  background-color: inherit;
  color: #ffffff;
  padding-right: 105px;
  font-family: Roboto;
  font-size: 18px;
  opacity: 0.8;
}
.cancelButton:hover {
  opacity: 1;
}
.purpleButton {
  color: #ffffff;
  background: var(--mainPurple);
  border-radius: 5px;
  padding: 15px 45px;
  font-family: Roboto;
  font-size: 18px;
  font-weight: bold;
}
.purpleButton:hover {
  background-color: var(--lighterMain);
  transition: 0.5s;
}

@media only screen and (max-width: 1201px) {
  .cardWrapper {
    padding: 41.5px 62px;
  }
  .h1 {
    font-size: 20px;
    line-height: 23px;
  }
  .paragraph {
    padding-top: 31px;
    font-size: 15px;
    line-height: 15px;
  }
  .flexitems {
    margin-top: 45.6px;
    margin-bottom: 91px;
  }
  .container {
    height: 58px;
  }
  .container .character {
    line-height: 62px;
    font-size: 30px;
    margin-right: 39px;
    width: 62px;
    height: 62px;
  }
  .cancelButton {
    padding-right: 87px;
    font-size: 15px;
  }
  .purpleButton {
    padding: 12.5px 37px;
    font-size: 15px;
  }
}

@media only screen and (max-width: 1024px) {
  .container .character {
    line-height: 65px;
    font-size: 30px;
    color: white;
    background-color: rgba(255, 255, 255, 0.3);
    border: 1px solid transparent;
    border-radius: 8px;
    margin-right: 35px;
    width: 65px;
    height: 65px;
  }
  .h1 {
    font-size: 16px;
    line-height: 20px;
    text-align: center;
  }
  .paragraph {
    padding-top: 31px;
    font-size: 18px;
    line-height: 15px;
    text-align: center;
  }
}

@media only screen and (max-width: 768px) {
  .cardWrapper {
    padding: 25px 20px;
    width: 90%;
  }
  .container .character {
    line-height: 40px;
    font-size: 20px;
    color: white;
    background-color: rgba(255, 255, 255, 0.3);
    border: 1px solid transparent;
    border-radius: 8px;
    margin-right: 25px;
    width: 40px;
    height: 40px;
  }
  
  .flexitems {
    margin-bottom: 30px;
  }
  .h1 {
    font-size: 12px;
    line-height: 20px;
  }
  .paragraph {
    padding-top: 31px;
    font-size: 16px;
    line-height: 15px;
  }
  .buttonWrapper {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    justify-content: center;
  }
  .purpleButton {
    margin-bottom: 30px;
  }
  .cancelButton {
    padding: 0;
    margin-bottom: 20px;
  }
  
}

@media only screen and (max-width: 480px) {
   .container .character {
    line-height: 30px;
    font-size: 20px;
    color: white;
    background-color: rgba(255, 255, 255, 0.3);
    border: 1px solid transparent;
    border-radius: 8px;
    margin-right: 20px;
    width: 30px;
    height: 30px;
  }
}
