.modalBackground {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  top:90px;
  z-index: 999;
}
.modalWrapper {
  position: absolute;
  right: 20px;
  background: white;
  border-radius: 10px;
  padding: 14px 0px 20px 0px;
}
.balanceWrapper {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
  color: var(--mainPurple);
  text-align: center;
  text-decoration: none;
}
.buttonWrapper {
  background-color: var(--mainPurple);
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  padding: 10px 50px 10px 45px;
}
.buttonWrapper i {
  margin-left: 23px;
}
