@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Rubik:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Roboto:wght@700&family=Rubik:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Roboto:wght@700&family=Rubik:wght@400;700&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
:root {
  --mainPurple: #343F52;
  --lighterMain: #52617b;
}

button {
  border: none;
  cursor: pointer;
  background-color: inherit;
}
a {
  text-decoration: none;
}



input {
  border: none;
  color: black;
}
:focus {
  outline: none;
}
:root {
  --toastify-color-info: var(--mainPurple);
  --toastify-font-family: "Roboto";
}

.grecaptcha-badge {
  z-index: 9999;
}

