.cardWrapper {
  color: #ffffff;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  position: absolute;
  left: 66%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin-right: 65px;
  padding: 38px 100px 67px 100px;
}
.h1 {
  font-family: "Rubik";
  font-size: 24px;
  color: #ffffff;
  padding-bottom: 14px;
}
.inputWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 27px;
  width: 540px;
}
.inputWrapper i {
  position: absolute;
  font-size: 24px;
  color: black;
  cursor: pointer;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.inputWrapper label {
  margin-bottom: 10px;
  font-size: 18px;
  font-family: "Roboto";
}
.inputWrapperInput {
  position: relative;
}
.inputWrapper input {
  border-radius: 10px;
  height: 52px;
  width: 100%;
  color: #fff;
  font-size: 24px;
  background: rgba(255, 255, 255, 0.3);
}

.buttonWrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 59px;
}

.backButton {
  border: none;
  padding: 0;
  border: none;
  background: none;
  color: white;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
}
.createAccButton {
  color: var(--mainPurple);
  background-color: white;
  border-radius: 10px;
  padding: 16px 32px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
}

@media only screen and (max-width: 1300px) {
  .cardWrapper {
    margin-right: 65px;
    padding: 38px 80px 67px 80px;
  }
  .inputWrapper {
    width: 480px;
  }
}

@media only screen and (max-width: 1024px) {
  .cardWrapper {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 40px 40px 40px 40px;
    width: 80%;
  }
  .h1 {
    font-size: 20px;
    text-align: center;
  }
  .inputWrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 27px;
    width: 100%;
  }
  .buttonWrapper {
    justify-content: center;
  }
}
