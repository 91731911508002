@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?eszeg7');
  src:  url('fonts/icomoon.eot?eszeg7#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?eszeg7') format('truetype'),
    url('fonts/icomoon.woff?eszeg7') format('woff'),
    url('fonts/icomoon.svg?eszeg7#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-LogoutIcon:before {
  content: "\e910";
  color: #fff;
}
.icon-loop:before {
  content: "\e90d";
}
.icon-eye-blocked:before {
  content: "\e905";
}
.icon-backIcon:before {
  content: "\e912";
  color: var(--mainPurple);
}
.icon-icon12:before {
  content: "\e911";
  color: var(--mainPurple);
}
.icon-SearchIcon:before {
  content: "\e90f";
  color: #fff;
}
.icon-errorFlag:before {
  content: "\e90e";
}
.icon-edit-button:before {
  content: "\e90c";
}
.icon-Vector:before {
  content: "\e909";
  color: var(--mainPurple);
}
.icon-6:before {
  content: "\e90a";
  color: var(--mainPurple);
}
.icon-3:before {
  content: "\e90b";
  color: var(--mainPurple);
}
.icon-eyeOpened:before {
  content: "\e908";
}
.icon-Hamburger:before {
  content: "\e907";
  color: var(--mainPurple);
}
.icon-HamburgerWhite:before {
  content: "\e907";
  color: #ffffff;
}
.icon-Settings:before {
  content: "\e906";
}
.icon-remove:before {
  content: "\e904";
  color: #f00;
}
.icon-TV:before {
  content: "\e903";
}
.icon-subription:before {
  content: "\e901";
}
.icon-m3u:before {
  content: "\e902";
}
.icon-devices:before {
  content: "\e900";
}
