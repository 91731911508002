.modalBackground {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modalWrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  padding: 70px 255px;
}
.h1 {
  font-family: "Rubik";
  font-weight: 700;
  font-size: 24px;
  color: #ffffff;
  margin-bottom: 65px;
}
.cardholderName {
  font-family: "Roboto";
  font-size: 18px;
  color: #ffffff;
}
.cardholderName input {
  margin-top: 15px;
}
.cardholderName input {
  width: 100%;
  height: 50px;
  background: gray;
  border-radius: 10px;
}
.cardNumberLabel {
  color: white;
  font-family: "Roboto";
  font-size: 18px;
  padding-top: 35px;
  margin-bottom: 15px;
}
.cardNumber {
  display: flex;
}
.cvv {
  color: white;
  font-family: "Roboto";
  font-size: 18px;
  position: relative;
}
.cvv label {
  position: absolute;
  top: 0;
  transform: translateY(calc(-100% - 15px));
}

.expDate {
  display: inline-block;
  font-family: "Roboto";
  font-size: 18px;
  color: #ffffff;
  margin-top: 30px;
  margin-bottom: 15px;
}

.cardNumber input {
  width: 74px;
  height: 50px;
  font-family: "Rubik";
  font-weight: 500;
  font-size: 17px;
  background-color: gray;
  border-radius: 10px;
}
.cardNumber div span {
  position: relative;
  width: 28px;
  height: 3px;
  background-color: white;
  display: inline-block;
  margin: 0 10px;
}
.cardNumber2 div span {
  position: relative;
  width: 28px;
  height: 3px;
  background-color: white;
  display: inline-block;
  margin: 22px 10px;
}
.cardNumber div {
  display: flex;
  align-items: center;
}
.cardNumber2 {
  display: flex;
  margin-right: 50px;
}
.cardNumber2 input {
  width: 74px;
  height: 50px;
  font-family: "Rubik";
  font-size: 17px;
  background-color: gray;
  border-radius: 10px;
}
.cardNumber2 :nth-child(3) {
  margin-right: 50px;
}

.buttonWrapper {
  margin-top: 100px;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.cancelButton {
  font-family: "Roboto";
  font-size: 18px;
  color: #ffffff;
  border: none;
  padding: 15px 50px;
  background-color: inherit;
}

.purpleButton {
  font-family: "Roboto";
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
  background-color: var(--mainPurple);
  border-radius: 5px;
  padding: 15px 75px;
}

@media only screen and (max-width: 1201px) {
  .modalWrapper {
    padding: 58px 211px;
  }
  .h1 {
    font-size: 16.5px;
    color: #ffffff;
    margin-bottom: 45px;
  }
  .cardholderName {
    font-size: 16.5px;
  }
  .cardholderName input {
    margin-top: 12.5px;
  }
  .cardholderName input {
    height: 41.5px;
  }
  .cardNumberLabel {
    font-size: 16.5px;
    padding-top: 29px;
    margin-bottom: 12.5px;
  }
  .cvv {
    font-size: 16.5px;
  }
  .expDate {
    font-size: 16.5px;
    margin-top: 25px;
    margin-bottom: 12.5px;
  }

  .cardNumber input {
    width: 61.5px;
    height: 41.5px;
    font-size: 14px;
  }
  .cardNumber div span {
    width: 23px;
    height: 2.5px;
    margin: 0 8.5px;
  }
  .cardNumber2 div span {
    width: 23px;
    height: 3px;
    margin: 18px 8.5px;
  }

  .cardNumber2 {
    margin-right: 41.5px;
  }
  .cardNumber2 input {
    width: 61.5px;
    height: 41.5px;
    font-size: 14px;
  }
  .cardNumber2 :nth-child(3) {
    margin-right: 41.5px;
  }
  .buttonWrapper {
    margin-top: 83px;
  }
  .cancelButton {
    font-size: 15px;
    padding: 12.5px 41.5px;
  }
  .purpleButton {
    font-size: 15px;
    line-height: 17.5px;
    padding: 12.5px 62px;
  }
}

/* //table and mobile // */

@media only screen and (max-width: 1024px) {
  .modalWrapper {
    padding: 58px 100px;
  }
}
  @media only screen and (max-width: 768px) {
    .modalWrapper {
      padding: 40px 70px;
    }
    .h1 {
      font-size: 15px;
      margin-bottom: 45px;
    }
    .cardholderName {
      font-size: 15px;
    }
    .cardholderName input {
      margin-top: 12px;
    }
    .cardholderName input {
      height: 40px;
    }
    .cardNumberLabel {
      font-size: 15px;
      padding-top: 29px;
      margin-bottom: 12.5px;
    }
    .cvv {
      font-size: 15px;
    }
    .expDate {
      font-size: 15px;
      margin-top: 25px;
      margin-bottom: 12.5px;
    }

    .cardNumber input {
      width: 50px;
      height: 35px;
      font-size: 14px;
    }
    .cardNumber div span {
      width: 23px;
      height: 2.5px;
      margin: 0 8.5px;
    }
    .cardNumber2 div span {
      width: 23px;
      height: 2.5px;
      margin: 18px 8.5px;
    }

    .cardNumber2 {
      margin-right: 41.5px;
    }
    .cardNumber2 input {
      width: 50px;
      height: 35px;
      font-size: 14px;
    }
    .cardNumber2 :nth-child(3) {
      margin-right: 41.5px;
    }
    .buttonWrapper {
      margin-top: 83px;
    }
    .cancelButton {
      font-size: 15px;
      padding: 12.5px 41.5px;
    }
    .purpleButton {
      font-size: 15px;
      line-height: 17.5px;
      padding: 12.5px 62px;
    }
  }

  @media only screen and (max-width: 480px) {
    .modalWrapper {
      padding: 25px 40px;
    }
    .h1 {
      font-size: 15px;
      margin-bottom: 45px;
    }
    .cardholderName {
      font-size: 15px;
    }
    .cardholderName input {
      margin-top: 12px;
    }
    .cardholderName input {
      height: 30px;
    }
    .cardNumberLabel {
      font-size: 15px;
      padding-top: 29px;
      margin-bottom: 12.5px;
    }
    .cvv {
      font-size: 15px;
    }
    .expDate {
      font-size: 15px;
      margin-top: 25px;
      margin-bottom: 12.5px;
    }

    .cardNumber input {
      width: 40px;
      height: 30px;
      font-size: 14px;
    }
    .cardNumber div span {
      width: 23px;
      height: 2.5px;
      margin: 0 8.5px;
    }
    .cardNumber2 div span {
      width: 23px;
      height: 2.5px;
      margin: 18px 8.5px;
    }

    .cardNumber2 {
      margin-right: 41.5px;
    }
    .cardNumber2 input {
      width: 40px;
      height: 30px;
      font-size: 14px;
    }
    .cardNumber2 :nth-child(3) {
      margin-right: 41.5px;
    }
    .buttonWrapper {
      margin-top: 83px;
    }
    .cancelButton {
      font-size: 15px;
      padding: 12.5px 41.5px;
    }
    .purpleButton {
      font-size: 15px;
      line-height: 17.5px;
      padding: 12.5px 62px;
    }
  }

