.containter {
  width: 84%;
}
.wrapper {
  position: relative;
  margin-right: 33px;
  margin-left: 50px;
  padding-bottom: 10px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.headerWrapper {
  display: flex;
  justify-content: space-between;
  padding: 35px 35px 0 60px;
  width: 100%;
  vertical-align: middle;
}
.header {
  display: flex;
  align-items: center;
}

.header i {
  padding-right: 30px;
  font-size: 40px;
  color: var(--mainPurple);
}

.h1 {
  font-family: "Montserrat";
  font-size: 16px;
  letter-spacing: 0.02em;
  color: #000000;
  margin: 0;
  line-height: 20px;
}
.purpleButton {
  font-family: "Montserrat";
  font-size: 16px;
  color: #ffffff;
  background-color: var(--mainPurple);
  padding: 11px 43px;
  border-radius: 5px;
  cursor: pointer;
  letter-spacing: 0.02em;
}
.purpleButton:hover {
  background-color: var(--lighterMain);
  transition: 0.5s;
}

.contentWrapper {
  background-color: rgba(214, 214, 214, 0.1);
  border-radius: 10px;
  margin: 0px 35px 25px 50px;
  padding: 30px 0px 30px 35px;
}
.contentWrapper h1 {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}
.purple {
  color: var(--mainPurple);
  margin-bottom: 20px;
  margin-top: 30px;
}

@media only screen and (max-width: 1024px) {
  .containter {
    width: 100%;
    position: relative;
  }
  .wrapper {
    margin: 0 auto;
    max-width: 100%;
    padding: 0;
    margin-top: 100px;
    padding-bottom: 100px;
    margin: 100px 40px 100px 40px;
  }
  .header {
    align-items: center;
    justify-content: center;
    margin: 0;
  }
  .headerWrapper {
    display: flex;
    justify-content: space-between;
    padding: 35px 35px 80px 60px;
    width: 100%;
    vertical-align: middle;
  }

  .header i {
    padding-right: 25px;
    font-size: 25px;
    vertical-align: middle;
  }
  .header h1 {
    vertical-align: middle;
  }
  .h1 {
    font-family: "Montserrat";
    font-size: 16px;
    letter-spacing: 0.02em;
    color: #000000;
    display: inline-block;
    margin: 0;
  }
  .purpleButton {
    font-family: "Montserrat";
    font-size: 16px;
    color: #ffffff;
    background-color: var(--mainPurple);
    padding: 11px 43px;
    border-radius: 5px;
    cursor: pointer;
    letter-spacing: 0.02em;
  }

  .contentWrapper {
    background-color: rgba(214, 214, 214, 0.1);
    border-radius: 10px;
    margin: 0px 35px 25px 50px;
    padding: 30px 0px 10px 35px;
  }
  .contentWrapper h1 {
    font-size: 16px;
  }
  .purple {
    color: var(--mainPurple);
    margin-bottom: 20px;
    margin-top: 30px;
  }
}

@media only screen and (max-width: 768px) {
  .wrapper {
    margin: 0 auto;
    max-width: 100%;
    padding: 0;
    margin-top: 60px;
    padding-bottom: 60px;
    margin: 35px 20px 100px 20px;
  }
  .header {
    display: flex;
    margin: 0;
    align-self: center;
    margin-bottom: 30px;
  }
  .headerWrapper {
    display: block;
    align-items: center;
    padding: 20px 40px 40px 40px;
    width: 100%;
  }

  .header i {
    padding-right: 25px;
    font-size: 35px;
  }
  .header h1 {
    vertical-align: middle;
  }
  .buttonWrapper {
    text-align: center;
  }
  .h1 {
    font-family: "Montserrat";
    font-size: 16px;
    letter-spacing: 0.02em;
    color: #000000;
    display: inline-block;
    margin: 0;
  }
  .purpleButton {
    font-family: "Montserrat";
    font-size: 16px;
    color: #ffffff;
    background-color: var(--mainPurple);
    padding: 11px 70px;
    border-radius: 5px;
    cursor: pointer;
    letter-spacing: 0.02em;
    align-items: center;
  }

  .contentWrapper {
    background-color: rgba(214, 214, 214, 0.1);
    border-radius: 10px;
    margin: 40px 35px 25px 50px;
    padding: 30px 0px 10px 35px;
  }
  .contentWrapper h1 {
    font-size: 16px;
  }
  .purple {
    color: var(--mainPurple);
    margin-bottom: 20px;
    margin-top: 30px;
  }
}
