input {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
  padding-left: 25px;
}
input:focus {
  border-color: black;
}
.modalWrapper {
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  top: 0;
  left: 0;
  width: 100%;
}
.headerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 34px 40px 0px 62px;
}
.iconWrapper {
  display: flex;
  align-items: center;
}
.iconWrapper i {
  padding-right: 30px;
}
.headerWrapper i {
  font-size: 44px;
  color: var(--mainPurple);
}
.buttonWrapper {
  font-size: 16px;
  font-family: "Montserrat";
  letter-spacing: 0.02em;
}

.whiteButton {
  color: var(--mainPurple);
  background-color: white;
  padding: 10px 15px;
  border-radius: 5px;
  margin-right: 30px;
  border: 1px solid var(--mainPurple);
}
.whiteButton:hover {
  color: var(--lighterMain);
  background-color: rgba(214, 214, 214,0.1);
  transition: 0.5s;
}
.purpleButton {
  color: white;
  background-color: var(--mainPurple);
  padding: 10px 28px;
  border-radius: 5px;
}
.purpleButton:hover {
  background-color: var(--lighterMain);
  transition: 0.5s;
}
.contentWrapper {
  margin: 0px 0px 0px 30px;
  padding: 35px 400px 35px 30px;
  background: rgba(214, 214, 214, 0.1);
  border-radius: 5px;
}

.passwordInput i {
  position: absolute;
  font-size: 24px;
  color: black;
  cursor: pointer;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  transition: font-size 0.5s;
}
.passwordInput {
  position: relative;
  width: 540px;
}
.purpleH1 {
  font-family: "Montserrat";
  font-weight: bold;
  letter-spacing: 0.02em;
  color: var(--mainPurple);
  font-size: 16px;
}
.inputWrapper label {
  display: block;
}
.inputWrapper input {
  background: #ffffff;
  border: 1px solid var(--mainPurple);
  width: 540px;
  height: 52px;
  border-radius: 5px;
}
.fieldWrapper {
  margin-bottom: 20px;
  position: relative;
}
.inputWrapper {
  margin-bottom: 50px;
}
.inputWrapperInput {
  position: relative;
}

@media only screen and (max-width: 1201px) {
  input {
    font-size: 15px;
    line-height: 17.5px;
    padding-left: 21px;
  }

  .headerWrapper {
    padding: 28px 33px 0px 51.5px;
  }
  .iconWrapper i {
    padding-right: 25px;
  }
  .headerWrapper i {
    font-size: 36.5px;
  }
  .buttonWrapper {
    font-size: 13px;
  }
  .whiteButton {
    padding: 8.5px 12.5px;
    margin-right: 25px;
  }
  .purpleButton {
    padding: 8.5px 23px;
  }
  .contentWrapper {
    margin: 0px 0px 0px 25px;
    padding: 29px 332px 29px 25px;
  }

  .passwordInput i {
    font-size: 20px;
    right: 8.3px;
  }
  .passwordInput {
    width: 448px;
  }
  .purpleH1 {
    font-size: 13px;
  }
  .inputWrapper input {
    width: 448px;
    height: 43px;
  }
  .fieldWrapper {
    margin-bottom: 16.5px;
  }
  .inputWrapper {
    margin-bottom: 41.5px;
  }
}

/* mobile & tablet */

@media only screen and (max-width: 1024) {
  input {
    font-size: 15px;
    line-height: 17.5px;
    padding-left: 21px;
  }

  .headerWrapper {
    display: grid;
    padding: 28px 33px 0px 51.5px;
    align-items: center;
  }
  .iconWrapper {
    display: flex;
    align-self: center;
  }
  .iconWrapper i {
    padding-right: 25px;
  }
  .headerWrapper i {
    font-size: 36.5px;
  }
  .buttonWrapper {
    font-size: 13px;
  }
  .whiteButton {
    padding: 8.5px 12.5px;
    margin-right: 25px;
  }
  .purpleButton {
    padding: 8.5px 23px;
  }
  .contentWrapper {
    margin: 0px 25px 0px 25px;
    padding: 30px 30px 30px 30px;
  }

  .passwordInput i {
    font-size: 20px;
    right: 8.3px;
  }
  .passwordInput {
    width: 448px;
  }
  .purpleH1 {
    font-size: 13px;
  }
  .inputWrapper input {
    width: 90%;
    height: 43px;
  }
  .fieldWrapper {
    margin-bottom: 16.5px;
  }
  .inputWrapper {
    margin-bottom: 41.5px;
  }
}

@media only screen and (max-width: 768px) {
  input {
    font-size: 15px;
    line-height: 17.5px;
    padding-left: 21px;
  }

  .headerWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 20px 20px 20px;
  }
  .iconWrapper {
    display: flex;
    margin: 0;
    margin-bottom: 30px;
  }
  .iconWrapper h4 {
    margin: 0;
  }
  .buttonWrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .whiteButton {
    padding: 8.5px 12.5px;
    margin-right: 25px;
  }
  .purpleButton {
    padding: 8.5px 23px;
  }
  .contentWrapper {
    margin: 0px 25px 0px 25px;
    padding: 30px 0px 30px 0px;
  }

  .passwordInput i {
    font-size: 20px;
    right: 8.3px;
  }
  .passwordInput {
    width: 100%;
  }
  .passwordInput input {
    width: 100%;
  }
  .purpleH1 {
    font-size: 13px;
  }

  .inputWrapper input {
    width: 100%;
    height: 40px;
  }
  .fieldWrapper {
    margin-bottom: 16.5px;
  }
  .inputWrapper {
    margin-bottom: 41.5px;
    width: 100%;
  }
}
