.background {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.modalWrapper {
  text-align: center;
  display: flex;
  justify-content: space-around;
  /* overflow: auto; */
}
.modal {
  width: 26.5%;
  box-sizing: border-box;
  border-radius: 10px;
}

.modalHeader {
  text-align: center;
  background: white;
  padding: 0px 0 15px;
  font-weight: 500;
  font-size: 24px;
  letter-spacing: 0.02em;
}
.modalHeader i {
  font-size: 80px;
  margin-bottom: 30px;
  display: inline-block;
}
.modalHeader h2 {
  margin-bottom: 50px;
  text-transform: capitalize;
}
.modalContent {
  padding: 30px 20px;
  background: #e6e6e6;
  position: relative;
  text-align: center;
}
.paragraph {
  margin: 0;
  text-align: left;
  margin-bottom: 70px;
  letter-spacing: 0.02em;
}
.modalContent::before {
  content: "";
  width: calc(100% - 40px);
  margin: 0 auto;
  display: block;
  background-color: rgba(0, 0, 0, 0.2);
  height: 1px;
  position: absolute;
  top: 0px;
  left: 20px;
}
.credit {
  margin-bottom: 25px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;
  font-family: "Rubik";
  text-align: center;
}
.button {
  padding: 10px 50px;
  background-color: var(--mainPurple);
  color: white;
  border-radius: 5px;
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.02em;
  cursor: pointer;
  text-align: center;
}
.swiper-wrapper {
  max-width: 90%;
}
.buttonWrapper {
  padding: 10px 0;
  width: 100%;
}
.xButton {
  float: right;
  font-weight: 700;
  font-size: 25px;
  color: rgba(0, 0, 0, 0.6);
  margin-right: 10px;
  cursor: pointer;
}
.mobileHeaderHidden {
  display: none;
}

@media screen and (min-width: 768px) {
  .swiper-container {
    max-width: 600px;
  }
  .mobileHeader {
    display: flex;
    flex-direction: row;
    background-color: var(--mainPurple);
    justify-content: space-between;
    z-index: 999;
  }
  .modalHeader h2 {
    margin-bottom: 25px;
  }
  .modalHeader i {
    margin-bottom: 0;
  }
  .h2 {
    margin-top: 0;
  }
  .paragraph {
    margin-bottom: 0;
  }

  .swiperSlide {
    padding-left: 30px !important;
    padding-right: 30px !important;
    position: static;
    z-index: 9999;
    margin-top: 50px;
    /* overflow: scroll */
  }
}
@media screen and (min-width: 1024px) {
  .swiper-container {
    max-width: 950px;
  }
  .mobileHeader {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .swiperSlide {
    padding-left: 30px !important;
    padding-right: 30px !important;
    margin-top: 30px;
    position: static;
  }
  .mobileHeader {
    display: flex;
    flex-direction: row;
    background-color: var(--mainPurple);
    justify-content: space-between;
    z-index: 999;
  }
  .modalHeader h2 {
    margin-bottom: 25px;
  }
  .modalHeader i {
    margin-bottom: 0;
  }
  .h2 {
    margin-top: 0;
  }
  .paragraph {
    margin-bottom: 0;
  }
}
