.container {
  width: 84%;
}
.wrapper {
  margin-right: 33px;
  margin-left: 50px;
  padding-right: 33px;
  padding-bottom: 10px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.headerWrapper {
  display: flex;
  justify-content: space-between;
  padding-top: 35px;
  padding-left: 60px;
  width: 100%;
}

.header i {
  padding-right: 30px;
  font-size: 40px;
  color: var(--mainPurple);
}
.header h1 {
  margin-bottom: 30px;
  vertical-align: middle;
}
.h1 {
  font-family: "Montserrat";
  font-size: 16px !important;
  letter-spacing: 0.02em;
  color: #000000;
  display: inline-block;
}
.purpleButton {
  font-family: "Montserrat";
  font-size: 16px;
  color: #ffffff;
  background-color: var(--mainPurple);
  padding: 11px 43px;
  border-radius: 5px;
  cursor: pointer;
  letter-spacing: 0.02em;
}

.contentWrapper {
  background-color: rgba(214, 214, 214, 0.1);
  border-radius: 10px;
  margin: 40px 50px 25px 50px;
  padding: 30px 0px 0px 35px;
}
.creditH1 {
  font-family: "Montserrat";
  font-weight: bold;
  letter-spacing: 0.02em;
  font-size: 16px;
  color: #000000;
}

.credit {
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 35px;
  letter-spacing: 0.02em;
  color: var(--mainPurple);
}

@media only screen and (max-width: 1024px) {
  .container {
    width: 100%;
    position: relative;
  }
  .wrapper {
    margin: 0 auto;
    max-width: 100%;
    padding: 0;
    margin-top: 100px;
    padding-bottom: 100px;
    margin: 100px 40px 100px 40px;
  }
  .headerWrapper {
    display: flex;
    justify-content: space-between;
    padding: 40px 40px 40px 40px;
    margin: 0;
  }
  .header i {
    padding-right: 25px;
    font-size: 25px;
    color: var(--mainPurple);
  }
  .header h1 {
    margin-bottom: 30px;
    vertical-align: middle;
  }
  .h1 {
    font-family: "Montserrat";
    font-size: 16px !important;
    letter-spacing: 0.02em;
    color: #000000;
    display: inline-block;
  }
  .purpleButton {
    font-family: "Montserrat";
    font-size: 16px;
    color: #ffffff;
    background-color: var(--mainPurple);
    padding: 11px 43px;
    border-radius: 5px;
    cursor: pointer;
    letter-spacing: 0.02em;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    width: 100%;
    position: relative;
  }
  .contentWrapper {
    margin: 40px 20px 25px 20px;
    padding: 30px 0px 0px 35px;
  }
  .wrapper {
    margin: 0 auto;
    max-width: 100%;
    padding: 0;
    margin-top: 60px;
    padding-bottom: 60px;
    margin: 35px 20px 100px 20px;
  }
  .headerWrapper {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 40px 40px 40px;
    margin: 0;
  }
  .header i {
    padding-right: 25px;
    font-size: 35px;
    color: var(--mainPurple);
  }
  .header {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }
  .header h1 {
   align-items: center;
  }
  .h1 {
    font-family: "Montserrat";
    font-size: 16px !important;
    letter-spacing: 0.02em;
    color: #000000;
    display: inline-block;
  }
  .purpleButton {
    font-family: "Montserrat";
    font-size: 16px;
    color: #ffffff;
    background-color: var(--mainPurple);
    padding: 11px 36px;
    border-radius: 5px;
    cursor: pointer;
    letter-spacing: 0.02em;
  }
}
