@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Rubik:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Roboto:wght@700&family=Rubik:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Roboto:wght@700&family=Rubik:wght@400;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
:root {
  --mainPurple: #343F52;
  --lighterMain: #52617b;
}

button {
  border: none;
  cursor: pointer;
  background-color: inherit;
}
a {
  text-decoration: none;
}



input {
  border: none;
  color: black;
}
:focus {
  outline: none;
}
:root {
  --toastify-color-info: var(--mainPurple);
  --toastify-font-family: "Roboto";
}

.grecaptcha-badge {
  z-index: 9999;
}


body {
  margin: 0;
  overflow-x: hidden;
}
* {
  box-sizing: border-box;
}


.LoginSidebar_image__wLYLC {
  display: flex;
  justify-content: space-between;
  background: url(/static/media/image.e85f9d5a.png) no-repeat center center fixed;
  min-height: 100vh;
  height: 100%;
  mix-blend-mode: luminosity;
  background-size: cover;
}

.LoginSidebar_sidebar__2OQAd {
  width: 33%;
  background: rgba(0, 0, 0, 0.5);
  /* height: 100vh; */
  padding-left: 5.063rem;
}
.LoginSidebar_logo__2Xg4m {
  margin-top: 57px;
  margin-bottom: 35px;
}
.LoginSidebar_header__2h9Lr {
  font-family: Rubik;
  font-weight: bold;
  font-size: 1.5rem;
  color: #ffffff;
}
.LoginSidebar_paragraph__15V3n {
  margin-top: 8.063rem;
  margin-right: 3.563rem;
  font-family: Rubik;
  font-size: 1rem;
  color: #ffffff;
  line-height: 19px;
  font-weight: 400;
}

@media only screen and (max-width: 1300px) {
  .LoginSidebar_sidebar__2OQAd {
    padding-left: 73px;
  }
  .LoginSidebar_logo__2Xg4m {
    margin-top: 51px;
    margin-bottom: 31.5px;
    margin-right: 129px;
  }
  .LoginSidebar_header__2h9Lr {
    font-size: 21px;
  }
  .LoginSidebar_paragraph__15V3n {
    margin-top: 116px;
    margin-right: 51px;
    font-size: 14.5px;
    line-height: 17px;
  }
}

@media only screen and (max-width: 1201px) {
  .LoginSidebar_sidebar__2OQAd {
    padding-left: 67px;
  }
  .LoginSidebar_logo__2Xg4m {
    margin-top: 47px;
    margin-bottom: 29px;
    margin-right: 119px;
  }
  .LoginSidebar_header__2h9Lr {
    font-size: 19.5px;
  }
  .LoginSidebar_paragraph__15V3n {
    margin-top: 107px;
    margin-right: 47px;
    font-size: 13.5px;
    line-height: 15.5px;
  }
}

@media only screen and (max-width: 1024px) {
  .LoginSidebar_sidebar__2OQAd {
    display: none;
  }
}

.LoginCard_cardWrapper__avkfy {
  color: #ffffff;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  padding: 47px 121px 47px 121px;
}
.LoginCard_mobileLogoWrapper__3eMAd {
  display: none;
}
.LoginCard_contentWrapper__24o-l {
  width: 540px;
}

.LoginCard_login__3Cz-S {
  margin-bottom: 41px;
  font-family: "Rubik";
  font-weight: bold;
  font-size: 24px;
  margin: 0;
}
.LoginCard_inputWrapper__20HFR {
  display: flex;
  align-content: space-between;
  flex-direction: column;
  margin-bottom: 30px;
  margin-top: 41px;
}
.LoginCard_inputWrapper__20HFR label {
  margin-bottom: 10px;
  font-family: "Roboto";
  font-size: 18px;
}
.LoginCard_inputWrapper__20HFR input {
  border-radius: 10px;
  height: 52px;
  width: 100%;
  color: #fff !important;
  font-size: 24px;
  padding: 0 10px;
  background-color: rgba(255, 255, 255, 0.3) !important;
}

.LoginCard_inputWrapper__20HFR i {
  position: absolute;
  font-size: 24px;
  color: black;
  cursor: pointer;
  right: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  transition: font-size 0.5s;
}
.LoginCard_inputWrapper__20HFR i:hover {
  font-size: 28px;
}
.LoginCard_inputWrapperInput__1w1iL {
  position: relative;
}

.LoginCard_buttonWrapper__h00gl {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}
.LoginCard_forgotPassword__3RnfK {
  padding: 0;
  border: none;
  background: none;
  color: white;
  font-family: Roboto;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
  opacity: 0.8;
}
.LoginCard_forgotPassword__3RnfK:hover {
  transition: 0.5s;
  opacity: 1;
}
.LoginCard_logIn__2ixwR {
  /* color: var(--mainPurple); */
  color: rgb(0, 0, 0);
  background-color: white;
  border-radius: 10px;
  padding: 15px 65px;
  font-family: Roboto;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
}
.LoginCard_logIn__2ixwR:hover {
  color: #ffffff;
  background-color: #636F85;
  transition: 0.5s;
}

.LoginCard_button3__353KN {
  text-align: center;
  margin-top: 30px;
}
.LoginCard_registerButton__155RN {
  cursor: pointer;
  text-align: center;
  font-family: Roboto;
  font-size: 18px;
  background-color: var(--mainPurple);
  color: #d6d6d6;
  border-radius: 0.625rem;
  padding: 15px;
  color: #d6d6d6;
}
.LoginCard_registerButton__155RN:hover {
  color: var(--mainPurple);
  background-color: #ffffff;
  transition: 0.5s;
}
.LoginCard_noErrorBlock__2W1ZL {
  background-color: #bd3134;
  display: none;
  vertical-align: center;
  margin-top: 25px;
}

.LoginCard_errorBlock__YNY6p {
  background-color: #bd3134;
  display: flex;
  vertical-align: center;
  margin-top: 25px;
}

.LoginCard_errorBlock__YNY6p i {
  font-size: 30px;
  align-self: center;
  padding-left: 20px;
  padding-right: 15px;
}

.LoginCard_errorParagraph__27qLO {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
}

.LoginCard_ReCAPTCHA__17X9H {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.LoginCard_sCardWrapper__FI9_a {
  color: #ffffff;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  margin-top: 150px;
  padding: 47px 121px 47px 121px;
  height: 100%;
}
.LoginCard_sContentWrapper__r0i0o {
  width: 100%;
  min-width: 100%;
}
.LoginCard_notVerified__j6cBW {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
  color: #ffffff;
  text-align: center;
  margin-top: 117px;
}
.LoginCard_pWrapper__322sO {
  max-width: 578px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 25px;
  color: #ffffff;
  margin-top: 58px;
  margin-bottom: 85px;
}
.LoginCard_sButtonWrapper__30E5H {
  display: flex;
  justify-content: space-between;
  margin-bottom: 200px;
}
.LoginCard_blackButton__13brV {
  border: none;
  background-color: inherit;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
  cursor: pointer;
}
.LoginCard_whiteButton__3JmUN {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: var(--mainPurple);
  border-radius: 10px;
  background-color: #ffffff;
  padding: 16px 39px 16px 39px;
  cursor: pointer;
}

@media screen and (max-width: 1366px) {
  .LoginCard_cardWrapper__avkfy {
    padding: 42.3px 109px 42.3px 109px;
  }
  .LoginCard_contentWrapper__24o-l {
    width: 486px;
  }

  .LoginCard_login__3Cz-S {
    margin-bottom: 36px;
    font-size: 21.6px;
    margin: 0;
  }
  .LoginCard_inputWrapper__20HFR {
    margin-bottom: 27px;
    margin-top: 37px;
  }
  .LoginCard_inputWrapper__20HFR label {
    margin-bottom: 9px;
    font-size: 16px;
  }
  .LoginCard_inputWrapper__20HFR input {
    height: 46px;
    font-size: 22px;
  }

  .LoginCard_inputWrapper__20HFR i {
    font-size: 22px;
  }
  .LoginCard_inputWrapper__20HFR i:hover {
    font-size: 25px;
  }
  .LoginCard_forgotPassword__3RnfK {
    font-size: 17px;
  }
  .LoginCard_logIn__2ixwR {
    padding: 13.5px 58.5px;
    font-size: 18px;
  }

  .LoginCard_button3__353KN {
    margin-top: 62px;
  }
  .LoginCard_registerButton__155RN {
    font-size: 17px;
    padding: 13.5px;
  }

  .LoginCard_ReCAPTCHA__17X9H {
    margin-top: 45px;
  }
  .LoginCard_sCardWrapper__FI9_a {
    padding: 47px 80px 47px 80px;
  }
}
@media only screen and (max-width: 1300px) {
  .LoginCard_cardWrapper__avkfy {
    padding: 42px 80px 42px 80px;
  }
  .LoginCard_contentWrapper__24o-l {
    width: 486px;
  }

  .LoginCard_login__3Cz-S {
    margin-bottom: 37px;
    font-size: 21.6px;
    margin: 0;
  }
  .LoginCard_inputWrapper__20HFR {
    margin-bottom: 27px;
    margin-top: 37px;
  }
  .LoginCard_inputWrapper__20HFR label {
    margin-bottom: 9px;
    font-size: 16px;
  }
  .LoginCard_inputWrapper__20HFR input {
    height: 47px;
    font-size: 21.6px;
  }

  .LoginCard_inputWrapper__20HFR i {
    font-size: 21.6px;
  }
  .LoginCard_inputWrapper__20HFR i:hover {
    font-size: 25px;
  }

  .LoginCard_forgotPassword__3RnfK {
    font-size: 16px;
  }
  .LoginCard_logIn__2ixwR {
    padding: 13.5px 68.5px;
    font-size: 16px;
  }

  .LoginCard_button3__353KN {
    margin-top: 62px;
  }
  .LoginCard_registerButton__155RN {
    font-size: 16px;
    padding: 13.5px;
  }

  .LoginCard_ReCAPTCHA__17X9H {
    margin-top: 45px;
  }
}
@media only screen and (max-width: 1201px) {
  .LoginCard_cardWrapper__avkfy {
    padding: 40px 100px 40px 100px;
  }
  .LoginCard_contentWrapper__24o-l {
    width: 400px;
  }

  .LoginCard_login__3Cz-S {
    margin-bottom: 34px;
    font-size: 20px;
  }
  .LoginCard_inputWrapper__20HFR {
    margin-bottom: 27px;
    margin-top: 37px;
  }
  .LoginCard_inputWrapper__20HFR label {
    margin-bottom: 8.2px;
    font-size: 15px;
  }
  .LoginCard_inputWrapper__20HFR input {
    height: 43px;
    font-size: 19.4x;
  }

  .LoginCard_inputWrapper__20HFR i {
    font-size: 19.5x;
  }
  .LoginCard_inputWrapper__20HFR i:hover {
    font-size: 23px;
  }

  .LoginCard_forgotPassword__3RnfK {
    font-size: 15px;
  }
  .LoginCard_logIn__2ixwR {
    padding: 12.4px 63px;
    font-size: 15px;
  }

  .LoginCard_button3__353KN {
    margin-top: 57px;
  }
  .LoginCard_registerButton__155RN {
    font-size: 15px;
    padding: 12.5px;
  }

  .LoginCard_sCardWrapper__FI9_a {
    padding: 47px 40px 47px 40px;
  }
}
@media only screen and (max-width: 1024px) {
  section {
    margin: 0 auto;
    min-width: 65%;
  }
  
  .LoginCard_mobileLogoWrapper__3eMAd {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 60px;
  }
  .LoginCard_logo__1iqtw {
    width: 110px;
    height: 32px;
  }
  .LoginCard_cardWrapper__avkfy {
    margin: 0 auto;
    width: 100%;
    max-width: 90%;
    padding: 0;
    position: static;
    -webkit-transform: none;
            transform: none;
    margin-bottom: 30px;
  }
  .LoginCard_contentWrapper__24o-l {
    width: 100%;
    padding: 40px 80px 95px;
  }

  .LoginCard_sCardWrapper__FI9_a {
    margin: 150px auto 0 auto;
    width: 80%;
  }
}

@media only screen and (min-width: 1920px) {
  .LoginCard_cardWrapper__avkfy {
    margin-right: 0;
    padding: 40px 100px 40px 100px;
  }
}

@media only screen and (max-width: 768px) {
  .LoginCard_cardWrapper__avkfy {
    max-width: 90%;
    position: static;
    -webkit-transform: none;
            transform: none;
    margin-bottom: 30px;
  }
  .LoginCard_contentWrapper__24o-l {
    padding: 20px 30px 50px;
  }

  .LoginCard_buttonWrapper__h00gl {
    flex-direction: column;
    align-items: center;
  }

  .LoginCard_logIn__2ixwR {
    padding: 16.5px 72px;
    margin-bottom: 20px;
  }

  .LoginCard_button3__353KN {
    margin-top: 20px;
  }
  .LoginCard_sCardWrapper__FI9_a {
    margin: 150px auto 0 auto;
    width: 90%;
  }
}


@-webkit-keyframes LoadingSpinner_lds-ring__2rmRW {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes LoadingSpinner_lds-ring__2rmRW {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.LoadingSpinner_loader__3t96B:before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  width: 100vw;
  height: 100vh;
  z-index: 999;
}
.LoadingSpinner_loader__3t96B:after {
  content: "";
  position: fixed;
  top:50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  background: transparent;
  border: 3px solid transparent;
  border-top: 3px solid var(--mainPurple);
  border-bottom: 3px solid var(--mainPurple);
  z-index: 99999;
  border-radius: 50%;
  outline: 3px solid white;
  outline-offset: -6px;
  -webkit-animation: LoadingSpinner_lds-ring__2rmRW 1s linear infinite;
          animation: LoadingSpinner_lds-ring__2rmRW 1s linear infinite;
}


.CreateAccount_section__xVOVu {
  width: 67%;
  display: flex;
  justify-content: center;
  align-items: center;
}


.CreateAccount_cardWrapper__OZRfI {
  color: #ffffff;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  padding: 25px 121px 25px 121px;
  position: static;
  margin-top: 70px;
  /* margin-right: 65px; */
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  
}
.CreateAccount_mobileLogoWrapper__34k5F {
  display: none;
}
.CreateAccount_logo__3-7un {
  width: 110px;
  height: 32px;
}
.CreateAccount_h3__2cWGO {
  font-family: "Rubik";
  font-size: 24px;
  color: #ffffff;
  padding-bottom: 14px;
  margin: 25px 0 25px 0;
}
.CreateAccount_inputWrapper__3QgYo {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  width: 540px;
}
.CreateAccount_inputWrapper__3QgYo i {
  position: absolute;
  font-size: 24px;
  color: black;
  cursor: pointer;
  right: 10px;
  bottom: 15px;
  transition: font-size 0.5s;
}
.CreateAccount_inputWrapper__3QgYo label {
  margin-bottom: 10px;
  font-size: 18px;
  font-family: "Roboto";
}
.CreateAccount_inputWrapper__3QgYo input {
  border-radius: 10px;
  height: 52px;
  font-size: 24px;
  color: #fff;
  background: rgba(255, 255, 255, 0.3);
}

.CreateAccount_buttonWrapper__3aPDQ {
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  margin-bottom: 25px;
}

.CreateAccount_backButton__aLRNZ {
  border: none;
  padding: 0;
  border: none;
  background: none;
  color: white;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
  opacity: 0.8;
}
.CreateAccount_backButton__aLRNZ:hover {
  opacity: 1;
  transition: 0.5s;
}
.CreateAccount_createAccButton__5SuWg {
  color: #fff;
  background-color: transparent;
  border-radius: 10px;
  border: 2px solid #fff;
  padding: 16px 32px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
}
.CreateAccount_createAccButton__5SuWg:hover {
  color: rgb(0, 0, 0);
  background-color: #fff;
  transition: 0.5s;
}
.CreateAccount_errorBlock__36FTX {
  background-color: #bd3134;
  display: none;
  vertical-align: center;
  margin-top: 25px;
}

.CreateAccount_errorBlock__36FTX i {
  font-size: 30px;
  align-self: center;
  padding-left: 20px;
  padding-right: 15px;
}

.CreateAccount_errorParagraph__1gJno {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
}
.CreateAccount_errorMessage__3PI5B {
  display: none;
  position: absolute;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: rgb(189, 49, 52);
  margin: 0;
  bottom: -7px;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
}

.CreateAccount_ReCAPTCHA__1L3DW {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.CreateAccount_successMessage__1VNhk {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
  color: #ffffff;
  text-align: center;
  margin-top: 150px;
}
.CreateAccount_pWrapper__3pSIb {
  margin: 0;
  margin-top: 60px;
  margin-bottom: 80px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 25px;
  color: #ffffff;
  max-width: 580px;
}
.CreateAccount_sButtonWrapper__eJZmL {
  display: flex;
  justify-content: center;
  margin-bottom: 280px;
}
.CreateAccount_closeButton__2mLjC {
  color: var(--mainPurple);
  background-color: white;
  border-radius: 10px;
  padding: 16px 70px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
}

@media only screen and (max-width: 1300px) {
  .CreateAccount_cardWrapper__OZRfI {
    margin-right: 58.5px;
    padding: 18px 109px 36px 109px;
  }
  .CreateAccount_h3__2cWGO {
    font-size: 21.5px;
    padding-bottom: 12.5px;
  }
  .CreateAccount_inputWrapper__3QgYo {
    margin-bottom: 24.5px;
    width: 486px;
  }
  .CreateAccount_inputWrapper__3QgYo i {
    font-size: 21.5px;
    right: 9px;
    bottom: 13px;
  }
  .CreateAccount_inputWrapper__3QgYo label {
    margin-bottom: 9px;
    font-size: 16px;
  }
  .CreateAccount_inputWrapper__3QgYo input {
    height: 47px;
    font-size: 21.5px;
  }

  .CreateAccount_buttonWrapper__3aPDQ {
    margin-top: 40px;
  }

  .CreateAccount_backButton__aLRNZ {
    font-size: 16px;
  }

  .CreateAccount_createAccButton__5SuWg {
    padding: 14px 29px;
    font-size: 16px;
  }
  .CreateAccount_ReCAPTCHA__1L3DW {
    margin-top: 18px;
  }
}

@media only screen and (max-width: 1201px) {
  .CreateAccount_cardWrapper__OZRfI {
    margin-right: 54px;
    padding: 16.5px 80px 33px 80px;
  }
  .CreateAccount_h3__2cWGO {
    font-size: 20px;
    padding-bottom: 11.5px;
  }
  .CreateAccount_inputWrapper__3QgYo {
    margin-bottom: 20px;
    width: 447px;
  }
  .CreateAccount_inputWrapper__3QgYo i {
    font-size: 20px;
    right: 8px;
    bottom: 12px;
  }
  .CreateAccount_inputWrapper__3QgYo label {
    margin-bottom: 8px;
    font-size: 15px;
  }
  .CreateAccount_inputWrapper__3QgYo input {
    height: 40px;
    font-size: 20px;
  }

  .CreateAccount_buttonWrapper__3aPDQ {
    margin-top: 40px;
  }

  .CreateAccount_backButton__aLRNZ {
    font-size: 15px;
  }
  .CreateAccount_createAccButton__5SuWg {
    padding: 13px 27px;
    font-size: 15px;
  }
  .CreateAccount_ReCAPTCHA__1L3DW {
    margin-top: 16.5px;
  }
}
@media only screen and (max-width: 1024px) {
  
  .CreateAccount_section__xVOVu {
    margin: 0 auto;
    min-width: 65%;
    flex-direction: column;
    justify-content: flex-start;
  }
  .CreateAccount_cardWrapper__OZRfI {
    position: static;
    top: 0;
    left: 0;
    -webkit-transform: none;
            transform: none;
    margin: 0 auto;
    width: 90%;
    padding: 25px 10px;
    margin-bottom: 30px;
  }
  .CreateAccount_mobileLogoWrapper__34k5F {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  
  .CreateAccount_contentWrapper__1oDZT {
    width: 100%;
    padding: 40px 80px 50px;
  }
  .CreateAccount_logo__3-7un {
    width: 110px;
    height: 32px;
  }
  .CreateAccount_h3__2cWGO {
    margin: 0;
    margin-bottom: 15px;
    font-size: 20px;
  }
  .CreateAccount_inputWrapper__3QgYo {
    width: 100%;
    margin-bottom: 10px;
  }
  .CreateAccount_inputWrapper__3QgYo i {
    font-size: 20px;
    right: 8px;
    bottom: 12px;
  }
  .CreateAccount_inputWrapper__3QgYo label {
    margin-bottom: 8px;
    font-size: 15px;
  }
  .CreateAccount_inputWrapper__3QgYo input {
    height: 40px;
    font-size: 20px;
  }

  .CreateAccount_buttonWrapper__3aPDQ {
    margin-top: 30px;
  }

  .CreateAccount_backButton__aLRNZ {
    font-size: 15px;
  }
  .CreateAccount_createAccButton__5SuWg {
    padding: 13px 27px;
    font-size: 15px;
  }
  .CreateAccount_ReCAPTCHA__1L3DW {
    margin-top: 10px;
  }
}
@media only screen and (max-width: 768px) {
  .CreateAccount_cardWrapper__OZRfI {
    max-width: 95%;
    margin-bottom: 30px;
    padding: 20px 10px;
  }
  .CreateAccount_mobileLogoWrapper__34k5F {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .CreateAccount_contentWrapper__1oDZT {
    width: 100%;
    padding: 30px 30px 20px;
  }

  .CreateAccount_h3__2cWGO {
    margin: 0;
    margin-bottom: 15px;
    font-size: 20px;
  }
  .CreateAccount_inputWrapper__3QgYo {
    width: 100%;
    margin-bottom: 10px;
  }
  .CreateAccount_inputWrapper__3QgYo i {
    font-size: 20px;
    right: 8px;
    bottom: 12px;
  }
  .CreateAccount_inputWrapper__3QgYo label {
    margin-bottom: 8px;
    font-size: 15px;
  }
  .CreateAccount_inputWrapper__3QgYo input {
    height: 40px;
    font-size: 20px;
  }

  .CreateAccount_buttonWrapper__3aPDQ {
    margin-top: 30px;
  }

  .CreateAccount_backButton__aLRNZ {
    font-size: 15px;
  }
  .CreateAccount_createAccButton__5SuWg {
    padding: 13px 27px;
    font-size: 15px;
  }
  .CreateAccount_sButtonWrapper__eJZmL {
    margin-bottom: 100px;
  }
  .CreateAccount_successMessage__1VNhk {
    margin-top: 100px;
  }
}
@media only screen and (min-width: 1920px) {
  .CreateAccount_cardWrapper__OZRfI {
    margin-right: 140px;
  }
}

.DashboardDevices_container__1Z-nW {
  width: 84%;
}
.DashboardDevices_wrapper__LjN2i {
  position: relative;
  margin-left: 50px;
  margin-right: 33px;
  margin-bottom: 40px;
  padding: 50px 35px 30px 35px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.DashboardDevices_modalOpen__2Qp2Z {
  box-shadow: none;
}

.DashboardDevices_icon-devices__3vrg5 {
  margin-right: 30px;
}
.DashboardDevices_inputSearch__1_3Yc {
  width: 300px;
  background-color: rgba(214, 214, 214, 0.1);
  color: black;
  border-radius: 5px;
  height: 40px;
  font-family: "Roboto";
  margin-right: 4px;
}

.DashboardDevices_purpleButton__1mKcL {
  font-family: "Rubik";
  font-size: 14px;
  color: #ffffff;
  background-color: var(--mainPurple);
  padding: 11px 35px;
  border-radius: 5px;
}
.DashboardDevices_purpleButton__1mKcL:hover {
  background-color: var(--lighterMain);
  transition: 0.5s;
}
.DashboardDevices_titleWrapper__1uOph {
  display: flex;
  align-items: center;
}
.DashboardDevices_h1__3Z2wj {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #000000;
  letter-spacing: 0.02em;
}
.DashboardDevices_header__16Scg i {
  padding-right: 30px;
  color: var(--mainPurple);
  font-size: 30px;
}

.DashboardDevices_header__16Scg {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  vertical-align: middle;
}

.DashboardDevices_devicesArr__2tZeK {
  color: var(--mainPurple);
  font-family: Montserrat;
  font-weight: bold;
  font-size: 16px;
  background-color: white;
}
.DashboardDevices_devicesArr__2tZeK td,
tr {
  text-align: left;
  vertical-align: middle;
  width: calc(100% / 5);
}
.DashboardDevices_tableHeader__2T6PJ {
  table-layout: fixed;
  width: 100%;
  padding: 0 35px 0 25px;
  border-spacing: 0 29px;
  background-color: rgba(214, 214, 214, 0.1);
}

.DashboardDevices_rows__3D__L {
  margin-bottom: 30px;
  font-family: Montserrat;
  font-style: normal;
  font-size: 16px;
  color: #000000;
}

td {
  width: calc(100% / 5);
  padding: 20px 30px;
}

.DashboardDevices_table__2lU93 {
  border-collapse: collapse;
}

.DashboardDevices_table__2lU93 .DashboardDevices_rows__3D__L {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.DashboardDevices_table__2lU93 .DashboardDevices_rows__3D__L:last-child {
  border-bottom: 0;
}
.DashboardDevices_iconTV__5u2lT {
  padding-left: 38px !important;
}

.DashboardDevices_devicesArr__2tZeK > td {
  padding: 25px 30px;
}
.DashboardDevices_deviceName__2GpOO {
  font-weight: bold;
  position: relative;
  font-size: 16px !important;
}
.DashboardDevices_deviceName__2GpOO input {
  width: 100%;
  padding: 0;
  background-color: inherit;
  text-overflow: ellipsis;
}
.DashboardDevices_deviceName__2GpOO i {
  font-size: 18px;
  color: black;
  background-color: #fff;
}
.DashboardDevices_deviceName__2GpOO button {
  position: absolute;
  background-color: #fff;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  display: block;
  left: 90%;
}
.DashboardDevices_Active__1RWmL {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #17bc27;
}

.DashboardDevices_Deactive__R4pkP {
  color: #ff0000;
}
.DashboardDevices_removeButton__WejzX {
  padding-left: 40px;
  padding-right: 0;
}
.DashboardDevices_removeButton__WejzX button {
  padding: 0;
  border: none;
  background: none;
  margin-right: 10px;
  cursor: pointer;
}
.DashboardDevices_searchIcon__1_491 {
  display: none;
}

@media only screen and (max-width: 1300px) {
  .DashboardDevices_wrapper__LjN2i {
    margin-left: 45px;
    margin-right: 30px;
    margin-bottom: 36px;
    padding: 45px 35px 27px 35px;
  }

  .DashboardDevices_icon-devices__3vrg5 {
    margin-right: 27px;
  }
  .DashboardDevices_inputSearch__1_3Yc {
    width: 270px;
    height: 36px;
    margin-right: 4px;
  }

  .DashboardDevices_purpleButton__1mKcL {
    font-size: 12px;
    padding: 9px 32.5px;
  }

  .DashboardDevices_h1__3Z2wj {
    font-size: 14px;
  }
  .DashboardDevices_header__16Scg i {
    padding-right: 27px;
    font-size: 27px;
  }

  .DashboardDevices_header__16Scg {
    margin-bottom: 18px;
  }

  .DashboardDevices_devicesArr__2tZeK {
    font-size: 14px;
  }

  .DashboardDevices_tableHeader__2T6PJ {
    padding: 0 32px 0 22.5px;
    border-spacing: 0 27px;
  }

  .DashboardDevices_rows__3D__L {
    margin-bottom: 27px;
    font-size: 14px;
  }
  .DashboardDevices_table__2lU93 .DashboardDevices_rows__3D__L {
    padding: 18px 27px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }
  .DashboardDevices_devicesArr__2tZeK > td {
    padding: 22.5px 27px;
  }
  .DashboardDevices_deviceName__2GpOO {
    font-size: 14px;
  }

  .DashboardDevices_deviceName__2GpOO i {
    font-size: 16px;
  }

  .DashboardDevices_Active__1RWmL {
    font-size: 14px;
    line-height: 18px;
  }
  .DashboardDevices_removeButton__WejzX button {
    margin-right: 9px;
  }
}

@media only screen and (max-width: 1201px) {
  .DashboardDevices_wrapper__LjN2i {
    margin-left: 4px;
    margin-right: 27px;
    margin-bottom: 33px;
    padding: 40px 30px 24px 30px;
  }

  .DashboardDevices_icon-devices__3vrg5 {
    margin-right: 24px;
  }
  .DashboardDevices_inputSearch__1_3Yc {
    width: 248px;
    height: 33px;
    margin-right: 4px;
  }

  .DashboardDevices_purpleButton__1mKcL {
    font-size: 11px;
    padding: 9px 30px;
  }

  .DashboardDevices_h1__3Z2wj {
    font-size: 13px;
  }
  .DashboardDevices_header__16Scg i {
    padding-right: 25px;
    font-size: 25px;
  }

  .DashboardDevices_header__16Scg {
    margin-bottom: 16.5px;
  }

  .DashboardDevices_devicesArr__2tZeK {
    font-size: 13px;
  }

  .DashboardDevices_tableHeader__2T6PJ {
    padding: 0 29.5px 0 20.5px;
    border-spacing: 0 27px;
  }

  .DashboardDevices_rows__3D__L {
    margin-bottom: 25px;
    font-size: 13px;
  }
  .DashboardDevices_rows__3D__L > td {
    padding: 16.5px 25px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding-right: 0;
  }
  .DashboardDevices_devicesArr__2tZeK > td {
    padding: 20.5px 25px;
  }
  .DashboardDevices_deviceName__2GpOO {
    font-size: 13px;
  }

  .DashboardDevices_deviceName__2GpOO i {
    font-size: 14.7px;
  }

  .DashboardDevices_Active__1RWmL {
    font-size: 13px;
    line-height: 16.5px;
  }
  .DashboardDevices_removeButton__WejzX button {
    margin-right: 10px;
  }
}

@media only screen and (max-width: 1024px) {
  .DashboardDevices_container__1Z-nW {
    width: 100%;
    position: relative;
  }
  .DashboardDevices_wrapper__LjN2i {
    margin: 0 auto;
    max-width: 100%;
    padding: 0;
    margin-top: 100px;
    padding-bottom: 100px;
    margin: 37px 40px 100px 40px;
    padding: 20px 40px 20px 40px;
  }

  .DashboardDevices_type__2azNR,
  .DashboardDevices_model__2gdLE,
  .DashboardDevices_lastActivity__1k-ov,
  .DashboardDevices_iconTV__5u2lT {
    display: none;
  }
  /* .table {
    padding: ;
  } */
  .DashboardDevices_header__16Scg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px 40px 40px 40px;
    margin: 0;
  }
  .DashboardDevices_inputSearch__1_3Yc {
    opacity: 0;
  }
  .DashboardDevices_inputSearchNone__2YC9U {
    opacity: 1;
    display: block;
    background-color: #d6d6d6;
    border-radius: 5px;
    margin: 0 auto 20px;
    width: 100%;
    height: 38px;
    transition: opacity 0.5s ease-in-out;
  }
  .DashboardDevices_table__2lU93 {
    table-layout: fixed;
    width: 100%;
  }

  .DashboardDevices_titleWrapper__1uOph {
    justify-content: center;
    margin-bottom: 35px;
  }
  .DashboardDevices_buttonWrapper__2KaAm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .DashboardDevices_purpleButton__1mKcL {
    font-size: 14px;
    padding: 10px 35px;
    max-width: 30%;
  }

  .DashboardDevices_h1__3Z2wj {
    font-size: 16px;
  }
  .DashboardDevices_header__16Scg i {
    padding-right: 25px;
    font-size: 35px;
  }
  .DashboardDevices_header__16Scg i:last-child {
    font-size: 25px;
  }

  .DashboardDevices_header__16Scg {
    margin-bottom: 16.5px;
  }

  .DashboardDevices_devicesArr__2tZeK {
    font-size: 13px;
    max-width: 80%;
  }
  .DashboardDevices_devicesArr__2tZeK td:last-of-type {
    padding-right: 0;
    width: 10%;
  }

  .DashboardDevices_tableHeader__2T6PJ {
    padding: 0 29.5px 0 20.5px;
    border-spacing: 0 27px;
  }

  .DashboardDevices_rows__3D__L {
    margin-bottom: 25px;
    font-size: 13px;
  }
  .DashboardDevices_rows__3D__L > td {
    border: none;
  }

  .DashboardDevices_table__2lU93 .DashboardDevices_rows__3D__L {
    border: 0;
  }

  .DashboardDevices_deviceName__2GpOO {
    font-size: 13px;
  }

  .DashboardDevices_deviceName__2GpOO i {
    font-size: 14.7px;
  }
  .DashboardDevices_deviceName__2GpOO button {
    top: 51%;
    left: 80%;
  }
  .DashboardDevices_deviceName__2GpOO input {
    text-overflow: ellipsis;
  }

  .DashboardDevices_Active__1RWmL {
    font-size: 13px;
    line-height: 16.5px;
  }
  .DashboardDevices_removeButton__WejzX {
    padding: 0;
  }
  .DashboardDevices_removeButton__WejzX button {
    margin-right: 8px;
  }
  .DashboardDevices_searchIcon__1_491 {
    display: flex;
    background-color: #63269a;
    font-size: 21px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    padding: 8px;
    vertical-align: middle;
    position: absolute;
    top: 30px;
    right: 15px;
  }
}
@media only screen and (max-width: 768px) {
  .DashboardDevices_container__1Z-nW {
    width: 100%;
  }
  .DashboardDevices_wrapper__LjN2i {
    margin: 0 auto;
    max-width: 100%;
    padding: 0;
    margin-top: 60px;
    padding-bottom: 60px;
    margin: 35px 20px 100px 20px;
    padding: 20px 25px 20px 25px;
  }

  .DashboardDevices_type__2azNR,
  .DashboardDevices_model__2gdLE,
  .DashboardDevices_lastActivity__1k-ov,
  .DashboardDevices_iconTV__5u2lT {
    display: none;
  }

  .DashboardDevices_header__16Scg {
    display: block;
    justify-content: center;
    padding: 10px 20px 40px 20px;
    margin: 0;
  }
  .DashboardDevices_inputSearch__1_3Yc {
    opacity: 0;
  }
  .DashboardDevices_inputSearchNone__2YC9U {
    opacity: 1;
    display: block;
    background-color: #d6d6d6;
    border-radius: 5px;
    margin: 0 auto 20px;
    width: 100%;
    height: 38px;
    transition: opacity 1s ease-in-out;
  }
  .DashboardDevices_table__2lU93 {
    table-layout: fixed;
    width: 100%;
    padding-left: 30px;
  }
  .DashboardDevices_devicesArr__2tZeK td:last-of-type {
    width: 10%;
  }
  .DashboardDevices_titleWrapper__1uOph {
    justify-content: center;
    margin-bottom: 30px;
  }
  .DashboardDevices_buttonWrapper__2KaAm {
    display: flex;
    justify-content: center;
  }

  .DashboardDevices_purpleButton__1mKcL {
    font-size: 14px;
    padding: 10px 35px;
    max-width: 80%;
  }

  .DashboardDevices_h1__3Z2wj {
    font-size: 16px;
  }
  .DashboardDevices_header__16Scg i {
    padding-right: 25px;
    font-size: 35px;
  }

  .DashboardDevices_header__16Scg i:last-child {
    font-size: 25px;
  }

  .DashboardDevices_devicesArr__2tZeK {
    font-size: 12px;
    max-width: 100%;
  }
  .DashboardDevices_devicesArr__2tZeK:last-child {
    padding-right: 0;
  }

  .DashboardDevices_tableHeader__2T6PJ {
    padding: 0 10px 0 10px;
    border-spacing: 0 27px;
  }

  .DashboardDevices_rows__3D__L {
    margin-bottom: 25px;
    font-size: 13px;
  }
  .DashboardDevices_rows__3D__L > td {
    border: none;
  }
  .DashboardDevices_rows__3D__L > td:first-child {
    padding-right: 0;
  }
  .DashboardDevices_rows__3D__L > td:last-child {
    padding-right: 0;
  }

  .DashboardDevices_table__2lU93 .DashboardDevices_rows__3D__L:last-child {
    border-bottom: 0;
  }
  .DashboardDevices_deviceName__2GpOO {
    font-size: 13px;
  }
  .DashboardDevices_deviceName__2GpOO input {
    text-overflow: ellipsis;
    max-width: 6ch;
  }
  .DashboardDevices_deviceName__2GpOO i {
    font-size: 14.7px;
  }

  .DashboardDevices_Active__1RWmL {
    font-size: 13px;
    line-height: 16.5px;
  }
  .DashboardDevices_removeButton__WejzX button {
    margin-right: 8px;
  }
}

@media only screen and (max-width: 600px) {
  .DashboardDevices_devicesArr__2tZeK td:last-of-type {
    width: 15%;
  }
  .DashboardDevices_devicesArr__2tZeK > td {
    padding: 21px 15px;
  }
  .DashboardDevices_rows__3D__L > td {
    padding: 21px 15px;
  }
}
@media only screen and (max-width: 450px) {
  .DashboardDevices_devicesArr__2tZeK td:last-of-type {
    width: 20%;
  }
  .DashboardDevices_devicesArr__2tZeK > td {
    padding: 20px 10px;
  }
  .DashboardDevices_rows__3D__L > td {
    padding: 20px 10px;
  }
}

.AddDeviceModal_modalBackground__RXWcW {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.AddDeviceModal_cardWrapper__iSsEM {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  padding: 50px 75px;
}
.AddDeviceModal_h1__2Qc6g {
  font-family: Rubik;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #ffffff;
}
.AddDeviceModal_paragraph__127Zk {
  display: block;
  padding-top: 37px;
  font-family: "Roboto";
  font-size: 18px;
  line-height: 19px;
  color: #ffffff;
}
.AddDeviceModal_flexitems__30jb- {
  display: flex;
  justify-content: space-around;
  margin: 0;
  margin-top: 55px;
  margin-bottom: 110px;
  border: none;
}
.AddDeviceModal_container__3tKrl {
  height: 70px;
  align-items: center;
}
.AddDeviceModal_container__3tKrl .AddDeviceModal_character__2zatL {
  line-height: 75px;
  font-size: 36px;
  color: white;
  background-color: rgba(255, 255, 255, 0.3);
  border: 1px solid transparent;
  border-radius: 8px;
  margin-right: 35px;
  width: 75px;
  height: 75px;
}
.AddDeviceModal_container__3tKrl .AddDeviceModal_character__2zatL:last-of-type {
  margin: 0;
}

.AddDeviceModal_character__2zatL:last-child {
  margin-right: 0;
}

.AddDeviceModal_buttonWrapper__1OKMQ {
  cursor: pointer;
  color: #d6d6d6;
  float: right;
}
.AddDeviceModal_cancelButton__2wFAL {
  border: none;
  background-color: inherit;
  color: #ffffff;
  padding-right: 105px;
  font-family: Roboto;
  font-size: 18px;
  opacity: 0.8;
}
.AddDeviceModal_cancelButton__2wFAL:hover {
  opacity: 1;
}
.AddDeviceModal_purpleButton__gamHP {
  color: #ffffff;
  background: var(--mainPurple);
  border-radius: 5px;
  padding: 15px 45px;
  font-family: Roboto;
  font-size: 18px;
  font-weight: bold;
}
.AddDeviceModal_purpleButton__gamHP:hover {
  background-color: var(--lighterMain);
  transition: 0.5s;
}

@media only screen and (max-width: 1201px) {
  .AddDeviceModal_cardWrapper__iSsEM {
    padding: 41.5px 62px;
  }
  .AddDeviceModal_h1__2Qc6g {
    font-size: 20px;
    line-height: 23px;
  }
  .AddDeviceModal_paragraph__127Zk {
    padding-top: 31px;
    font-size: 15px;
    line-height: 15px;
  }
  .AddDeviceModal_flexitems__30jb- {
    margin-top: 45.6px;
    margin-bottom: 91px;
  }
  .AddDeviceModal_container__3tKrl {
    height: 58px;
  }
  .AddDeviceModal_container__3tKrl .AddDeviceModal_character__2zatL {
    line-height: 62px;
    font-size: 30px;
    margin-right: 39px;
    width: 62px;
    height: 62px;
  }
  .AddDeviceModal_cancelButton__2wFAL {
    padding-right: 87px;
    font-size: 15px;
  }
  .AddDeviceModal_purpleButton__gamHP {
    padding: 12.5px 37px;
    font-size: 15px;
  }
}

@media only screen and (max-width: 1024px) {
  .AddDeviceModal_container__3tKrl .AddDeviceModal_character__2zatL {
    line-height: 65px;
    font-size: 30px;
    color: white;
    background-color: rgba(255, 255, 255, 0.3);
    border: 1px solid transparent;
    border-radius: 8px;
    margin-right: 35px;
    width: 65px;
    height: 65px;
  }
  .AddDeviceModal_h1__2Qc6g {
    font-size: 16px;
    line-height: 20px;
    text-align: center;
  }
  .AddDeviceModal_paragraph__127Zk {
    padding-top: 31px;
    font-size: 18px;
    line-height: 15px;
    text-align: center;
  }
}

@media only screen and (max-width: 768px) {
  .AddDeviceModal_cardWrapper__iSsEM {
    padding: 25px 20px;
    width: 90%;
  }
  .AddDeviceModal_container__3tKrl .AddDeviceModal_character__2zatL {
    line-height: 40px;
    font-size: 20px;
    color: white;
    background-color: rgba(255, 255, 255, 0.3);
    border: 1px solid transparent;
    border-radius: 8px;
    margin-right: 25px;
    width: 40px;
    height: 40px;
  }
  
  .AddDeviceModal_flexitems__30jb- {
    margin-bottom: 30px;
  }
  .AddDeviceModal_h1__2Qc6g {
    font-size: 12px;
    line-height: 20px;
  }
  .AddDeviceModal_paragraph__127Zk {
    padding-top: 31px;
    font-size: 16px;
    line-height: 15px;
  }
  .AddDeviceModal_buttonWrapper__1OKMQ {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    justify-content: center;
  }
  .AddDeviceModal_purpleButton__gamHP {
    margin-bottom: 30px;
  }
  .AddDeviceModal_cancelButton__2wFAL {
    padding: 0;
    margin-bottom: 20px;
  }
  
}

@media only screen and (max-width: 480px) {
   .AddDeviceModal_container__3tKrl .AddDeviceModal_character__2zatL {
    line-height: 30px;
    font-size: 20px;
    color: white;
    background-color: rgba(255, 255, 255, 0.3);
    border: 1px solid transparent;
    border-radius: 8px;
    margin-right: 20px;
    width: 30px;
    height: 30px;
  }
}

.DeviceDetailsModal_modalWrapper__hhlzx {
  position: absolute;
  background: #ffffff;
  border: none;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  overflow-y: hidden;
  overflow-x: hidden;
}

.DeviceDetailsModal_headerWrapper__3515C {
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin-bottom: 20px;
}

.DeviceDetailsModal_iconWrapper__1dBtz {
  display: flex;
}

.DeviceDetailsModal_iconWrapper__1dBtz i {
  color: var(--mainPurple);
  font-size: 28px;
}

.DeviceDetailsModal_active__1p-wT {
  margin-left: 90px;
  color: #17bc27;
}
.DeviceDetailsModal_deactive__2qi6P {
  margin-left: 90px;
  color: #ff0000;
}
.DeviceDetailsModal_h1__3S6uP {
  position: relative;
  font-size: 16px;
  font-family: "Montserrat";
  font-weight: 700;
  letter-spacing: 0.02em;
  color: #000000;
}
.DeviceDetailsModal_h1__3S6uP input {
  width: 100px;
  text-align: left;
  vertical-align: middle;
  padding: 0;
  background-color: #fff;
}
.DeviceDetailsModal_h1__3S6uP span {
  vertical-align: middle;
}

.DeviceDetailsModal_headerWrapper__3515C i {
  font-size: 35px;
  color: var(--mainPurple);
  margin-right: 30px;
}
.DeviceDetailsModal_purpleButton__3n_x6 {
  font-size: 14px;
  letter-spacing: 0.02em;
  color: #ffffff;
  font-family: "Rubik";
  font-weight: 500;
  background: var(--mainPurple);
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  margin-left: 30px;
}

.DeviceDetailsModal_purpleButton__3n_x6:hover {
  background-color: var(--lighterMain);
  transition: 0.5s;
}
.DeviceDetailsModal_purpleButtonDimmed__2oTZH {
  font-size: 14px;
  letter-spacing: 0.02em;
  color: #ffffff;
  font-family: "Rubik";
  font-weight: 500;
  background: var(--mainPurple);
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  margin-left: 30px;
}
.DeviceDetailsModal_purpleButtonDimmed__2oTZH:hover {
  background: var(--mainPurple);
}

.DeviceDetailsModal_changeDeviceName__m2pc9 {
  background-color: #fff;
  border: none;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 25%;
}
.DeviceDetailsModal_changeDeviceName__m2pc9 i {
  font-size: 18px;
  vertical-align: middle;
}
.DeviceDetailsModal_contentWrapper__3wlta {
  margin-bottom: 20px;
  padding: 35px 35px 30px 50px;
  background: white;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}
.DeviceDetailsModal_tableWrapper__3Zm3O {
  width: 100%;
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.02em;
  color: #000000;
  background-color: rgba(214, 214, 214, 0.1);
  border-radius: 10px;

  padding: 40px 45px 30px 45px;
}
.DeviceDetailsModal_tableHeader__3Tmq6 {
  table-layout: fixed;
  width: 100%;
}
.DeviceDetailsModal_dropdownButton__ucqlJ {
  cursor: pointer;
}

.DeviceDetailsModal_devicesArr__1wf_9 {
  color: var(--mainPurple);
  background-color: white;
  letter-spacing: 0.02em;
  text-align: left;
  line-height: 20px;
  width: calc(100% / 5);
}
.DeviceDetailsModal_deviceArr__1eYM3 th:last-child {
  padding-right: 0;
}
.DeviceDetailsModal_devicesArr__1wf_9 th {
  padding: 20px 40px 20px 20px;
  border: none;
  width: 10%;
}

.DeviceDetailsModal_deviceName__1NJba {
  font-weight: bold;
  position: relative;
}
.DeviceDetailsModal_deviceName__1NJba input {
  width: 100%;
  padding: 0;
  background-color: rgba(214, 214, 214, 0.1);
}
.DeviceDetailsModal_deviceName__1NJba i {
  font-size: 18px;
  color: black;
  background-color: rgba(214, 214, 214, 0.1);
}
.DeviceDetailsModal_deviceName__1NJba button {
  position: absolute;
  background-color: #fff;
  top: 63%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 0%;
  vertical-align: middle;
  text-align: middle;
}
.DeviceDetailsModal_emptyList__2A9zN {
  font-size: 16px;
}
.DeviceDetailsModal_rows__29Vm9 {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #000000;
  margin-bottom: 30px;
  text-align: left;
  padding: 15px;
  letter-spacing: 0.02em;
}
.DeviceDetailsModal_rows__29Vm9 input {
  width: 100%;
  padding: 0;
}
.DeviceDetailsModal_rows__29Vm9 td {
  padding: 20px 0px 0px 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% / 5);
}
.DeviceDetailsModal_mobileActions__2tvwy {
  display: none;
}

.DeviceDetailsModal_switch__32i8M {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 15px;
  vertical-align: middle;
  margin-right: 10px;
}

.DeviceDetailsModal_switch__32i8M input {
  opacity: 0;
  width: 0;
  height: 0;
}

.DeviceDetailsModal_sliderRound__LREs1 {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  transition: 0.4s;
}

.DeviceDetailsModal_sliderRound__LREs1:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 0px;
  bottom: 1px;
  background-color: #ffffff;
  transition: 0.4s;
}

input:checked + .DeviceDetailsModal_sliderRound__LREs1 {
  background-color: #17bc27;
}

input:focus + .DeviceDetailsModal_sliderRound__LREs1 {
  box-shadow: 0 0 1px #17bc27;
}

input:checked + .DeviceDetailsModal_sliderRound__LREs1:before {
  -webkit-transform: translateX(17px);
  transform: translateX(17px);
}

.DeviceDetailsModal_sliderRound__LREs1 {
  border-radius: 34px;
}

.DeviceDetailsModal_sliderRound__LREs1:before {
  border-radius: 50%;
}

.DeviceDetailsModal_dropdown__4NPfb {
  display: none;
}

@media only screen and (max-width: 1201px) {
  .DeviceDetailsModal_headerWrapper__3515C {
    margin-bottom: 16.5px;
    align-items: center;
  }

  .DeviceDetailsModal_iconWrapper__1dBtz i {
    font-size: 23px;
  }

  .DeviceDetailsModal_h1__3S6uP {
    font-size: 13px;
  }
  .DeviceDetailsModal_h1__3S6uP input {
    width: 83px;
  }

  .DeviceDetailsModal_headerWrapper__3515C i {
    font-size: 29px;
    margin-right: 25px;
  }
  .DeviceDetailsModal_purpleButton__3n_x6 {
    font-size: 11.5px;
    padding: 8.5px 16.5px;
    margin-left: 25px;
  }
  .DeviceDetailsModal_purpleButtonDimmed__2oTZH {
    font-size: 11.5px;
    padding: 8.5px 16.5px;
    margin-left: 25px;
  }

  .DeviceDetailsModal_changeDeviceName__m2pc9 i {
    font-size: 15px;
  }
  .DeviceDetailsModal_contentWrapper__3wlta {
    margin-bottom: 16.5px;
    padding: 29px 29px 25px 41.5px;
  }
  .DeviceDetailsModal_tableWrapper__3Zm3O {
    font-size: 13px;
    padding: 33px 37px 25px 37px;
  }

  .DeviceDetailsModal_devicesArr__1wf_9 {
    line-height: 16.5px;
  }
  .DeviceDetailsModal_devicesArr__1wf_9 th {
    padding: 16.5px 33px 16.5px 16.5px;
  }
  .DeviceDetailsModal_deviceName__1NJba i {
    font-size: 15px;
  }
  .DeviceDetailsModal_emptyList__2A9zN {
    font-size: 13px;
  }
  .DeviceDetailsModal_rows__29Vm9 {
    font-size: 13px;
    line-height: 16.5px;
    padding: 12.5px;
  }

  .DeviceDetailsModal_rows__29Vm9 td {
    padding: 16.5px 0px 0px 16.5px;
  }

  .DeviceDetailsModal_switch__32i8M {
    width: 25px;
    height: 12.5px;
  }

  .DeviceDetailsModal_sliderRound__LREs1:before {
    position: absolute;
    content: "";
    height: 11px;
    width: 11px;
  }
  input:checked + .DeviceDetailsModal_sliderRound__LREs1:before {
    -webkit-transform: translateX(14px);
    transform: translateX(14px);
  }
  .DeviceDetailsModal_sliderRound__LREs1 {
    border-radius: 28px;
  }
}

@media only screen and (max-width: 1024px) {
  .DeviceDetailsModal_modalWrapper__hhlzx {
    top: 0;
  }
  .DeviceDetailsModal_greenName__2BbdL {
    color: rgb(17, 136, 23);
  }

  .DeviceDetailsModal_mobileActions__2tvwy {
    display: block;
    margin: 0;
  }
  .DeviceDetailsModal_mobileActions__2tvwy button {
    margin-right: 0;
  }
  .DeviceDetailsModal_tableWrapper__3Zm3O {
    padding: 60px 15px 120px 15px;
    margin: 0;
  }
  .DeviceDetailsModal_tableWrapper__3Zm3O h4 {
    font-size: 16px;
    font-weight: 700;
  }
  .DeviceDetailsModal_iconWrapper__1dBtz {
    justify-content: center;
    margin-bottom: 35px;
    align-items: center;
  }
  .DeviceDetailsModal_changeDeviceName__m2pc9 i {
    display: none;
  }

  .DeviceDetailsModal_iconWrapper__1dBtz i {
    align-self: center;
    font-size: 35px;
  }
  .DeviceDetailsModal_buttonWrapper__3YXTc {
    display: flex;
    justify-content: center;
  }
  .DeviceDetailsModal_buttonWrapperBottom__29nPc {
    display: flex;
    justify-content: space-around;
    flex-direction: row-reverse;
  }
  .DeviceDetailsModal_headerWrapper__3515C {
    margin: 0 20px 0 20px;
    display: block;
  }
  .DeviceDetailsModal_contentWrapper__3wlta {
    padding: 30px 10px 30px 10px;
    margin-bottom: 35px;
  }
  .DeviceDetailsModal_devicesArr__1wf_9 th {
    padding-right: 0;
  }
  .DeviceDetailsModal_backButton__UywvV {
    display: none;
  }
  .DeviceDetailsModal_purpleButton__3n_x6 {
    padding: 10px 20px;
    font-size: 14px;
  }
  .DeviceDetailsModal_purpleButtonDimmed__2oTZH {
    padding: 10px 20px;
    font-size: 14px;
    display: flex;
    justify-content: center;
  }
  .DeviceDetailsModal_rows__29Vm9 td {
    overflow: visible;
  }
  .DeviceDetailsModal_active__1p-wT,
  .DeviceDetailsModal_deactive__2qi6P,
  .DeviceDetailsModal_status__1NGWl,
  .DeviceDetailsModal_refreshButton__jEFuN,
  .DeviceDetailsModal_switch__32i8M,
  .DeviceDetailsModal_URL__1K0h8 {
    display: none;
  }

  .DeviceDetailsModal_changeDeviceName__m2pc9 {
    right: -50%;
  }
  .DeviceDetailsModal_dropdown__4NPfb {
    display: inline;
    text-align: center;
  }
  .DeviceDetailsModal_desktopActions__Wz6s6 {
    display: none;
  }
  .DeviceDetailsModal_devicesArr__1wf_9 th:last-of-type {
    width: 5%;
  }
  .DeviceDetailsModal_deviceName__1NJba input {
    text-overflow: ellipsis;
    background-color: inherit;
    max-width: 65%;
  }
  .DeviceDetailsModal_dropdownContent__nyDXG {
    display: none;
    position: relative;
    float: left;
  }
  .DeviceDetailsModal_dropdownOpen__13IHp {
    display: inline-block;
    position: absolute;
    z-index: 9999;
    padding: 15px 20px 15px 20px;
    /* width: 130px; */
    min-width: 150px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    right: 20%;
  }
  .DeviceDetailsModal_refreshSpan__1Bp52 {
    margin-left: 10px;
  }
  .DeviceDetailsModal_switchWrapper__1h0UR {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .DeviceDetailsModal_refreshWrapper__21ZZV {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
  }
  .DeviceDetailsModal_refreshWrapper__21ZZV button {
    font-size: 20px;
  }
  .DeviceDetailsModal_deleteWrapper__2LhPD {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
  }
  .DeviceDetailsModal_deleteWrapper__2LhPD button {
    margin-right: 15px;
    font-size: 20px;
  }
  .DeviceDetailsModal_switchMobile__BEQei {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 15px;
    vertical-align: middle;
    margin-right: 10px;
  }

  .DeviceDetailsModal_switchMobile__BEQei input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .DeviceDetailsModal_activeMobile__QgsIF {
    color: #17bc27;
    display: inline;
  }
  .DeviceDetailsModal_deactiveMobile__1AJCz {
    color: #ff0000;
    display: inline;
  }
  .DeviceDetailsModal_sliderRoundMobile__3pYsH {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: black;
    transition: 0.4s;
  }

  .DeviceDetailsModal_sliderRoundMobile__3pYsH:before {
    position: absolute;
    content: "";
    height: 13px;
    width: 13px;
    left: 0px;
    bottom: 1px;
    background-color: #ffffff;
    transition: 0.4s;
  }

  input:checked + .DeviceDetailsModal_sliderRoundMobile__3pYsH {
    background-color: #17bc27;
  }

  input:focus + .DeviceDetailsModal_sliderRoundMobile__3pYsH {
    box-shadow: 0 0 1px #17bc27;
  }

  input:checked + .DeviceDetailsModal_sliderRoundMobile__3pYsH:before {
    -webkit-transform: translateX(17px);
    transform: translateX(17px);
  }

  .DeviceDetailsModal_sliderRoundMobile__3pYsH {
    border-radius: 34px;
  }

  .DeviceDetailsModal_sliderRoundMobile__3pYsH:before {
    border-radius: 50%;
  }
  .DeviceDetailsModal_purpleButton__3n_x6:nth-of-type(2) {
    padding: 10px 30px;
  }
}
@media only screen and (max-width: 768px) {
  .DeviceDetailsModal_modalWrapper__hhlzx {
    top: 0%;
  }

  .DeviceDetailsModal_headerWrapper__3515C {
    flex-direction: column;
    margin: 0;
    margin-bottom: 16.5px;
  }
  .DeviceDetailsModal_iconWrapper__1dBtz {
    justify-content: center;
    margin-bottom: 30px;
  }

  .DeviceDetailsModal_buttonWrapper__3YXTc {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  .DeviceDetailsModal_purpleButton__3n_x6 {
    margin: 0;
    margin-bottom: 20px;
  }

  .DeviceDetailsModal_active__1p-wT {
    display: none;
    margin: 0;
  }
  .DeviceDetailsModal_deactive__2qi6P {
    display: none;
    margin: 0;
  }
  .DeviceDetailsModal_URL__1K0h8 {
    display: none;
  }
  .DeviceDetailsModal_status__1NGWl {
    display: none;
  }
  .DeviceDetailsModal_actions__266jh {
    display: none;
  }

  .DeviceDetailsModal_purpleButtonDimmed__2oTZH {
    font-size: 14px;
    padding: 10px 20px;
    margin: 0;
    height: 34px;
  }

  .DeviceDetailsModal_changeDeviceName__m2pc9 i {
    display: none;
  }
  .DeviceDetailsModal_contentWrapper__3wlta {
    margin-bottom: 35px;
    padding: 30px 15px 30px 15px;
  }
  .DeviceDetailsModal_tableWrapper__3Zm3O {
    font-size: 13px;
    padding: 60px 15px 120px 15px;
  }

  .DeviceDetailsModal_devicesArr__1wf_9 {
    line-height: 16.5px;
  }
  .DeviceDetailsModal_devicesArr__1wf_9 th {
    padding: 16.5px 33px 16.5px 16.5px;
  }
  .DeviceDetailsModal_deviceName__1NJba i {
    font-size: 15px;
  }
  .DeviceDetailsModal_emptyList__2A9zN {
    font-size: 13px;
  }
  .DeviceDetailsModal_rows__29Vm9 {
    font-size: 13px;
    line-height: 16.5px;
    padding: 12.5px;
  }

  .DeviceDetailsModal_rows__29Vm9 td {
    padding: 16.5px 0px 0px 16.5px;
  }

  .DeviceDetailsModal_switch__32i8M {
    width: 25px;
    height: 12.5px;
  }

  .DeviceDetailsModal_sliderRound__LREs1:before {
    position: absolute;
    content: "";
    height: 11px;
    width: 11px;
  }
  input:checked + .DeviceDetailsModal_sliderRound__LREs1:before {
    -webkit-transform: translateX(14px);
    transform: translateX(14px);
  }
  .DeviceDetailsModal_sliderRound__LREs1 {
    border-radius: 28px;
  }
}

.AddModal_modalBackground__1HO5w {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.AddModal_modalWrapper__YKhlN {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  padding: 50px 120px;
}
.AddModal_xButton__3YZSi {
  display: none;
}
.AddModal_h1__2W8PE {
  font-family: Rubik;
  font-weight: 700;
  font-size: 24px;
  color: white;
  padding-bottom: 50px;
}

.AddModal_inputWrapper__CFnDR {
  display: flex;
  flex-direction: column;
  font-family: Roboto;
  font-size: 18px;
  min-width: 540px;
  font-weight: 400;
  color: black;
  padding-bottom: 35px;
}
.AddModal_inputWrapper__CFnDR label {
  color: #fff;
}
.AddModal_inputWrapper__CFnDR input {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  height: 52px;
  border-radius: 10px;
  margin-top: 10px;
  font-size: 24px;
  background: rgba(255, 255, 255, 0.3);
  color: #fff;
}

.AddModal_inputWrapper__CFnDR label {
  color: #fff;
}
.AddModal_buttonWrapper__3xRjx {
  display: flex;
  justify-content: space-between;
}

.AddModal_button1__Sb88H {
  border: none;
  background-color: inherit;
  font-family: Roboto;
  font-size: 18px;
  color: white;
  cursor: pointer;
  opacity: 0.8;
}
.AddModal_button1__Sb88H:hover {
  opacity: 1;
}
.AddModal_button2__2NF-s {
  background: var(--mainPurple);
  font-family: Roboto;
  font-weight: 700;
  font-size: 18px;
  color: #ffffff;
  border-radius: 5px;
  padding: 16px 60px;
}
.AddModal_button2__2NF-s:hover {
  background-color: var(--lighterMain);
  transition: 0.5s;
}

@media only screen and (max-width: 1024px) {
  .AddModal_modalBackground__1HO5w {
    position: fixed;
     background-color: rgb(0, 0, 0, 0);
  }
  .AddModal_inputWrapper__CFnDR {
    text-align: center;
  }
  .AddModal_modalWrapper__YKhlN {
    padding: 60px 30px;
    width: 80%;
    background-color: rgba(0, 0, 0);
    margin: 0;
    top: 50%;
  }
  .AddModal_h1__2W8PE {
    padding-bottom: 35px;
    text-align: center;
  }
  .AddModal_inputWrapper__CFnDR {
    min-width: 0;
    width: 100%;
    padding-bottom: 35px;
  }
  .AddModal_xButton__3YZSi {
    position: absolute;
    top: 10px;
    right: 10px;
    display: block;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 37px;
    letter-spacing: 0.02em;
    color: #ffffff;
    float: right;
  }
  .AddModal_button1__Sb88H {
    display: none;
  }
  .AddModal_button2__2NF-s {
    width: 100%;
  }
}
@media only screen and (max-width: 768px) {
  .AddModal_modalBackground__1HO5w {
    position: fixed;
  }
  .AddModal_modalWrapper__YKhlN {
    padding: 60px 30px;
    width: 80%;
    background-color: rgba(0, 0, 0);
    margin: 0;
    top: 50%;
  }
  .AddModal_buttonWrapper__3xRjx {
    flex-direction: column-reverse;
    justify-content: center;
  }
  .AddModal_button2__2NF-s {
    margin-bottom: 20px;
  }
}

.PlanModal_background__1v9Ja {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.PlanModal_modalWrapper__pDKS2 {
  text-align: center;
  display: flex;
  justify-content: space-around;
  /* overflow: auto; */
}
.PlanModal_modal__3oYZp {
  width: 26.5%;
  box-sizing: border-box;
  border-radius: 10px;
}

.PlanModal_modalHeader__9dVQ5 {
  text-align: center;
  background: white;
  padding: 0px 0 15px;
  font-weight: 500;
  font-size: 24px;
  letter-spacing: 0.02em;
}
.PlanModal_modalHeader__9dVQ5 i {
  font-size: 80px;
  margin-bottom: 30px;
  display: inline-block;
}
.PlanModal_modalHeader__9dVQ5 h2 {
  margin-bottom: 50px;
  text-transform: capitalize;
}
.PlanModal_modalContent__1GWoh {
  padding: 30px 20px;
  background: #e6e6e6;
  position: relative;
  text-align: center;
}
.PlanModal_paragraph__2YawT {
  margin: 0;
  text-align: left;
  margin-bottom: 70px;
  letter-spacing: 0.02em;
}
.PlanModal_modalContent__1GWoh::before {
  content: "";
  width: calc(100% - 40px);
  margin: 0 auto;
  display: block;
  background-color: rgba(0, 0, 0, 0.2);
  height: 1px;
  position: absolute;
  top: 0px;
  left: 20px;
}
.PlanModal_credit__2aVfR {
  margin-bottom: 25px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;
  font-family: "Rubik";
  text-align: center;
}
.PlanModal_button__3f-BA {
  padding: 10px 50px;
  background-color: var(--mainPurple);
  color: white;
  border-radius: 5px;
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.02em;
  cursor: pointer;
  text-align: center;
}
.PlanModal_swiper-wrapper__vhZpX {
  max-width: 90%;
}
.PlanModal_buttonWrapper__3O19m {
  padding: 10px 0;
  width: 100%;
}
.PlanModal_xButton__3kntn {
  float: right;
  font-weight: 700;
  font-size: 25px;
  color: rgba(0, 0, 0, 0.6);
  margin-right: 10px;
  cursor: pointer;
}
.PlanModal_mobileHeaderHidden__3Ck_G {
  display: none;
}

@media screen and (min-width: 768px) {
  .PlanModal_swiper-container__1QaHh {
    max-width: 600px;
  }
  .PlanModal_mobileHeader__dWtMj {
    display: flex;
    flex-direction: row;
    background-color: var(--mainPurple);
    justify-content: space-between;
    z-index: 999;
  }
  .PlanModal_modalHeader__9dVQ5 h2 {
    margin-bottom: 25px;
  }
  .PlanModal_modalHeader__9dVQ5 i {
    margin-bottom: 0;
  }
  .PlanModal_h2__2WCTy {
    margin-top: 0;
  }
  .PlanModal_paragraph__2YawT {
    margin-bottom: 0;
  }

  .PlanModal_swiperSlide__1hEFV {
    padding-left: 30px !important;
    padding-right: 30px !important;
    position: static;
    z-index: 9999;
    margin-top: 50px;
    /* overflow: scroll */
  }
}
@media screen and (min-width: 1024px) {
  .PlanModal_swiper-container__1QaHh {
    max-width: 950px;
  }
  .PlanModal_mobileHeader__dWtMj {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .PlanModal_swiperSlide__1hEFV {
    padding-left: 30px !important;
    padding-right: 30px !important;
    margin-top: 30px;
    position: static;
  }
  .PlanModal_mobileHeader__dWtMj {
    display: flex;
    flex-direction: row;
    background-color: var(--mainPurple);
    justify-content: space-between;
    z-index: 999;
  }
  .PlanModal_modalHeader__9dVQ5 h2 {
    margin-bottom: 25px;
  }
  .PlanModal_modalHeader__9dVQ5 i {
    margin-bottom: 0;
  }
  .PlanModal_h2__2WCTy {
    margin-top: 0;
  }
  .PlanModal_paragraph__2YawT {
    margin-bottom: 0;
  }
}

.DeleteModal_modalBackground__-jG14 {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.DeleteModal_modalWrapper__2VjWu {
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  z-index: 9999;
  max-width: 350px;
  padding: 35px 65px 35px 65px;
}

.DeleteModal_paragraph__VLCcl {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #ffffff;
}
.DeleteModal_buttonWrapper__pHRLF {
  padding: 10px 30px 10px 30px;
  border-radius: 5px;

  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
  color: #ffffff;
  background-color: var(--mainPurple);
}
.DeleteModal_button__21BMv {
  display: flex;
  justify-content: space-evenly;
}
.DeleteModal_buttonWrapper__pHRLF:hover {
  background-color: var(--lighterMain);
  transition: 0.5s;
}

@media only screen and (max-width: 768px) {
  .DeleteModal_modalWrapper__2VjWu {
    padding: 35px;
  }
}


.AddXtreamModal_modalBackground__IMAoG {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  overflow-y: scroll;
}

.AddXtreamModal_modalWrapper__2zIvW {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
}
.AddXtreamModal_contentWrapper__2ubFO {
  margin: 50px 121px 90px 121px;
}
.AddXtreamModal_h1__1u8UB {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #ffffff;
  margin-bottom: 50px;
}
.AddXtreamModal_inputWrapper__1Wxzl input {
  color: white;
  width: 540px;
  height: 52px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.3);
}
.AddXtreamModal_inputWrapper__1Wxzl {
  margin-bottom: 35px;
  display: flex;
  flex-direction: column;
}
.AddXtreamModal_inputWrapper__1Wxzl label {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
  margin-bottom: 10px;
}

.AddXtreamModal_buttonWrapper__nrmCa {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  color: #ffffff;
  margin-bottom: 90px;
}
.AddXtreamModal_purpleButton__3NUzS {
  color: #ffffff;
  background-color: var(--mainPurple);
  border-radius: 5px;
  font-size: 18px;
  padding: 16px 56px;
  margin-left: 30px;
}
.AddXtreamModal_xButton__2t0B_ {
  display: none;
}
.AddXtreamModal_purpleButton__3NUzS:hover {
  background-color: var(--lighterMain);
  transition: 0.5s;
}
.AddXtreamModal_blackButton__auvkg {
  color: #ffffff;
  border: none;
  background-color: inherit;
  font-size: 18px;
  opacity: 0.8;
}
.AddXtreamModal_blackButton__auvkg:hover {
  opacity: 1;
}

@media only screen and (max-width: 1024px) {
  .AddXtreamModal_modalBackground__IMAoG {
    position: static;
  }
  .AddXtreamModal_modalWrapper__2zIvW {
    position: absolute;
    left: 50%;
    top: 45%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background: rgba(0, 0, 0);
    border-radius: 10px;
    width: 80%;
  }
  .AddXtreamModal_contentWrapper__2ubFO {
    margin: 0;
    width: 100%;
  }
  .AddXtreamModal_h1__1u8UB {
    text-align: center;
    font-size: 22px;
    margin-top: 50px;
  }
  .AddXtreamModal_inputWrapper__1Wxzl {
    width: 90%;
    text-align: center;
    display: flex;
    justify-content: center;
    margin: 40px auto;
  }
  .AddXtreamModal_inputWrapper__1Wxzl input {
    width: 100%;
  }
  .AddXtreamModal_buttonWrapper__nrmCa {
    float: none;
    display: flex;
    justify-content: center;
    margin: 0;
    margin-bottom: 70px;
    
  }
  .AddXtreamModal_purpleButton__3NUzS {
    margin-left: 0;
    padding: 17px auto;
  }
  .AddXtreamModal_xButton__2t0B_ {
    display: block;
    position: absolute;
    top: 10px;
    right: 10px;
    display: block;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 37px;
    letter-spacing: 0.02em;
    color: #ffffff;
    float: right;
  }
  .AddXtreamModal_blackButton__auvkg {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .AddXtreamModal_modalWrapper__2zIvW {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background: rgba(0, 0, 0);
    border-radius: 10px;
    width: 90%;
  }
  .AddXtreamModal_contentWrapper__2ubFO {
    margin: 50px auto 50px auto;
  }
  .AddXtreamModal_inputWrapper__1Wxzl input {
    width: 100%;
  }
  .AddXtreamModal_purpleButton__3NUzS {
    margin-bottom: 20px;
  }
  .AddXtreamModal_buttonWrapper__nrmCa {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    margin: 0;
  }
}

.HeaderDashboard_headerWrapper__1DENh {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 28px 60px;
}

.HeaderDashboard_headerWrapper__1DENh img {
  cursor: pointer;
}

.HeaderDashboard_logo__1I3rN {
  width: 108px;
  height: 32px;
}
.HeaderDashboard_dashboardButtonWrapper__1slqB {
  display: flex;
  align-items: center;
}
.HeaderDashboard_logoutButton__3q7MS {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  border: none;
  color: #000000;
  background-color: white;
  margin-left: 23px;
  cursor: pointer;
}
.HeaderDashboard_logoutVector__2W7Bd {
  padding-right: 10px;
  padding-left: 23px;
}

@media only screen and (max-width: 1300px) {
  .HeaderDashboard_headerWrapper__1DENh {
    width: 100%;
    padding: 27px 54px;
  }

  .HeaderDashboard_logo__1I3rN {
    width: 97px;
    height: 29px;
  }

  .HeaderDashboard_logoutButton__3q7MS {
    font-size: 12.5px;
    line-height: 14.5px;
    margin-left: 20.5px;
  }
  .HeaderDashboard_logoutVector__2W7Bd {
    padding-right: 9px;
    padding-left: 22.5px;
  }
}

@media only screen and (max-width: 1201px) {
  .HeaderDashboard_headerWrapper__1DENh {
    width: 100%;
    padding: 24px 50px;
  }

  .HeaderDashboard_logo__1I3rN {
    width: 89px;
    height: 26.5px;
  }

  .HeaderDashboard_logoutButton__3q7MS {
    font-size: 11.5px;
    line-height: 13.5px;
    margin-left: 18.5px;
  }
  .HeaderDashboard_logoutVector__2W7Bd {
    padding-right: 8.5px;
    padding-left: 20.5px;
  }
}

@media only screen and (min-width: 1900px) {
  .HeaderDashboard_logo__1I3rN {
    margin-left: 40px;
  }
}
@media only screen and (max-width: 1024px) {
  .HeaderDashboard_headerWrapper__1DENh {
    display: none;
  }
}

.SidebarDashboard_mobileHeader__33GGW {
  display: none;
}

.SidebarDashboard_wrapper__3kkXU {
  display: flex;
  flex-direction: column;
  width: 16%;
  padding-left: 0px;
  height: calc(100vh - 98px);
}
.SidebarDashboard_avatarWrapper__7D8WB {
  text-align: center;
}

.SidebarDashboard_avatar__3dxTt {
  margin: 0 auto 36px;
}

.SidebarDashboard_purple__7bzh6 {
  color: var(--mainPurple);
}

.SidebarDashboard_paragraph__1nx13 {
  text-align: center;
  font-family: Rubik;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.07em;
  color: #141414;
}
.SidebarDashboard_buttonWrapper__1YX4R {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  background: rgba(214, 214, 214, 0.1);
  margin-top: 10px;
  padding-top: 75px;
  justify-items: center;
  flex-grow: 1;
}
.SidebarDashboard_navLink__1Qpjk {
  display: inline-block;
  color: black;
  width: 100%;
  align-items: center;
  padding: 15px 70px 15px 40px;
  border-radius: 0px 10px 10px 0;
  margin-bottom: 57px;
  font-size: 16px;
  border: none;
  font-weight: bold;
}
.SidebarDashboard_navLink__1Qpjk i {
  margin-right: 40px;
  font-size: 20px;
}

.SidebarDashboard_navLink__1Qpjk:hover {
  background: var(--mainPurple);
  color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.SidebarDashboard_current__nvCi8 {
  background: var(--mainPurple);
  color: white;
  display: flex;
  align-items: center;
  padding: 15px 70px 15px 40px;
  border-radius: 0px 10px 10px 0;
  margin-bottom: 57px;
  border: none;
  font-weight: bold;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 16px;
}
.SidebarDashboard_current__nvCi8 i {
  margin-right: 40px;
  font-size: 20px;
}
.SidebarDashboard_headerWrapper__xZ-T2 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 60px;
}

.SidebarDashboard_logo__1COZD {
  width: 108px;
  height: 32px;
}
.SidebarDashboard_dashboardButtonWrapper__2TQdC {
  display: flex;
  align-items: center;
}
.SidebarDashboard_logoutButton__2bslr {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  border: none;
  color: #000000;
  background-color: white;
  margin-left: 23px;
  cursor: pointer;
}
.SidebarDashboard_logoutVector__xq70- {
  padding-right: 10px;
  padding-left: 23px;
}
.SidebarDashboard_daysLeftButton__3HeFm {
  font-family: Rubik;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  padding: 10px 35px;

  letter-spacing: 0.02em;
  background-color: white;
  color: var(--mainPurple);

  border: 2px solid var(--mainPurple);
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;
}

@media only screen and (max-width: 1300px) {
  .SidebarDashboard_wrapper__3kkXU {
    width: 16%;
    height: calc(100vh - 88px);
  }

  .SidebarDashboard_avatar__3dxTt {
    margin-bottom: 32px;
  }

  .SidebarDashboard_paragraph__1nx13 {
    font-size: 13.5px;
    line-height: 16px;
    letter-spacing: 0.06em;
  }
  .SidebarDashboard_buttonWrapper__1YX4R {
    margin-top: 9px;
    padding-top: 67px;
  }
  .SidebarDashboard_navLink__1Qpjk {
    width: 100%;
    padding: 14px 63px 14px 30px;
    border-radius: 0px 10px 10px 0;
    margin-bottom: 57px;
    font-size: 14.5px;
  }
  .SidebarDashboard_navLink__1Qpjk i {
    margin-right: 36px;
    font-size: 18px;
  }

  .SidebarDashboard_current__nvCi8 {
    padding: 13.5px 63px 13.5px 30px;
    margin-bottom: 57px;
  }
  .SidebarDashboard_current__nvCi8 i {
    padding-right: 20px;
  }
  .SidebarDashboard_headerWrapper__xZ-T2 {
    padding: 27px 54px;
  }

  .SidebarDashboard_logo__1COZD {
    width: 97px;
    height: 29px;
  }

  .SidebarDashboard_logoutButton__2bslr {
    font-size: 12.5px;
    line-height: 14.5px;
    margin-left: 21px;
  }
  .SidebarDashboard_logoutVector__xq70- {
    padding-right: 9px;
    padding-left: 21px;
  }
  .SidebarDashboard_daysLeftButton__3HeFm {
    font-size: 12.5px;
    line-height: 15px;
    padding: 9px 32px;
    letter-spacing: 0.02em;
  }
}

@media only screen and (max-width: 1201px) {
  .SidebarDashboard_wrapper__3kkXU {
    width: 13%;
    height: calc(100vh - 81px);
  }

  .SidebarDashboard_avatar__3dxTt {
    margin-left: 76px;
    margin-bottom: 30px;
  }

  .SidebarDashboard_paragraph__1nx13 {
    font-size: 12.5px;
    line-height: 14.5px;
    letter-spacing: 0.05em;
  }
  .SidebarDashboard_buttonWrapper__1YX4R {
    margin-top: 8px;
    padding-top: 61.5px;
  }
  .SidebarDashboard_navLink__1Qpjk {
    width: 100%;
    padding: 12.5px 58px 12.5px 20px;
    margin-bottom: 52.5px;
    font-size: 13px;
  }
  .SidebarDashboard_navLink__1Qpjk i {
    margin-right: 33px;
    font-size: 16.5px;
  }

  .SidebarDashboard_current__nvCi8 {
    padding: 12.5px 58px 12.5px 20px;
    margin-bottom: 52.5px;
  }
  .SidebarDashboard_current__nvCi8 i {
    padding-right: 15px;
  }
  .SidebarDashboard_headerWrapper__xZ-T2 {
    padding: 25px 50px;
  }

  .SidebarDashboard_logo__1COZD {
    width: 90px;
    height: 27px;
  }

  .SidebarDashboard_logoutButton__2bslr {
    font-size: 11.5px;
    line-height: 13px;
    margin-left: 19.5px;
  }
  .SidebarDashboard_logoutVector__xq70- {
    padding-right: 8.5px;
    padding-left: 19px;
  }
  .SidebarDashboard_daysLeftButton__3HeFm {
    font-size: 11.5px;
    line-height: 14px;
    padding: 8px 27.5px;
  }
}

@media only screen and (max-width: 1024px) {
  .SidebarDashboard_wrapper__3kkXU {
    width: 65%;
    height: calc(100vh - 81px);
    background-color: #141414;
    position: absolute;
    z-index: 2;
    margin-top: 89px;
    left: -100%;
    transition: left 0.5s ease-in-out;
  }
  .SidebarDashboard_sidebarOpen__OXbOn {
    left: 0;
  }
  .SidebarDashboard_hidden__DN_UF {
    display: none;
  }
  
  .SidebarDashboard_mobileMenu__1It46 {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.02em;
    color: #ffffff;
    margin-left: 40px;
    display: inline;
  }

  .SidebarDashboard_avatar__3dxTt,
  .SidebarDashboard_paragraph__1nx13,
  .SidebarDashboard_logoutVector__xq70- {
    display: none;
  }

  .SidebarDashboard_buttonWrapper__1YX4R {
    padding-top: 61.5px;
    border-top: 0;
    margin: 0;
  }
  .SidebarDashboard_navLink__1Qpjk {
    width: 100%;
    padding: 25px 58px 25px 20px;
    margin-bottom: 0;
    font-size: 13px;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    color: white;
    margin-left: 40px;
  }
  .SidebarDashboard_navLink__1Qpjk:hover {
    margin: 0;
    padding-left: 60px;
    background-color: rgba(255, 255, 255, 0.2);
  }
  .SidebarDashboard_navLink__1Qpjk i {
    margin-right: 40px;
    font-size: 26px;
    color: white;
  }

  .SidebarDashboard_current__nvCi8 {
    padding: 25px 58px 25px 40px;
    margin-bottom: 0;
    padding-left: 60px;
    background-color: rgba(255, 255, 255, 0.2);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .SidebarDashboard_current__nvCi8 i {
    margin-right: 40px;
    padding: 0;
    font-size: 26px;
  }
  .SidebarDashboard_headerWrapper__xZ-T2 {
    padding: 25px 50px;
  }

  .SidebarDashboard_logo__1COZD {
    width: 90px;
    height: 27px;
  }

  .SidebarDashboard_logoutButton__2bslr {
    font-size: 11.5px;
    line-height: 13px;
    margin-left: 19.5px;
  }

  /* new shit for mobile */
  .SidebarDashboard_mobileHeader__33GGW {
    display: flex;
    flex-direction: row;
    background-color: var(--mainPurple);
    justify-content: space-between;
    z-index: 999;
  }
  
  .SidebarDashboard_mobileLogoWrapper__3IgMq {
    align-self: center;
  }
  .SidebarDashboard_hamburgerWrapper__Ifvem {
    align-self: center;
    padding: 25px 0 25px 25px;
  }
  .SidebarDashboard_hamburger__ld4KH {
    background-color: #63269a;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    padding: 11px;
    color: white;
  }

  .SidebarDashboard_searchBar__3sy4j {
    padding: 25px 0 25px 0;
    vertical-align: middle;
  }
  .SidebarDashboard_searchIcon__2Adxy {
    background-color: #63269a;
    font-size: 21px;
    height: 39px;
    width: 39px;
    border-radius: 50%;
    padding: 11px;
    vertical-align: middle;
  }

  .SidebarDashboard_mobileAvatar__85iWP {
    margin-left: 25px;
    margin-right: 25px;
    vertical-align: middle;
  }
}

.MobileLogoutModal_modalBackground__blnq- {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  top:90px;
  z-index: 999;
}
.MobileLogoutModal_modalWrapper__3aOpI {
  position: absolute;
  right: 20px;
  background: white;
  border-radius: 10px;
  padding: 14px 0px 20px 0px;
}
.MobileLogoutModal_balanceWrapper__2FLJQ {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
  color: var(--mainPurple);
  text-align: center;
  text-decoration: none;
}
.MobileLogoutModal_buttonWrapper__ikiEX {
  background-color: var(--mainPurple);
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  padding: 10px 50px 10px 45px;
}
.MobileLogoutModal_buttonWrapper__ikiEX i {
  margin-left: 23px;
}



@media only screen and (max-width: 1024px) {
  .DashboardStyle_dashboardStyle__23AID {
    display: grid!important;
    justify-content: normal!important;
  }
}

.DashboardCredit_container__OyGhJ {
  width: 84%;
}
.DashboardCredit_wrapper__2yNwa {
  margin-right: 33px;
  margin-left: 50px;
  padding-right: 33px;
  padding-bottom: 10px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.DashboardCredit_headerWrapper__2XYM3 {
  display: flex;
  justify-content: space-between;
  padding-top: 35px;
  padding-left: 60px;
  width: 100%;
}

.DashboardCredit_header__FsvBU i {
  padding-right: 30px;
  font-size: 40px;
  color: var(--mainPurple);
}
.DashboardCredit_header__FsvBU h1 {
  margin-bottom: 30px;
  vertical-align: middle;
}
.DashboardCredit_h1__1Xfnd {
  font-family: "Montserrat";
  font-size: 16px !important;
  letter-spacing: 0.02em;
  color: #000000;
  display: inline-block;
}
.DashboardCredit_purpleButton__2pTgC {
  font-family: "Montserrat";
  font-size: 16px;
  color: #ffffff;
  background-color: var(--mainPurple);
  padding: 11px 43px;
  border-radius: 5px;
  cursor: pointer;
  letter-spacing: 0.02em;
}

.DashboardCredit_contentWrapper__17ZTg {
  background-color: rgba(214, 214, 214, 0.1);
  border-radius: 10px;
  margin: 40px 50px 25px 50px;
  padding: 30px 0px 0px 35px;
}
.DashboardCredit_creditH1__3J4f_ {
  font-family: "Montserrat";
  font-weight: bold;
  letter-spacing: 0.02em;
  font-size: 16px;
  color: #000000;
}

.DashboardCredit_credit__N1qDj {
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 35px;
  letter-spacing: 0.02em;
  color: var(--mainPurple);
}

@media only screen and (max-width: 1024px) {
  .DashboardCredit_container__OyGhJ {
    width: 100%;
    position: relative;
  }
  .DashboardCredit_wrapper__2yNwa {
    margin: 0 auto;
    max-width: 100%;
    padding: 0;
    margin-top: 100px;
    padding-bottom: 100px;
    margin: 100px 40px 100px 40px;
  }
  .DashboardCredit_headerWrapper__2XYM3 {
    display: flex;
    justify-content: space-between;
    padding: 40px 40px 40px 40px;
    margin: 0;
  }
  .DashboardCredit_header__FsvBU i {
    padding-right: 25px;
    font-size: 25px;
    color: var(--mainPurple);
  }
  .DashboardCredit_header__FsvBU h1 {
    margin-bottom: 30px;
    vertical-align: middle;
  }
  .DashboardCredit_h1__1Xfnd {
    font-family: "Montserrat";
    font-size: 16px !important;
    letter-spacing: 0.02em;
    color: #000000;
    display: inline-block;
  }
  .DashboardCredit_purpleButton__2pTgC {
    font-family: "Montserrat";
    font-size: 16px;
    color: #ffffff;
    background-color: var(--mainPurple);
    padding: 11px 43px;
    border-radius: 5px;
    cursor: pointer;
    letter-spacing: 0.02em;
  }
}

@media only screen and (max-width: 768px) {
  .DashboardCredit_container__OyGhJ {
    width: 100%;
    position: relative;
  }
  .DashboardCredit_contentWrapper__17ZTg {
    margin: 40px 20px 25px 20px;
    padding: 30px 0px 0px 35px;
  }
  .DashboardCredit_wrapper__2yNwa {
    margin: 0 auto;
    max-width: 100%;
    padding: 0;
    margin-top: 60px;
    padding-bottom: 60px;
    margin: 35px 20px 100px 20px;
  }
  .DashboardCredit_headerWrapper__2XYM3 {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 40px 40px 40px;
    margin: 0;
  }
  .DashboardCredit_header__FsvBU i {
    padding-right: 25px;
    font-size: 35px;
    color: var(--mainPurple);
  }
  .DashboardCredit_header__FsvBU {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }
  .DashboardCredit_header__FsvBU h1 {
   align-items: center;
  }
  .DashboardCredit_h1__1Xfnd {
    font-family: "Montserrat";
    font-size: 16px !important;
    letter-spacing: 0.02em;
    color: #000000;
    display: inline-block;
  }
  .DashboardCredit_purpleButton__2pTgC {
    font-family: "Montserrat";
    font-size: 16px;
    color: #ffffff;
    background-color: var(--mainPurple);
    padding: 11px 36px;
    border-radius: 5px;
    cursor: pointer;
    letter-spacing: 0.02em;
  }
}

.BuyCreditModal_modalBackground__iKfPo {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.BuyCreditModal_modalWrapper__3itFf {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  padding: 70px 255px;
}
.BuyCreditModal_h1__khfzY {
  font-family: "Rubik";
  font-weight: 700;
  font-size: 24px;
  color: #ffffff;
  margin-bottom: 65px;
}
.BuyCreditModal_cardholderName__2eS8p {
  font-family: "Roboto";
  font-size: 18px;
  color: #ffffff;
}
.BuyCreditModal_cardholderName__2eS8p input {
  margin-top: 15px;
}
.BuyCreditModal_cardholderName__2eS8p input {
  width: 100%;
  height: 50px;
  background: gray;
  border-radius: 10px;
}
.BuyCreditModal_cardNumberLabel___LWrp {
  color: white;
  font-family: "Roboto";
  font-size: 18px;
  padding-top: 35px;
  margin-bottom: 15px;
}
.BuyCreditModal_cardNumber__cw6yg {
  display: flex;
}
.BuyCreditModal_cvv__35pBr {
  color: white;
  font-family: "Roboto";
  font-size: 18px;
  position: relative;
}
.BuyCreditModal_cvv__35pBr label {
  position: absolute;
  top: 0;
  -webkit-transform: translateY(calc(-100% - 15px));
          transform: translateY(calc(-100% - 15px));
}

.BuyCreditModal_expDate__5VCz1 {
  display: inline-block;
  font-family: "Roboto";
  font-size: 18px;
  color: #ffffff;
  margin-top: 30px;
  margin-bottom: 15px;
}

.BuyCreditModal_cardNumber__cw6yg input {
  width: 74px;
  height: 50px;
  font-family: "Rubik";
  font-weight: 500;
  font-size: 17px;
  background-color: gray;
  border-radius: 10px;
}
.BuyCreditModal_cardNumber__cw6yg div span {
  position: relative;
  width: 28px;
  height: 3px;
  background-color: white;
  display: inline-block;
  margin: 0 10px;
}
.BuyCreditModal_cardNumber2__1IhmO div span {
  position: relative;
  width: 28px;
  height: 3px;
  background-color: white;
  display: inline-block;
  margin: 22px 10px;
}
.BuyCreditModal_cardNumber__cw6yg div {
  display: flex;
  align-items: center;
}
.BuyCreditModal_cardNumber2__1IhmO {
  display: flex;
  margin-right: 50px;
}
.BuyCreditModal_cardNumber2__1IhmO input {
  width: 74px;
  height: 50px;
  font-family: "Rubik";
  font-size: 17px;
  background-color: gray;
  border-radius: 10px;
}
.BuyCreditModal_cardNumber2__1IhmO :nth-child(3) {
  margin-right: 50px;
}

.BuyCreditModal_buttonWrapper__1AJ0_ {
  margin-top: 100px;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.BuyCreditModal_cancelButton__1XF9t {
  font-family: "Roboto";
  font-size: 18px;
  color: #ffffff;
  border: none;
  padding: 15px 50px;
  background-color: inherit;
}

.BuyCreditModal_purpleButton__13sFx {
  font-family: "Roboto";
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
  background-color: var(--mainPurple);
  border-radius: 5px;
  padding: 15px 75px;
}

@media only screen and (max-width: 1201px) {
  .BuyCreditModal_modalWrapper__3itFf {
    padding: 58px 211px;
  }
  .BuyCreditModal_h1__khfzY {
    font-size: 16.5px;
    color: #ffffff;
    margin-bottom: 45px;
  }
  .BuyCreditModal_cardholderName__2eS8p {
    font-size: 16.5px;
  }
  .BuyCreditModal_cardholderName__2eS8p input {
    margin-top: 12.5px;
  }
  .BuyCreditModal_cardholderName__2eS8p input {
    height: 41.5px;
  }
  .BuyCreditModal_cardNumberLabel___LWrp {
    font-size: 16.5px;
    padding-top: 29px;
    margin-bottom: 12.5px;
  }
  .BuyCreditModal_cvv__35pBr {
    font-size: 16.5px;
  }
  .BuyCreditModal_expDate__5VCz1 {
    font-size: 16.5px;
    margin-top: 25px;
    margin-bottom: 12.5px;
  }

  .BuyCreditModal_cardNumber__cw6yg input {
    width: 61.5px;
    height: 41.5px;
    font-size: 14px;
  }
  .BuyCreditModal_cardNumber__cw6yg div span {
    width: 23px;
    height: 2.5px;
    margin: 0 8.5px;
  }
  .BuyCreditModal_cardNumber2__1IhmO div span {
    width: 23px;
    height: 3px;
    margin: 18px 8.5px;
  }

  .BuyCreditModal_cardNumber2__1IhmO {
    margin-right: 41.5px;
  }
  .BuyCreditModal_cardNumber2__1IhmO input {
    width: 61.5px;
    height: 41.5px;
    font-size: 14px;
  }
  .BuyCreditModal_cardNumber2__1IhmO :nth-child(3) {
    margin-right: 41.5px;
  }
  .BuyCreditModal_buttonWrapper__1AJ0_ {
    margin-top: 83px;
  }
  .BuyCreditModal_cancelButton__1XF9t {
    font-size: 15px;
    padding: 12.5px 41.5px;
  }
  .BuyCreditModal_purpleButton__13sFx {
    font-size: 15px;
    line-height: 17.5px;
    padding: 12.5px 62px;
  }
}

/* //table and mobile // */

@media only screen and (max-width: 1024px) {
  .BuyCreditModal_modalWrapper__3itFf {
    padding: 58px 100px;
  }
}
  @media only screen and (max-width: 768px) {
    .BuyCreditModal_modalWrapper__3itFf {
      padding: 40px 70px;
    }
    .BuyCreditModal_h1__khfzY {
      font-size: 15px;
      margin-bottom: 45px;
    }
    .BuyCreditModal_cardholderName__2eS8p {
      font-size: 15px;
    }
    .BuyCreditModal_cardholderName__2eS8p input {
      margin-top: 12px;
    }
    .BuyCreditModal_cardholderName__2eS8p input {
      height: 40px;
    }
    .BuyCreditModal_cardNumberLabel___LWrp {
      font-size: 15px;
      padding-top: 29px;
      margin-bottom: 12.5px;
    }
    .BuyCreditModal_cvv__35pBr {
      font-size: 15px;
    }
    .BuyCreditModal_expDate__5VCz1 {
      font-size: 15px;
      margin-top: 25px;
      margin-bottom: 12.5px;
    }

    .BuyCreditModal_cardNumber__cw6yg input {
      width: 50px;
      height: 35px;
      font-size: 14px;
    }
    .BuyCreditModal_cardNumber__cw6yg div span {
      width: 23px;
      height: 2.5px;
      margin: 0 8.5px;
    }
    .BuyCreditModal_cardNumber2__1IhmO div span {
      width: 23px;
      height: 2.5px;
      margin: 18px 8.5px;
    }

    .BuyCreditModal_cardNumber2__1IhmO {
      margin-right: 41.5px;
    }
    .BuyCreditModal_cardNumber2__1IhmO input {
      width: 50px;
      height: 35px;
      font-size: 14px;
    }
    .BuyCreditModal_cardNumber2__1IhmO :nth-child(3) {
      margin-right: 41.5px;
    }
    .BuyCreditModal_buttonWrapper__1AJ0_ {
      margin-top: 83px;
    }
    .BuyCreditModal_cancelButton__1XF9t {
      font-size: 15px;
      padding: 12.5px 41.5px;
    }
    .BuyCreditModal_purpleButton__13sFx {
      font-size: 15px;
      line-height: 17.5px;
      padding: 12.5px 62px;
    }
  }

  @media only screen and (max-width: 480px) {
    .BuyCreditModal_modalWrapper__3itFf {
      padding: 25px 40px;
    }
    .BuyCreditModal_h1__khfzY {
      font-size: 15px;
      margin-bottom: 45px;
    }
    .BuyCreditModal_cardholderName__2eS8p {
      font-size: 15px;
    }
    .BuyCreditModal_cardholderName__2eS8p input {
      margin-top: 12px;
    }
    .BuyCreditModal_cardholderName__2eS8p input {
      height: 30px;
    }
    .BuyCreditModal_cardNumberLabel___LWrp {
      font-size: 15px;
      padding-top: 29px;
      margin-bottom: 12.5px;
    }
    .BuyCreditModal_cvv__35pBr {
      font-size: 15px;
    }
    .BuyCreditModal_expDate__5VCz1 {
      font-size: 15px;
      margin-top: 25px;
      margin-bottom: 12.5px;
    }

    .BuyCreditModal_cardNumber__cw6yg input {
      width: 40px;
      height: 30px;
      font-size: 14px;
    }
    .BuyCreditModal_cardNumber__cw6yg div span {
      width: 23px;
      height: 2.5px;
      margin: 0 8.5px;
    }
    .BuyCreditModal_cardNumber2__1IhmO div span {
      width: 23px;
      height: 2.5px;
      margin: 18px 8.5px;
    }

    .BuyCreditModal_cardNumber2__1IhmO {
      margin-right: 41.5px;
    }
    .BuyCreditModal_cardNumber2__1IhmO input {
      width: 40px;
      height: 30px;
      font-size: 14px;
    }
    .BuyCreditModal_cardNumber2__1IhmO :nth-child(3) {
      margin-right: 41.5px;
    }
    .BuyCreditModal_buttonWrapper__1AJ0_ {
      margin-top: 83px;
    }
    .BuyCreditModal_cancelButton__1XF9t {
      font-size: 15px;
      padding: 12.5px 41.5px;
    }
    .BuyCreditModal_purpleButton__13sFx {
      font-size: 15px;
      line-height: 17.5px;
      padding: 12.5px 62px;
    }
  }


.DashboardSettings_containter__2CdCF {
  width: 84%;
}
.DashboardSettings_wrapper__2jOqU {
  position: relative;
  margin-right: 33px;
  margin-left: 50px;
  padding-bottom: 10px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.DashboardSettings_headerWrapper__3BB53 {
  display: flex;
  justify-content: space-between;
  padding: 35px 35px 0 60px;
  width: 100%;
  vertical-align: middle;
}
.DashboardSettings_header__3iJrW {
  display: flex;
  align-items: center;
}

.DashboardSettings_header__3iJrW i {
  padding-right: 30px;
  font-size: 40px;
  color: var(--mainPurple);
}

.DashboardSettings_h1__32On0 {
  font-family: "Montserrat";
  font-size: 16px;
  letter-spacing: 0.02em;
  color: #000000;
  margin: 0;
  line-height: 20px;
}
.DashboardSettings_purpleButton__N-zXw {
  font-family: "Montserrat";
  font-size: 16px;
  color: #ffffff;
  background-color: var(--mainPurple);
  padding: 11px 43px;
  border-radius: 5px;
  cursor: pointer;
  letter-spacing: 0.02em;
}
.DashboardSettings_purpleButton__N-zXw:hover {
  background-color: var(--lighterMain);
  transition: 0.5s;
}

.DashboardSettings_contentWrapper__2L8pU {
  background-color: rgba(214, 214, 214, 0.1);
  border-radius: 10px;
  margin: 0px 35px 25px 50px;
  padding: 30px 0px 30px 35px;
}
.DashboardSettings_contentWrapper__2L8pU h1 {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}
.DashboardSettings_purple__3XiRo {
  color: var(--mainPurple);
  margin-bottom: 20px;
  margin-top: 30px;
}

@media only screen and (max-width: 1024px) {
  .DashboardSettings_containter__2CdCF {
    width: 100%;
    position: relative;
  }
  .DashboardSettings_wrapper__2jOqU {
    margin: 0 auto;
    max-width: 100%;
    padding: 0;
    margin-top: 100px;
    padding-bottom: 100px;
    margin: 100px 40px 100px 40px;
  }
  .DashboardSettings_header__3iJrW {
    align-items: center;
    justify-content: center;
    margin: 0;
  }
  .DashboardSettings_headerWrapper__3BB53 {
    display: flex;
    justify-content: space-between;
    padding: 35px 35px 80px 60px;
    width: 100%;
    vertical-align: middle;
  }

  .DashboardSettings_header__3iJrW i {
    padding-right: 25px;
    font-size: 25px;
    vertical-align: middle;
  }
  .DashboardSettings_header__3iJrW h1 {
    vertical-align: middle;
  }
  .DashboardSettings_h1__32On0 {
    font-family: "Montserrat";
    font-size: 16px;
    letter-spacing: 0.02em;
    color: #000000;
    display: inline-block;
    margin: 0;
  }
  .DashboardSettings_purpleButton__N-zXw {
    font-family: "Montserrat";
    font-size: 16px;
    color: #ffffff;
    background-color: var(--mainPurple);
    padding: 11px 43px;
    border-radius: 5px;
    cursor: pointer;
    letter-spacing: 0.02em;
  }

  .DashboardSettings_contentWrapper__2L8pU {
    background-color: rgba(214, 214, 214, 0.1);
    border-radius: 10px;
    margin: 0px 35px 25px 50px;
    padding: 30px 0px 10px 35px;
  }
  .DashboardSettings_contentWrapper__2L8pU h1 {
    font-size: 16px;
  }
  .DashboardSettings_purple__3XiRo {
    color: var(--mainPurple);
    margin-bottom: 20px;
    margin-top: 30px;
  }
}

@media only screen and (max-width: 768px) {
  .DashboardSettings_wrapper__2jOqU {
    margin: 0 auto;
    max-width: 100%;
    padding: 0;
    margin-top: 60px;
    padding-bottom: 60px;
    margin: 35px 20px 100px 20px;
  }
  .DashboardSettings_header__3iJrW {
    display: flex;
    margin: 0;
    align-self: center;
    margin-bottom: 30px;
  }
  .DashboardSettings_headerWrapper__3BB53 {
    display: block;
    align-items: center;
    padding: 20px 40px 40px 40px;
    width: 100%;
  }

  .DashboardSettings_header__3iJrW i {
    padding-right: 25px;
    font-size: 35px;
  }
  .DashboardSettings_header__3iJrW h1 {
    vertical-align: middle;
  }
  .DashboardSettings_buttonWrapper__8KFE0 {
    text-align: center;
  }
  .DashboardSettings_h1__32On0 {
    font-family: "Montserrat";
    font-size: 16px;
    letter-spacing: 0.02em;
    color: #000000;
    display: inline-block;
    margin: 0;
  }
  .DashboardSettings_purpleButton__N-zXw {
    font-family: "Montserrat";
    font-size: 16px;
    color: #ffffff;
    background-color: var(--mainPurple);
    padding: 11px 70px;
    border-radius: 5px;
    cursor: pointer;
    letter-spacing: 0.02em;
    align-items: center;
  }

  .DashboardSettings_contentWrapper__2L8pU {
    background-color: rgba(214, 214, 214, 0.1);
    border-radius: 10px;
    margin: 40px 35px 25px 50px;
    padding: 30px 0px 10px 35px;
  }
  .DashboardSettings_contentWrapper__2L8pU h1 {
    font-size: 16px;
  }
  .DashboardSettings_purple__3XiRo {
    color: var(--mainPurple);
    margin-bottom: 20px;
    margin-top: 30px;
  }
}

input {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
  padding-left: 25px;
}
input:focus {
  border-color: black;
}
.EditSettingsModal_modalWrapper__v4MD_ {
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  top: 0;
  left: 0;
  width: 100%;
}
.EditSettingsModal_headerWrapper__20hqK {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 34px 40px 0px 62px;
}
.EditSettingsModal_iconWrapper__396DX {
  display: flex;
  align-items: center;
}
.EditSettingsModal_iconWrapper__396DX i {
  padding-right: 30px;
}
.EditSettingsModal_headerWrapper__20hqK i {
  font-size: 44px;
  color: var(--mainPurple);
}
.EditSettingsModal_buttonWrapper__1JL5t {
  font-size: 16px;
  font-family: "Montserrat";
  letter-spacing: 0.02em;
}

.EditSettingsModal_whiteButton__3GI3a {
  color: var(--mainPurple);
  background-color: white;
  padding: 10px 15px;
  border-radius: 5px;
  margin-right: 30px;
  border: 1px solid var(--mainPurple);
}
.EditSettingsModal_whiteButton__3GI3a:hover {
  color: var(--lighterMain);
  background-color: rgba(214, 214, 214,0.1);
  transition: 0.5s;
}
.EditSettingsModal_purpleButton__8So7D {
  color: white;
  background-color: var(--mainPurple);
  padding: 10px 28px;
  border-radius: 5px;
}
.EditSettingsModal_purpleButton__8So7D:hover {
  background-color: var(--lighterMain);
  transition: 0.5s;
}
.EditSettingsModal_contentWrapper__3GJIX {
  margin: 0px 0px 0px 30px;
  padding: 35px 400px 35px 30px;
  background: rgba(214, 214, 214, 0.1);
  border-radius: 5px;
}

.EditSettingsModal_passwordInput__3YSKD i {
  position: absolute;
  font-size: 24px;
  color: black;
  cursor: pointer;
  top: 50%;
  right: 10px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  transition: font-size 0.5s;
}
.EditSettingsModal_passwordInput__3YSKD {
  position: relative;
  width: 540px;
}
.EditSettingsModal_purpleH1__4dHMi {
  font-family: "Montserrat";
  font-weight: bold;
  letter-spacing: 0.02em;
  color: var(--mainPurple);
  font-size: 16px;
}
.EditSettingsModal_inputWrapper__3kKWV label {
  display: block;
}
.EditSettingsModal_inputWrapper__3kKWV input {
  background: #ffffff;
  border: 1px solid var(--mainPurple);
  width: 540px;
  height: 52px;
  border-radius: 5px;
}
.EditSettingsModal_fieldWrapper__2kW7T {
  margin-bottom: 20px;
  position: relative;
}
.EditSettingsModal_inputWrapper__3kKWV {
  margin-bottom: 50px;
}
.EditSettingsModal_inputWrapperInput__1SAHD {
  position: relative;
}

@media only screen and (max-width: 1201px) {
  input {
    font-size: 15px;
    line-height: 17.5px;
    padding-left: 21px;
  }

  .EditSettingsModal_headerWrapper__20hqK {
    padding: 28px 33px 0px 51.5px;
  }
  .EditSettingsModal_iconWrapper__396DX i {
    padding-right: 25px;
  }
  .EditSettingsModal_headerWrapper__20hqK i {
    font-size: 36.5px;
  }
  .EditSettingsModal_buttonWrapper__1JL5t {
    font-size: 13px;
  }
  .EditSettingsModal_whiteButton__3GI3a {
    padding: 8.5px 12.5px;
    margin-right: 25px;
  }
  .EditSettingsModal_purpleButton__8So7D {
    padding: 8.5px 23px;
  }
  .EditSettingsModal_contentWrapper__3GJIX {
    margin: 0px 0px 0px 25px;
    padding: 29px 332px 29px 25px;
  }

  .EditSettingsModal_passwordInput__3YSKD i {
    font-size: 20px;
    right: 8.3px;
  }
  .EditSettingsModal_passwordInput__3YSKD {
    width: 448px;
  }
  .EditSettingsModal_purpleH1__4dHMi {
    font-size: 13px;
  }
  .EditSettingsModal_inputWrapper__3kKWV input {
    width: 448px;
    height: 43px;
  }
  .EditSettingsModal_fieldWrapper__2kW7T {
    margin-bottom: 16.5px;
  }
  .EditSettingsModal_inputWrapper__3kKWV {
    margin-bottom: 41.5px;
  }
}

/* mobile & tablet */

@media only screen and (max-width: 1024) {
  input {
    font-size: 15px;
    line-height: 17.5px;
    padding-left: 21px;
  }

  .EditSettingsModal_headerWrapper__20hqK {
    display: grid;
    padding: 28px 33px 0px 51.5px;
    align-items: center;
  }
  .EditSettingsModal_iconWrapper__396DX {
    display: flex;
    align-self: center;
  }
  .EditSettingsModal_iconWrapper__396DX i {
    padding-right: 25px;
  }
  .EditSettingsModal_headerWrapper__20hqK i {
    font-size: 36.5px;
  }
  .EditSettingsModal_buttonWrapper__1JL5t {
    font-size: 13px;
  }
  .EditSettingsModal_whiteButton__3GI3a {
    padding: 8.5px 12.5px;
    margin-right: 25px;
  }
  .EditSettingsModal_purpleButton__8So7D {
    padding: 8.5px 23px;
  }
  .EditSettingsModal_contentWrapper__3GJIX {
    margin: 0px 25px 0px 25px;
    padding: 30px 30px 30px 30px;
  }

  .EditSettingsModal_passwordInput__3YSKD i {
    font-size: 20px;
    right: 8.3px;
  }
  .EditSettingsModal_passwordInput__3YSKD {
    width: 448px;
  }
  .EditSettingsModal_purpleH1__4dHMi {
    font-size: 13px;
  }
  .EditSettingsModal_inputWrapper__3kKWV input {
    width: 90%;
    height: 43px;
  }
  .EditSettingsModal_fieldWrapper__2kW7T {
    margin-bottom: 16.5px;
  }
  .EditSettingsModal_inputWrapper__3kKWV {
    margin-bottom: 41.5px;
  }
}

@media only screen and (max-width: 768px) {
  input {
    font-size: 15px;
    line-height: 17.5px;
    padding-left: 21px;
  }

  .EditSettingsModal_headerWrapper__20hqK {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 20px 20px 20px;
  }
  .EditSettingsModal_iconWrapper__396DX {
    display: flex;
    margin: 0;
    margin-bottom: 30px;
  }
  .EditSettingsModal_iconWrapper__396DX h4 {
    margin: 0;
  }
  .EditSettingsModal_buttonWrapper__1JL5t {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .EditSettingsModal_whiteButton__3GI3a {
    padding: 8.5px 12.5px;
    margin-right: 25px;
  }
  .EditSettingsModal_purpleButton__8So7D {
    padding: 8.5px 23px;
  }
  .EditSettingsModal_contentWrapper__3GJIX {
    margin: 0px 25px 0px 25px;
    padding: 30px 0px 30px 0px;
  }

  .EditSettingsModal_passwordInput__3YSKD i {
    font-size: 20px;
    right: 8.3px;
  }
  .EditSettingsModal_passwordInput__3YSKD {
    width: 100%;
  }
  .EditSettingsModal_passwordInput__3YSKD input {
    width: 100%;
  }
  .EditSettingsModal_purpleH1__4dHMi {
    font-size: 13px;
  }

  .EditSettingsModal_inputWrapper__3kKWV input {
    width: 100%;
    height: 40px;
  }
  .EditSettingsModal_fieldWrapper__2kW7T {
    margin-bottom: 16.5px;
  }
  .EditSettingsModal_inputWrapper__3kKWV {
    margin-bottom: 41.5px;
    width: 100%;
  }
}

.PasswordChange_cardWrapper__8mBv0 {
  color: #ffffff;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  margin-right: 65px;
  padding: 38px 121px 67px 121px;
  position: absolute;
  left: 66%;
  top: 50%;
  -webkit-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);
}
.PasswordChange_mobileLogoWrapper__WBHTR {
  display: none;
}
.PasswordChange_h1__2xQl3 {
  font-family: "Rubik";
  font-size: 24px;
  color: #ffffff;
  padding-bottom: 14px;
}
.PasswordChange_inputWrapper__35rAI {
  display: flex;
  flex-direction: column;
  margin-bottom: 27px;
  width: 540px;
}

.PasswordChange_inputWrapper__35rAI label {
  margin-bottom: 10px;
  font-size: 18px;
  font-family: "Roboto";
}
.PasswordChange_inputWrapper__35rAI input {
  border-radius: 10px;
  height: 52px;
  color: #fff;
  font-size: 24px;
  background: rgba(255, 255, 255, 0.3);
}
.PasswordChange_contentWrapper__wkncN {
  width: 540px;
}

.PasswordChange_buttonWrapper__3JVRw {
  display: flex;
  justify-content: space-between;
  margin-top: 59px;
  flex: row-reverse 1;
}

.PasswordChange_backButton__3QO4F {
  border: none;
  padding: 0;
  border: none;
  background: none;
  color: white;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
  opacity: 0.8;
}
.PasswordChange_backButton__3QO4F:hover {
  opacity: 1;
}
.PasswordChange_whitePurpleButton__2hg1f {
  color: var(--mainPurple);
  background-color: white;
  border-radius: 10px;
  padding: 16px 32px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
}
.PasswordChange_whitePurpleButton__2hg1f:hover {
  color:#ffffff;
  background-color: var(--mainPurple);
  transition: 0.5s;
}

@media only screen and (max-width: 1300px) {
  .PasswordChange_cardWrapper__8mBv0 {
    margin-right: 58.5px;
    padding: 34px 75px 60px 75px;
  }
  .PasswordChange_h1__2xQl3 {
    font-size: 21.5px;
    padding-bottom: 12.5px;
  }
  .PasswordChange_inputWrapper__35rAI {
    margin-bottom: 24px;
  }

  .PasswordChange_inputWrapper__35rAI label {
    margin-bottom: 9px;
    font-size: 16px;
  }
  .PasswordChange_inputWrapper__35rAI input {
    height: 45px;
    font-size: 21.5px;
  }
  .PasswordChange_buttonWrapper__3JVRw {
    margin-top: 54px;
  }
  .PasswordChange_backButton__3QO4F {
    font-size: 16px;
  }
  .PasswordChange_whitePurpleButton__2hg1f {
    padding: 14.5px 29px;
    font-size: 16px;
  }
}

@media only screen and (max-width: 1200px) {
  .PasswordChange_cardWrapper__8mBv0 {
    margin-right: 52px;
    padding: 34px 60px 60px 60px;
  }
  .PasswordChange_h1__2xQl3 {
    font-size: 19px;
    padding-bottom: 11px;
  }
  .PasswordChange_inputWrapper__35rAI {
    margin-bottom: 21.5px;
  }

  .PasswordChange_inputWrapper__35rAI label {
    margin-bottom: 8px;
    font-size: 14.5px;
  }
  .PasswordChange_inputWrapper__35rAI input {
    height: 45px;
    font-size: 20px;
  }
  .PasswordChange_buttonWrapper__3JVRw {
    margin-top: 48px;
    margin-bottom: 48px;
  }
  .PasswordChange_backButton__3QO4F {
    font-size: 14.5px;
  }
  .PasswordChange_whitePurpleButton__2hg1f {
    padding: 13px 26px;
    font-size: 14.5px;
  }
}

@media only screen and (max-width: 1024px) {
  .PasswordChange_cardWrapper__8mBv0 {
    position: static;
    top:0;
    left: 0;
    margin: 0 auto;
    width: 100%;
    max-width: 95%;
    padding: 0;
    -webkit-transform: none;
            transform: none;
  }
  .PasswordChange_contentWrapper__wkncN {
    width: 100%;
    padding: 40px 40px 0;
    padding-bottom: 40px;
  }
  section {
    margin: 0 auto;
    min-width: 80%;
    vertical-align: middle;
  }
  .PasswordChange_h1__2xQl3 {
    margin: 0;
    margin-bottom: 30px;
    text-align: center;
  }
  .PasswordChange_mobileLogoWrapper__WBHTR {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 100px;
  }
  .PasswordChange_logo__3jAQi {
    width: 110px;
    height: 32px;
  }
  .PasswordChange_inputWrapper__35rAI {
    margin-bottom: 21.5px;
    width: 100%;
  }
  .PasswordChange_inputWrapper__35rAI label {
    text-align: center;
  }
}
.PasswordChange_buttonWrapper__3JVRw {
  margin: 0;
}
@media only screen and (max-width: 768px) {
  
  .PasswordChange_inputWrapper__35rAI {
    margin-bottom: 21.5px;
  }
  .PasswordChange_h1__2xQl3 {
    text-align: center;
  }
  .PasswordChange_buttonWrapper__3JVRw {
    flex-direction: column;
    align-items: center;
  }
  .PasswordChange_inputWrapper__35rAI label {
    margin-bottom: 8px;
    font-size: 14.5px;
  }
  .PasswordChange_inputWrapper__35rAI input {
    height: 45px;
    font-size: 20px;
  }
  .PasswordChange_buttonWrapper__3JVRw {
    margin-top: 48px;
    flex-direction: column-reverse;
  }
  .PasswordChange_backButton__3QO4F {
    font-size: 14.5px;
    margin-top: 48px;
  }
  .PasswordChange_whitePurpleButton__2hg1f {
    padding: 13px 65px;
    font-size: 14.5px;
  }
}

.PasswordConfirmation_cardWrapper__JM-FU {
  color: #ffffff;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  position: absolute;
  left: 66%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  margin-right: 65px;
  padding: 38px 100px 67px 100px;
}
.PasswordConfirmation_h1__2q59I {
  font-family: "Rubik";
  font-size: 24px;
  color: #ffffff;
  padding-bottom: 14px;
}
.PasswordConfirmation_inputWrapper__3X06t {
  display: flex;
  flex-direction: column;
  margin-bottom: 27px;
  width: 540px;
}
.PasswordConfirmation_inputWrapper__3X06t i {
  position: absolute;
  font-size: 24px;
  color: black;
  cursor: pointer;
  right: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.PasswordConfirmation_inputWrapper__3X06t label {
  margin-bottom: 10px;
  font-size: 18px;
  font-family: "Roboto";
}
.PasswordConfirmation_inputWrapperInput__1ABCe {
  position: relative;
}
.PasswordConfirmation_inputWrapper__3X06t input {
  border-radius: 10px;
  height: 52px;
  width: 100%;
  color: #fff;
  font-size: 24px;
  background: rgba(255, 255, 255, 0.3);
}

.PasswordConfirmation_buttonWrapper__zqAMi {
  display: flex;
  justify-content: space-between;
  margin-top: 59px;
}

.PasswordConfirmation_backButton__3kEIA {
  border: none;
  padding: 0;
  border: none;
  background: none;
  color: white;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
}
.PasswordConfirmation_createAccButton__3PsJR {
  color: var(--mainPurple);
  background-color: white;
  border-radius: 10px;
  padding: 16px 32px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
}

@media only screen and (max-width: 1300px) {
  .PasswordConfirmation_cardWrapper__JM-FU {
    margin-right: 65px;
    padding: 38px 80px 67px 80px;
  }
  .PasswordConfirmation_inputWrapper__3X06t {
    width: 480px;
  }
}

@media only screen and (max-width: 1024px) {
  .PasswordConfirmation_cardWrapper__JM-FU {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    padding: 40px 40px 40px 40px;
    width: 80%;
  }
  .PasswordConfirmation_h1__2q59I {
    font-size: 20px;
    text-align: center;
  }
  .PasswordConfirmation_inputWrapper__3X06t {
    display: flex;
    flex-direction: column;
    margin-bottom: 27px;
    width: 100%;
  }
  .PasswordConfirmation_buttonWrapper__zqAMi {
    justify-content: center;
  }
}

@font-face {
  font-family: 'icomoon';
  src:  url(/static/media/icomoon.98b7ed52.eot);
  src:  url(/static/media/icomoon.98b7ed52.eot#iefix) format('embedded-opentype'),
    url(/static/media/icomoon.d794730e.ttf) format('truetype'),
    url(/static/media/icomoon.befb0a37.woff) format('woff'),
    url(/static/media/icomoon.61e4916a.svg#icomoon) format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-LogoutIcon:before {
  content: "\E910";
  color: #fff;
}
.icon-loop:before {
  content: "\E90D";
}
.icon-eye-blocked:before {
  content: "\E905";
}
.icon-backIcon:before {
  content: "\E912";
  color: var(--mainPurple);
}
.icon-icon12:before {
  content: "\E911";
  color: var(--mainPurple);
}
.icon-SearchIcon:before {
  content: "\E90F";
  color: #fff;
}
.icon-errorFlag:before {
  content: "\E90E";
}
.icon-edit-button:before {
  content: "\E90C";
}
.icon-Vector:before {
  content: "\E909";
  color: var(--mainPurple);
}
.icon-6:before {
  content: "\E90A";
  color: var(--mainPurple);
}
.icon-3:before {
  content: "\E90B";
  color: var(--mainPurple);
}
.icon-eyeOpened:before {
  content: "\E908";
}
.icon-Hamburger:before {
  content: "\E907";
  color: var(--mainPurple);
}
.icon-HamburgerWhite:before {
  content: "\E907";
  color: #ffffff;
}
.icon-Settings:before {
  content: "\E906";
}
.icon-remove:before {
  content: "\E904";
  color: #f00;
}
.icon-TV:before {
  content: "\E903";
}
.icon-subription:before {
  content: "\E901";
}
.icon-m3u:before {
  content: "\E902";
}
.icon-devices:before {
  content: "\E900";
}

