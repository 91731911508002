.mobileHeader {
  display: none;
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 16%;
  padding-left: 0px;
  height: calc(100vh - 98px);
}
.avatarWrapper {
  text-align: center;
}

.avatar {
  margin: 0 auto 36px;
}

.purple {
  color: var(--mainPurple);
}

.paragraph {
  text-align: center;
  font-family: Rubik;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.07em;
  color: #141414;
}
.buttonWrapper {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  background: rgba(214, 214, 214, 0.1);
  margin-top: 10px;
  padding-top: 75px;
  justify-items: center;
  flex-grow: 1;
}
.navLink {
  display: inline-block;
  color: black;
  width: 100%;
  align-items: center;
  padding: 15px 70px 15px 40px;
  border-radius: 0px 10px 10px 0;
  margin-bottom: 57px;
  font-size: 16px;
  border: none;
  font-weight: bold;
}
.navLink i {
  margin-right: 40px;
  font-size: 20px;
}

.navLink:hover {
  background: var(--mainPurple);
  color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.current {
  background: var(--mainPurple);
  color: white;
  display: flex;
  align-items: center;
  padding: 15px 70px 15px 40px;
  border-radius: 0px 10px 10px 0;
  margin-bottom: 57px;
  border: none;
  font-weight: bold;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 16px;
}
.current i {
  margin-right: 40px;
  font-size: 20px;
}
.headerWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 60px;
}

.logo {
  width: 108px;
  height: 32px;
}
.dashboardButtonWrapper {
  display: flex;
  align-items: center;
}
.logoutButton {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  border: none;
  color: #000000;
  background-color: white;
  margin-left: 23px;
  cursor: pointer;
}
.logoutVector {
  padding-right: 10px;
  padding-left: 23px;
}
.daysLeftButton {
  font-family: Rubik;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  padding: 10px 35px;

  letter-spacing: 0.02em;
  background-color: white;
  color: var(--mainPurple);

  border: 2px solid var(--mainPurple);
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;
}

@media only screen and (max-width: 1300px) {
  .wrapper {
    width: 16%;
    height: calc(100vh - 88px);
  }

  .avatar {
    margin-bottom: 32px;
  }

  .paragraph {
    font-size: 13.5px;
    line-height: 16px;
    letter-spacing: 0.06em;
  }
  .buttonWrapper {
    margin-top: 9px;
    padding-top: 67px;
  }
  .navLink {
    width: 100%;
    padding: 14px 63px 14px 30px;
    border-radius: 0px 10px 10px 0;
    margin-bottom: 57px;
    font-size: 14.5px;
  }
  .navLink i {
    margin-right: 36px;
    font-size: 18px;
  }

  .current {
    padding: 13.5px 63px 13.5px 30px;
    margin-bottom: 57px;
  }
  .current i {
    padding-right: 20px;
  }
  .headerWrapper {
    padding: 27px 54px;
  }

  .logo {
    width: 97px;
    height: 29px;
  }

  .logoutButton {
    font-size: 12.5px;
    line-height: 14.5px;
    margin-left: 21px;
  }
  .logoutVector {
    padding-right: 9px;
    padding-left: 21px;
  }
  .daysLeftButton {
    font-size: 12.5px;
    line-height: 15px;
    padding: 9px 32px;
    letter-spacing: 0.02em;
  }
}

@media only screen and (max-width: 1201px) {
  .wrapper {
    width: 13%;
    height: calc(100vh - 81px);
  }

  .avatar {
    margin-left: 76px;
    margin-bottom: 30px;
  }

  .paragraph {
    font-size: 12.5px;
    line-height: 14.5px;
    letter-spacing: 0.05em;
  }
  .buttonWrapper {
    margin-top: 8px;
    padding-top: 61.5px;
  }
  .navLink {
    width: 100%;
    padding: 12.5px 58px 12.5px 20px;
    margin-bottom: 52.5px;
    font-size: 13px;
  }
  .navLink i {
    margin-right: 33px;
    font-size: 16.5px;
  }

  .current {
    padding: 12.5px 58px 12.5px 20px;
    margin-bottom: 52.5px;
  }
  .current i {
    padding-right: 15px;
  }
  .headerWrapper {
    padding: 25px 50px;
  }

  .logo {
    width: 90px;
    height: 27px;
  }

  .logoutButton {
    font-size: 11.5px;
    line-height: 13px;
    margin-left: 19.5px;
  }
  .logoutVector {
    padding-right: 8.5px;
    padding-left: 19px;
  }
  .daysLeftButton {
    font-size: 11.5px;
    line-height: 14px;
    padding: 8px 27.5px;
  }
}

@media only screen and (max-width: 1024px) {
  .wrapper {
    width: 65%;
    height: calc(100vh - 81px);
    background-color: #141414;
    position: absolute;
    z-index: 2;
    margin-top: 89px;
    left: -100%;
    transition: left 0.5s ease-in-out;
  }
  .sidebarOpen {
    left: 0;
  }
  .hidden {
    display: none;
  }
  
  .mobileMenu {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.02em;
    color: #ffffff;
    margin-left: 40px;
    display: inline;
  }

  .avatar,
  .paragraph,
  .logoutVector {
    display: none;
  }

  .buttonWrapper {
    padding-top: 61.5px;
    border-top: 0;
    margin: 0;
  }
  .navLink {
    width: 100%;
    padding: 25px 58px 25px 20px;
    margin-bottom: 0;
    font-size: 13px;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    color: white;
    margin-left: 40px;
  }
  .navLink:hover {
    margin: 0;
    padding-left: 60px;
    background-color: rgba(255, 255, 255, 0.2);
  }
  .navLink i {
    margin-right: 40px;
    font-size: 26px;
    color: white;
  }

  .current {
    padding: 25px 58px 25px 40px;
    margin-bottom: 0;
    padding-left: 60px;
    background-color: rgba(255, 255, 255, 0.2);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .current i {
    margin-right: 40px;
    padding: 0;
    font-size: 26px;
  }
  .headerWrapper {
    padding: 25px 50px;
  }

  .logo {
    width: 90px;
    height: 27px;
  }

  .logoutButton {
    font-size: 11.5px;
    line-height: 13px;
    margin-left: 19.5px;
  }

  /* new shit for mobile */
  .mobileHeader {
    display: flex;
    flex-direction: row;
    background-color: var(--mainPurple);
    justify-content: space-between;
    z-index: 999;
  }
  
  .mobileLogoWrapper {
    align-self: center;
  }
  .hamburgerWrapper {
    align-self: center;
    padding: 25px 0 25px 25px;
  }
  .hamburger {
    background-color: #63269a;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    padding: 11px;
    color: white;
  }

  .searchBar {
    padding: 25px 0 25px 0;
    vertical-align: middle;
  }
  .searchIcon {
    background-color: #63269a;
    font-size: 21px;
    height: 39px;
    width: 39px;
    border-radius: 50%;
    padding: 11px;
    vertical-align: middle;
  }

  .mobileAvatar {
    margin-left: 25px;
    margin-right: 25px;
    vertical-align: middle;
  }
}
