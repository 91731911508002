.modalBackground {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  overflow-y: scroll;
}

.modalWrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
}
.contentWrapper {
  margin: 50px 121px 90px 121px;
}
.h1 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #ffffff;
  margin-bottom: 50px;
}
.inputWrapper input {
  color: white;
  width: 540px;
  height: 52px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.3);
}
.inputWrapper {
  margin-bottom: 35px;
  display: flex;
  flex-direction: column;
}
.inputWrapper label {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
  margin-bottom: 10px;
}

.buttonWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  color: #ffffff;
  margin-bottom: 90px;
}
.purpleButton {
  color: #ffffff;
  background-color: var(--mainPurple);
  border-radius: 5px;
  font-size: 18px;
  padding: 16px 56px;
  margin-left: 30px;
}
.xButton {
  display: none;
}
.purpleButton:hover {
  background-color: var(--lighterMain);
  transition: 0.5s;
}
.blackButton {
  color: #ffffff;
  border: none;
  background-color: inherit;
  font-size: 18px;
  opacity: 0.8;
}
.blackButton:hover {
  opacity: 1;
}

@media only screen and (max-width: 1024px) {
  .modalBackground {
    position: static;
  }
  .modalWrapper {
    position: absolute;
    left: 50%;
    top: 45%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0);
    border-radius: 10px;
    width: 80%;
  }
  .contentWrapper {
    margin: 0;
    width: 100%;
  }
  .h1 {
    text-align: center;
    font-size: 22px;
    margin-top: 50px;
  }
  .inputWrapper {
    width: 90%;
    text-align: center;
    display: flex;
    justify-content: center;
    margin: 40px auto;
  }
  .inputWrapper input {
    width: 100%;
  }
  .buttonWrapper {
    float: none;
    display: flex;
    justify-content: center;
    margin: 0;
    margin-bottom: 70px;
    
  }
  .purpleButton {
    margin-left: 0;
    padding: 17px auto;
  }
  .xButton {
    display: block;
    position: absolute;
    top: 10px;
    right: 10px;
    display: block;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 37px;
    letter-spacing: 0.02em;
    color: #ffffff;
    float: right;
  }
  .blackButton {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .modalWrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0);
    border-radius: 10px;
    width: 90%;
  }
  .contentWrapper {
    margin: 50px auto 50px auto;
  }
  .inputWrapper input {
    width: 100%;
  }
  .purpleButton {
    margin-bottom: 20px;
  }
  .buttonWrapper {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    margin: 0;
  }
}
