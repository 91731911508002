.cardWrapper {
  color: #ffffff;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  padding: 47px 121px 47px 121px;
}
.mobileLogoWrapper {
  display: none;
}
.contentWrapper {
  width: 540px;
}

.login {
  margin-bottom: 41px;
  font-family: "Rubik";
  font-weight: bold;
  font-size: 24px;
  margin: 0;
}
.inputWrapper {
  display: flex;
  align-content: space-between;
  flex-direction: column;
  margin-bottom: 30px;
  margin-top: 41px;
}
.inputWrapper label {
  margin-bottom: 10px;
  font-family: "Roboto";
  font-size: 18px;
}
.inputWrapper input {
  border-radius: 10px;
  height: 52px;
  width: 100%;
  color: #fff !important;
  font-size: 24px;
  padding: 0 10px;
  background-color: rgba(255, 255, 255, 0.3) !important;
}

.inputWrapper i {
  position: absolute;
  font-size: 24px;
  color: black;
  cursor: pointer;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  transition: font-size 0.5s;
}
.inputWrapper i:hover {
  font-size: 28px;
}
.inputWrapperInput {
  position: relative;
}

.buttonWrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}
.forgotPassword {
  padding: 0;
  border: none;
  background: none;
  color: white;
  font-family: Roboto;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
  opacity: 0.8;
}
.forgotPassword:hover {
  transition: 0.5s;
  opacity: 1;
}
.logIn {
  /* color: var(--mainPurple); */
  color: rgb(0, 0, 0);
  background-color: white;
  border-radius: 10px;
  padding: 15px 65px;
  font-family: Roboto;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
}
.logIn:hover {
  color: #ffffff;
  background-color: #636F85;
  transition: 0.5s;
}

.button3 {
  text-align: center;
  margin-top: 30px;
}
.registerButton {
  cursor: pointer;
  text-align: center;
  font-family: Roboto;
  font-size: 18px;
  background-color: var(--mainPurple);
  color: #d6d6d6;
  border-radius: 0.625rem;
  padding: 15px;
  color: #d6d6d6;
}
.registerButton:hover {
  color: var(--mainPurple);
  background-color: #ffffff;
  transition: 0.5s;
}
.noErrorBlock {
  background-color: #bd3134;
  display: none;
  vertical-align: center;
  margin-top: 25px;
}

.errorBlock {
  background-color: #bd3134;
  display: flex;
  vertical-align: center;
  margin-top: 25px;
}

.errorBlock i {
  font-size: 30px;
  align-self: center;
  padding-left: 20px;
  padding-right: 15px;
}

.errorParagraph {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
}

.ReCAPTCHA {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.sCardWrapper {
  color: #ffffff;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  margin-top: 150px;
  padding: 47px 121px 47px 121px;
  height: 100%;
}
.sContentWrapper {
  width: 100%;
  min-width: 100%;
}
.notVerified {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
  color: #ffffff;
  text-align: center;
  margin-top: 117px;
}
.pWrapper {
  max-width: 578px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 25px;
  color: #ffffff;
  margin-top: 58px;
  margin-bottom: 85px;
}
.sButtonWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 200px;
}
.blackButton {
  border: none;
  background-color: inherit;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
  cursor: pointer;
}
.whiteButton {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: var(--mainPurple);
  border-radius: 10px;
  background-color: #ffffff;
  padding: 16px 39px 16px 39px;
  cursor: pointer;
}

@media screen and (max-width: 1366px) {
  .cardWrapper {
    padding: 42.3px 109px 42.3px 109px;
  }
  .contentWrapper {
    width: 486px;
  }

  .login {
    margin-bottom: 36px;
    font-size: 21.6px;
    margin: 0;
  }
  .inputWrapper {
    margin-bottom: 27px;
    margin-top: 37px;
  }
  .inputWrapper label {
    margin-bottom: 9px;
    font-size: 16px;
  }
  .inputWrapper input {
    height: 46px;
    font-size: 22px;
  }

  .inputWrapper i {
    font-size: 22px;
  }
  .inputWrapper i:hover {
    font-size: 25px;
  }
  .forgotPassword {
    font-size: 17px;
  }
  .logIn {
    padding: 13.5px 58.5px;
    font-size: 18px;
  }

  .button3 {
    margin-top: 62px;
  }
  .registerButton {
    font-size: 17px;
    padding: 13.5px;
  }

  .ReCAPTCHA {
    margin-top: 45px;
  }
  .sCardWrapper {
    padding: 47px 80px 47px 80px;
  }
}
@media only screen and (max-width: 1300px) {
  .cardWrapper {
    padding: 42px 80px 42px 80px;
  }
  .contentWrapper {
    width: 486px;
  }

  .login {
    margin-bottom: 37px;
    font-size: 21.6px;
    margin: 0;
  }
  .inputWrapper {
    margin-bottom: 27px;
    margin-top: 37px;
  }
  .inputWrapper label {
    margin-bottom: 9px;
    font-size: 16px;
  }
  .inputWrapper input {
    height: 47px;
    font-size: 21.6px;
  }

  .inputWrapper i {
    font-size: 21.6px;
  }
  .inputWrapper i:hover {
    font-size: 25px;
  }

  .forgotPassword {
    font-size: 16px;
  }
  .logIn {
    padding: 13.5px 68.5px;
    font-size: 16px;
  }

  .button3 {
    margin-top: 62px;
  }
  .registerButton {
    font-size: 16px;
    padding: 13.5px;
  }

  .ReCAPTCHA {
    margin-top: 45px;
  }
}
@media only screen and (max-width: 1201px) {
  .cardWrapper {
    padding: 40px 100px 40px 100px;
  }
  .contentWrapper {
    width: 400px;
  }

  .login {
    margin-bottom: 34px;
    font-size: 20px;
  }
  .inputWrapper {
    margin-bottom: 27px;
    margin-top: 37px;
  }
  .inputWrapper label {
    margin-bottom: 8.2px;
    font-size: 15px;
  }
  .inputWrapper input {
    height: 43px;
    font-size: 19.4x;
  }

  .inputWrapper i {
    font-size: 19.5x;
  }
  .inputWrapper i:hover {
    font-size: 23px;
  }

  .forgotPassword {
    font-size: 15px;
  }
  .logIn {
    padding: 12.4px 63px;
    font-size: 15px;
  }

  .button3 {
    margin-top: 57px;
  }
  .registerButton {
    font-size: 15px;
    padding: 12.5px;
  }

  .sCardWrapper {
    padding: 47px 40px 47px 40px;
  }
}
@media only screen and (max-width: 1024px) {
  section {
    margin: 0 auto;
    min-width: 65%;
  }
  
  .mobileLogoWrapper {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 60px;
  }
  .logo {
    width: 110px;
    height: 32px;
  }
  .cardWrapper {
    margin: 0 auto;
    width: 100%;
    max-width: 90%;
    padding: 0;
    position: static;
    transform: none;
    margin-bottom: 30px;
  }
  .contentWrapper {
    width: 100%;
    padding: 40px 80px 95px;
  }

  .sCardWrapper {
    margin: 150px auto 0 auto;
    width: 80%;
  }
}

@media only screen and (min-width: 1920px) {
  .cardWrapper {
    margin-right: 0;
    padding: 40px 100px 40px 100px;
  }
}

@media only screen and (max-width: 768px) {
  .cardWrapper {
    max-width: 90%;
    position: static;
    transform: none;
    margin-bottom: 30px;
  }
  .contentWrapper {
    padding: 20px 30px 50px;
  }

  .buttonWrapper {
    flex-direction: column;
    align-items: center;
  }

  .logIn {
    padding: 16.5px 72px;
    margin-bottom: 20px;
  }

  .button3 {
    margin-top: 20px;
  }
  .sCardWrapper {
    margin: 150px auto 0 auto;
    width: 90%;
  }
}
